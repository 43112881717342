/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountingAccountRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  accountNumber?: number;
  label?: string | null;
  direction?: Direction;
  class?: AccountingClass;
  shortcut?: string | null;
  shortcutLabel?: string | null;
}

/** @format int32 */
export enum AccountingClass {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
}

export interface AccountingSettingRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  salesLogJournalId?: number | null;
  /** @format int32 */
  purchasingLogJournalId?: number | null;
  /** @format int32 */
  customerSubAccountId?: number | null;
  /** @format int32 */
  supplierSubAccountId?: number | null;
  /** @format int32 */
  salesDefaultAccountId?: number | null;
  /** @format int32 */
  salesDiscountAccountId?: number | null;
  /** @format int32 */
  holdbackAccountId?: number | null;
  /** @format int32 */
  accountingCompanyId?: number | null;
}

export interface ActivityChanelTypeRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
}

export interface ActivityRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  companyId?: number;
  /** @format int32 */
  collaboratorId?: number;
  /** @format int32 */
  contactId?: number | null;
  comments?: string | null;
  /** @format int32 */
  activitySourceTypeId?: number;
  /** @format int32 */
  activityChanelTypeId?: number;
  /** @format int32 */
  activityResultTypeId?: number;
  /** @format date-time */
  reminderDate?: string | null;
  /** @format date-time */
  activityDate?: string | null;
  isUnreachable?: boolean;
  hasLeavingMessage?: boolean;
  isTransfer?: boolean;
  /** @format int32 */
  transferCollaboratorId?: number | null;
  /** @format int32 */
  institutionId?: number;
}

export interface ActivityResultTypeRequestDto {
  /** @format int32 */
  id?: number;
  color?: string | null;
  label?: string | null;
}

export interface ActivitySourceTypeRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
}

export interface AddressCompanyRequestDto {
  /** @format int32 */
  id?: number;
  address?: string | null;
  addressComplement?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  siret?: string | null;
  label?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  isDefault?: boolean;
}

export interface AffairCategoryRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  parentId?: number | null;
}

export interface AffairContactRequestDto {
  label?: string | null;
  /** @format int32 */
  contactId?: number;
}

/** @format int32 */
export enum AffairCostingDirectionType {
  Value1 = 1,
  Value2 = 2,
}

export interface AffairCostingRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number;
  /** @format date-time */
  date?: string;
  sourceType?: AffairCostingSourceType;
  directionType?: AffairCostingDirectionType;
  label?: string | null;
  /** @format double */
  quantity?: number;
  /** @format double */
  valuatedQuantity?: number;
  /** @format double */
  amountUnit?: number;
  /** @format int32 */
  missionId?: number | null;
  /** @format int32 */
  collaboratorId?: number | null;
  documentApplicationType?: DocumentApplicationType;
  /** @format int32 */
  documentId?: number | null;
  documentPath?: string | null;
  /** @format int32 */
  analyticsSettingId?: number | null;
  /** @format int32 */
  institutionId?: number;
  /** @format int32 */
  parentId?: number | null;
  /** @format int32 */
  accountingAccountId?: number | null;
}

/** @format int32 */
export enum AffairCostingSourceType {
  Value1 = 1,
  Value2 = 2,
}

export interface AffairRequestDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  code?: string | null;
  status?: AffairStatus;
  zone?: AffairZone;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  description?: string | null;
  address?: string | null;
  addressComplement?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  ownAddressAffair?: boolean;
  /** @format int32 */
  companyId?: number | null;
  /** @format int32 */
  affairTypeId?: number | null;
  /** @format int32 */
  affairCategoryId?: number | null;
  collaboratorIds?: number[] | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  /** @format double */
  hoursByDayDefault?: number;
  internalNote?: string | null;
  /** @format double */
  amount?: number | null;
  color?: string | null;
  /** @format int32 */
  customFieldTemplateId?: number | null;
  /** @format int32 */
  analyticsSettingId?: number | null;
  /** @format int32 */
  caCoCollaboratorId?: number | null;
}

/** @format int32 */
export enum AffairStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface AffairTagAffairRequestDto {
  affairIds?: number[] | null;
  affairTagIds?: number[] | null;
}

export interface AffairTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface AffairTypeRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  workspaceId?: number;
}

/** @format int32 */
export enum AffairZone {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

/** @format int32 */
export enum AmountType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface AnalyticsSettingRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  analyticCode?: string | null;
  /** @format int32 */
  parentAnalyticsSettingId?: number | null;
}

export interface BankAccountRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  name?: string | null;
  iban?: string | null;
  bic?: string | null;
  /** @format int32 */
  institutionId?: number;
}

/** @format int32 */
export enum Civility {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface CollaboratorGoalRequestDto {
  /** @format int32 */
  id?: number;
  statisticsPeriod?: StatisticsPeriod;
  /** @format int32 */
  numbersActivitiesByPeriod?: number | null;
  /** @format int32 */
  numbersQuotesSentByPeriod?: number | null;
  /** @format int32 */
  numbersQuotesSignedByPeriod?: number | null;
  /** @format int32 */
  numbersInvoicesSentByPeriod?: number | null;
  /** @format int32 */
  numbersOrderFormsCustomersSentByPeriod?: number | null;
  /** @format double */
  amountQuotesSentByPeriod?: number | null;
  /** @format double */
  amountQuotesSignedByPeriod?: number | null;
  /** @format double */
  amountInvoicesSentByPeriod?: number | null;
  /** @format double */
  amountOrderFormsCustomersSentByPeriod?: number;
  /** @format int32 */
  collaboratorId?: number;
}

export interface CollaboratorRequestDto {
  /** @format int32 */
  id?: number;
  city?: string | null;
  civility?: Civility;
  /** @format int32 */
  travelMax?: number;
  email?: string | null;
  matricule?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  comments?: string | null;
  internalNote?: string | null;
  phoneNumber?: string | null;
  secondaryPhoneNumber?: string | null;
  zipCode?: string | null;
  country?: string | null;
  address?: string | null;
  addressComplement?: string | null;
  socialSecurityNumber?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  /** @format double */
  hoursByDay?: number;
  reportEntryMode?: ReportEntryMode;
  /** @format int32 */
  contractId?: number;
  /** @format int32 */
  jobId?: number;
  /** @format int32 */
  userId?: number | null;
  /** @format double */
  grossDailyCost?: number;
  useCrm?: boolean;
}

export interface CollaboratorTagCollaboratorRequestDto {
  collaboratorIds?: number[] | null;
  collaboratorTagIds?: number[] | null;
}

export interface CollaboratorTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface CompanyContactRequestDto {
  /** @format int32 */
  contactId?: number;
  label?: string | null;
}

export interface CompanyIndicatorRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  typeField?: TypeField;
  options?: string | null;
  defaultValue?: string | null;
  /** @format int32 */
  institutionId?: number;
  /** @format int32 */
  companyTypeId?: number | null;
}

export interface CompanyRequestDto {
  /** @format int32 */
  id?: number;
  addresses?: AddressCompanyRequestDto[] | null;
  contacts?: CompanyContactRequestDto[] | null;
  color?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  secondaryPhoneNumber?: string | null;
  siren?: string | null;
  tvaNumber?: string | null;
  isParticular?: boolean;
  name?: string | null;
  civility?: Civility;
  lastName?: string | null;
  firstName?: string | null;
  notes?: string | null;
  companyAccount?: string | null;
  apeCode?: string | null;
  rcsCode?: string | null;
  source?: string | null;
  code?: string | null;
  internalNote?: string | null;
  website?: string | null;
  companyWorkforce?: CompanyWorkforceType;
  /** @format double */
  annualSales?: number | null;
  /** @format date-time */
  annualSalesYear?: string | null;
  /** @format int32 */
  companyTypeId?: number;
  /** @format int32 */
  collaboratorId?: number | null;
}

export interface CompanyTagCompanyRequestDto {
  companyIds?: number[] | null;
  companyTagIds?: number[] | null;
}

export interface CompanyTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
  /** @format int32 */
  companyTypeId?: number;
}

/** @format int32 */
export enum CompanyWorkforceType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface ContactRequestDto {
  /** @format int32 */
  id?: number;
  civility?: Civility;
  lastName?: string | null;
  firstName?: string | null;
  phoneNumber?: string | null;
  secondaryPhoneNumber?: string | null;
  email?: string | null;
  activity?: string | null;
  /** @format int32 */
  collaboratorId?: number | null;
}

export interface ContactTagContactRequestDto {
  contactIds?: number[] | null;
  contactTagIds?: number[] | null;
}

export interface ContactTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface ContractDetailRequestDto {
  /** @format int32 */
  id?: number;
  dayOfWeek?: DayOfWeek;
  /** @format date-time */
  date?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format double */
  duration?: number;
  /** @format int32 */
  payrollEventId?: number;
  /** @format int32 */
  contractId?: number;
}

/** @format int32 */
export enum ContractNature {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface ContractRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format double */
  hoursDayDefault?: number;
  /** @format double */
  hoursDayLimit?: number;
  /** @format double */
  hoursDayWarning?: number;
  /** @format double */
  hoursWeekDefault?: number;
  /** @format double */
  hoursWeekLimit?: number;
  /** @format double */
  hoursWeekWarning?: number;
  nature?: ContractNature;
  status?: ContractStatus;
}

/** @format int32 */
export enum ContractStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
}

export interface CreditDetailRequestDto {
  /** @format int32 */
  id?: number;
  index?: string | null;
  isDisplaySubtotal?: boolean;
  type?: DocumentLineType;
  description?: string | null;
  /** @format double */
  quantity?: number | null;
  /** @format int32 */
  unitId?: number | null;
  /** @format double */
  unitPriceHt?: number | null;
  /** @format int32 */
  referencielTvaId?: number | null;
  /** @format double */
  quantityDeducted?: number | null;
  reference?: string | null;
  /** @format double */
  situationProgress?: number | null;
  /** @format double */
  previousSituationProgress?: number | null;
  /** @format double */
  discount?: number | null;
  isDisabled?: boolean;
  isOption?: boolean;
  /** @format int32 */
  parentId?: number | null;
  /** @format int32 */
  creditId?: number | null;
  /** @format int32 */
  productId?: number | null;
  /** @format int32 */
  analyticSettingId?: number | null;
}

export interface CreditOptionRequestDto {
  /** @format int32 */
  id?: number;
  showSituationDetails?: boolean;
  calculateMargin?: boolean;
  calculateUnitPriceHtByUnitPurchasePriceHt?: boolean;
  showDiscountColumn?: boolean;
  isReverseCharge?: boolean;
  hideQuantityColumn?: boolean;
  hideReferencielTvaColumn?: boolean;
  hideLinesQuantityEmpty?: boolean;
  hideUnitPriceHtColumn?: boolean;
  hidePriceHtColumn?: boolean;
  hideTotal?: boolean;
  showDiscount?: boolean;
  hidePaymentConditions?: boolean;
  hideCompany?: boolean;
  showCompanySiret?: boolean;
  showCompanyAddressLabel?: boolean;
  showCompanyMail?: boolean;
  showCompanyPhoneNumber?: boolean;
  showDetailWorkLine?: boolean;
  showCompanyCode?: boolean;
  showProductPicturesAttached?: boolean;
  showCompanyTva?: boolean;
  showWaterMark?: boolean;
  showDescription?: boolean;
  showThumbnail?: boolean;
  showReferenceColumn?: boolean;
  showAffairCode?: boolean;
  hideUser?: boolean;
  hideInstitutionLogo?: boolean;
  hideInstitutionName?: boolean;
  hideInstitutionAddress?: boolean;
  hideAffair?: boolean;
  hideIndexColumn?: boolean;
  hideUnitColumn?: boolean;
  /** @format int32 */
  paymentConditionDefaultId?: number | null;
  /** @format int32 */
  documentNumber?: number;
  additionalLegalNotices?: string | null;
  comment?: string | null;
  showTermsOfSales?: boolean;
  /** @format int32 */
  creditId?: number;
}

export interface CreditProviderRepartitionRequestDto {
  /** @format int32 */
  affairId?: number;
  /** @format int32 */
  companyId?: number;
  /** @format double */
  priceHt?: number;
  /** @format int32 */
  referencielTvaId?: number;
  /** @format int32 */
  index?: number;
}

export interface CreditProviderRequestDto {
  /** @format int32 */
  id?: number;
  documentReference?: string | null;
  /** @format date-time */
  documentDate?: string;
  /** @format date-time */
  valueDate?: string | null;
  description?: string | null;
  /** @format double */
  totalHT?: number;
  /** @format double */
  totalTTC?: number;
  /** @format double */
  remainingToPayTtc?: number;
  /** @format int32 */
  purchaseCategoryId?: number | null;
  path?: string | null;
  isProcessed?: boolean;
  creditProviderStatus?: CreditProviderStatus;
  /** @format int32 */
  folderId?: number | null;
  /** @format int32 */
  providerId?: number;
  internalNote?: string | null;
  /** @format int32 */
  invoiceProviderId?: number | null;
  source?: string | null;
  /** @format date-time */
  sourceDate?: string | null;
  /** @format int32 */
  institutionId?: number;
}

/** @format int32 */
export enum CreditProviderStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface CreditProviderTagCreditProviderRequestDto {
  creditProviderIds?: number[] | null;
  creditProviderTagIds?: number[] | null;
}

export interface CreditProviderTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface CreditProviderTvaRequestDto {
  /** @format int32 */
  referentielTvaId?: number;
  /** @format double */
  value?: number;
  /** @format double */
  valueTva?: number;
}

export interface CreditRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  affairAddress?: string | null;
  affairAddressComplement?: string | null;
  affairCity?: string | null;
  affairZipCode?: string | null;
  affairCountry?: string | null;
  /** @format date-time */
  documentDate?: string;
  /** @format double */
  discountGlobalNumber?: number;
  discountGlobalType?: AmountType;
  nature?: Nature;
  description?: string | null;
  path?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  comments?: string | null;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  status?: DocumentStatus;
  /** @format int32 */
  companyId?: number;
  companyName?: string | null;
  companyAddress?: string | null;
  companyAddressComplement?: string | null;
  companyZipCode?: string | null;
  companyCity?: string | null;
  companyCountry?: string | null;
  companyPhoneNumber?: string | null;
  companyMail?: string | null;
  companyAddressLabel?: string | null;
  /** @format int32 */
  companyAddressId?: number | null;
  companyCode?: string | null;
  footer?: string | null;
  footerType?: FooterType;
  /** @format double */
  totalHt?: number;
  /** @format double */
  totalTtc?: number;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
  isCounted?: boolean;
  /** @format date-time */
  countedDate?: string;
  /** @format double */
  remainingToPayTtc?: number;
  documentTvas?: DocumentTvaRequestDto[] | null;
  source?: string | null;
  usedUrlGed?: boolean;
  affairCode?: string | null;
  /** @format int32 */
  invoiceId?: number | null;
  internalNote?: string | null;
  additionalLegalNotices?: string | null;
  /** @format int32 */
  folderId?: number | null;
}

export interface CreditTagCreditRequestDto {
  creditIds?: number[] | null;
  creditTagIds?: number[] | null;
}

export interface CreditTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface CustomEmailTemplateRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  subject?: string | null;
  key?: string | null;
  template?: string | null;
}

export interface CustomFieldGroupRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
  /** @format int32 */
  position?: number;
  /** @format int32 */
  customFieldTemplateId?: number;
}

export interface CustomFieldRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  position?: number;
  label?: string | null;
  description?: string | null;
  code?: string | null;
  typeField?: TypeField;
  options?: string | null;
  params?: string | null;
  isRequired?: boolean;
  defaultValue?: string | null;
  /** @format int32 */
  customFieldGroupId?: number;
}

export interface CustomFieldTemplateRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  institutionId?: number;
  type?: CustomFieldTemplateType;
}

/** @format int32 */
export enum CustomFieldTemplateType {
  Value0 = 0,
  Value1 = 1,
}

export interface CustomPerimeterRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  parentId?: number | null;
}

/** @format int32 */
export enum DayOfWeek {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface DeductionRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  holdbackNumber?: number | null;
  /** @format double */
  holdbackPercent?: number | null;
  /** @format date-time */
  holdbackDate?: string | null;
  prorataAccountLabel?: string | null;
  /** @format double */
  prorataAccountPrice?: number | null;
  /** @format double */
  prorataAccountPercent?: number | null;
  punctualDeductions?: PunctualDeductionRequestDto[] | null;
  reviews?: ReviewRequestDto[] | null;
  /** @format int32 */
  invoiceId?: number | null;
  /** @format int32 */
  quoteId?: number | null;
  /** @format int32 */
  creditId?: number | null;
  /** @format int32 */
  orderFormCustomerId?: number | null;
}

export interface DegressivePurchaseRatesRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  quantityMin?: number;
  /** @format double */
  price?: number;
  /** @format double */
  quantityMax?: number | null;
  /** @format double */
  discount?: number;
  /** @format int32 */
  productProviderId?: number;
  /** @format int32 */
  grossMarginHt?: number | null;
  /** @format double */
  marginRate?: number | null;
}

export interface DegressiveSalesRatesRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  quantityMin?: number;
  /** @format double */
  price?: number;
  /** @format double */
  quantityMax?: number | null;
  /** @format double */
  discount?: number;
  /** @format double */
  grossMarginHt?: number;
  /** @format double */
  marginRate?: number;
  /** @format int32 */
  productId?: number;
}

export interface DeliveryFormDetailRequestDto {
  /** @format int32 */
  id?: number;
  index?: string | null;
  reference?: string | null;
  description?: string | null;
  /** @format double */
  outPutQuantity?: number;
  /** @format int32 */
  deliveryFormId?: number;
  /** @format int32 */
  unitId?: number;
  /** @format int32 */
  productId?: number | null;
  type?: FormsDetailsType;
  /** @format double */
  unitPriceHt?: number | null;
  /** @format int32 */
  referencielTvaId?: number;
  /** @format int32 */
  analyticSettingId?: number | null;
}

export interface DeliveryFormOptionRequestDto {
  /** @format int32 */
  id?: number;
  hideCompanyCustomer?: boolean;
  isReverseCharge?: boolean;
  showDiscount?: boolean;
  hideCollaborator?: boolean;
  hidePriceHtColumn?: boolean;
  hideReferencielTvaColumn?: boolean;
  hideUnitPriceHtColumn?: boolean;
  hideDestockingLocation?: boolean;
  hideOutPutQuantity?: boolean;
  showCompanyCustomerPhoneNumber?: boolean;
  showCompanyCustomerMail?: boolean;
  showCompanyCustomerCode?: boolean;
  showCompanyCustomerTvaNumber?: boolean;
  showCompanyCustomerSiret?: boolean;
  showCompanyCustomerAddressLabel?: boolean;
  hideStorageLocation?: boolean;
  showReferenceColumn?: boolean;
  showAffairCode?: boolean;
  hideUser?: boolean;
  hideInstitutionLogo?: boolean;
  hideInstitutionName?: boolean;
  hideInstitutionAddress?: boolean;
  hideAffair?: boolean;
  hideIndexColumn?: boolean;
  hideUnitColumn?: boolean;
  comment?: string | null;
  /** @format int32 */
  deliveryFormId?: number;
}

export interface DeliveryFormRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  affairCode?: string | null;
  affairAddress?: string | null;
  affairAddressComplement?: string | null;
  affairCity?: string | null;
  affairZipCode?: string | null;
  affairCountry?: string | null;
  comments?: string | null;
  /** @format int32 */
  companyCustomerId?: number | null;
  companyCustomerName?: string | null;
  companyCustomerCode?: string | null;
  companyCustomerAddress?: string | null;
  companyCustomerAddressComplement?: string | null;
  companyCustomerCity?: string | null;
  companyCustomerZipCode?: string | null;
  companyCustomerCountry?: string | null;
  companyCustomerPhoneNumber?: string | null;
  companyCustomerMail?: string | null;
  companyCustomerSiret?: string | null;
  companyCustomerTva?: string | null;
  /** @format int32 */
  companyCustomerAddressId?: number | null;
  companyCustomerAddressLabel?: string | null;
  status?: DeliveryFormStatus;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  /** @format date-time */
  documentDate?: string;
  /** @format date-time */
  deliveryDate?: string;
  /** @format date-time */
  receiptDate?: string;
  /** @format int32 */
  deliveredQuantity?: number;
  /** @format int32 */
  folderId?: number | null;
  internalNote?: string | null;
  path?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  /** @format double */
  valueHt?: number;
  deliveryType?: DeliveryType;
  /** @format int32 */
  collaboratorId?: number | null;
  collaboratorFirstName?: string | null;
  collaboratorLastName?: string | null;
  collaboratorMail?: string | null;
  collaboratorPhoneNumber?: string | null;
  collaboratorSecondaryPhoneNumber?: string | null;
  /** @format int32 */
  storageLocationId?: number | null;
  storageLocationLabel?: string | null;
  storageLocationType?: StorageLocationType;
  storageLocationAddress?: string | null;
  storageLocationAddressComplement?: string | null;
  storageLocationZipCode?: string | null;
  storageLocationCity?: string | null;
  storageLocationPhoneNumber?: string | null;
  storageLocationEmail?: string | null;
  /** @format int32 */
  destockingLocationId?: number | null;
  destockingLocationLabel?: string | null;
  destockingLocationType?: StorageLocationType;
  destockingLocationAddress?: string | null;
  destockingLocationAddressComplement?: string | null;
  destockingLocationZipCode?: string | null;
  destockingLocationCity?: string | null;
  destockingLocationPhoneNumber?: string | null;
  destockingLocationEmail?: string | null;
  /** @format int32 */
  handDeliveryContactId?: number | null;
  handDeliveryContactCivility?: Civility;
  handDeliveryContactFirstName?: string | null;
  handDeliveryContactLastName?: string | null;
  handDeliveryContactPhoneNumber?: string | null;
  handDeliveryContactEmail?: string | null;
  /** @format int32 */
  companySubContractorId?: number | null;
  companySubContractorName?: string | null;
  companySubContractorAddress?: string | null;
  companySubContractorAddressComplement?: string | null;
  companySubContractorCity?: string | null;
  companySubContractorZipCode?: string | null;
  companySubContractorCountry?: string | null;
  companySubContractorPhoneNumber?: string | null;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
  /** @format int32 */
  quoteId?: number | null;
  /** @format int32 */
  orderFormCustomerId?: number | null;
}

/** @format int32 */
export enum DeliveryFormStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface DeliveryFormTagDeliveryFormRequestDto {
  deliveryFormIds?: number[] | null;
  deliveryFormTagIds?: number[] | null;
}

export interface DeliveryFormTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface DeliveryFormTvaRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  value?: number;
  /** @format double */
  valueTva?: number;
  /** @format int32 */
  referencialTvaId?: number;
  /** @format int32 */
  deliveryFormId?: number;
}

/** @format int32 */
export enum DeliveryType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum DestockingType {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum Direction {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum DocumentApplicationType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
}

export interface DocumentDetailRequestDto {
  /** @format int32 */
  id?: number;
  index?: string | null;
  type?: DocumentLineType;
  description?: string | null;
  /** @format double */
  quantity?: number | null;
  /** @format int32 */
  unitId?: number | null;
  /** @format double */
  unitPriceHt?: number | null;
  /** @format int32 */
  referencielTvaId?: number | null;
  /** @format double */
  disbursedNetHt?: number | null;
  /** @format double */
  marginBrutHt?: number | null;
  /** @format double */
  marginRate?: number | null;
  /** @format double */
  quantityDeducted?: number | null;
  reference?: string | null;
  /** @format double */
  situationProgress?: number | null;
  /** @format double */
  previousSituationProgress?: number | null;
  /** @format double */
  situationProgressQuantity?: number;
  /** @format double */
  previousSituationProgressQuantity?: number;
  /** @format double */
  publicPrice?: number | null;
  /** @format double */
  discount?: number | null;
  isDisabled?: boolean;
  /** @format int32 */
  companyPriceId?: number | null;
  isOption?: boolean;
  /** @format double */
  marginCoefficient?: number | null;
  /** @format int32 */
  parentId?: number | null;
  /** @format int32 */
  quoteId?: number | null;
  /** @format int32 */
  invoiceId?: number | null;
  /** @format int32 */
  productId?: number | null;
  /** @format int32 */
  analyticSettingId?: number | null;
}

/** @format int32 */
export enum DocumentLineType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
}

/** @format int32 */
export enum DocumentStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
}

export interface DocumentTvaRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  value?: number;
  /** @format double */
  valueTva?: number;
  /** @format int32 */
  referencielTvaId?: number;
  /** @format int32 */
  quoteId?: number | null;
  /** @format int32 */
  invoiceId?: number | null;
  /** @format int32 */
  creditId?: number | null;
  /** @format int32 */
  orderFormCustomerId?: number | null;
}

/** @format int32 */
export enum DocumentType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** @format int32 */
export enum DocumentValidity {
  Value0 = 0,
  Value7 = 7,
  Value15 = 15,
  Value30 = 30,
  Value45 = 45,
  Value60 = 60,
  Value90 = 90,
}

/** @format int32 */
export enum Duration {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface EquipmentRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  reference?: string | null;
  /** @format int32 */
  serialNumber?: number;
  state?: EquipmentState;
  /** @format date-time */
  entryDate?: string;
  /** @format date-time */
  exitDate?: string;
}

/** @format int32 */
export enum EquipmentState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface EventDetailRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  eventId?: number;
  /** @format date-time */
  date?: string;
  /** @format double */
  duration?: number;
  shortLabel?: string | null;
  /** @format date-time */
  startTimeSlot?: string;
  /** @format date-time */
  endTimeSlot?: string;
}

export interface EventRequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  name?: string | null;
  comments?: string | null;
  resizable?: boolean;
  draggable?: boolean;
  cls?: string | null;
  /** @format int32 */
  companyId?: number | null;
  /** @format int32 */
  affairId?: number | null;
  /** @format int32 */
  missionId?: number | null;
  /** @format int32 */
  eventTypeId?: number;
  isPersonal?: boolean;
  /** @format double */
  hoursDay?: number | null;
  collaboratorIds?: number[] | null;
}

export interface EventTypeRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
  isDependent?: boolean;
  isEditable?: boolean;
  description?: string | null;
  /** @format int32 */
  payrollEventId?: number | null;
}

export interface FileRequestDto {
  /** @format int32 */
  id?: number;
  fileApplication?: boolean;
  name?: string | null;
  /** @format binary */
  file?: File | null;
  folderIds?: number[] | null;
  /** @format int32 */
  institutionId?: number;
}

export interface FiscalYearRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  institutionSettingId?: number;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
}

export interface FolderRequestDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  folderApplication?: boolean;
  /** @format int32 */
  parentId?: number | null;
}

/** @format int32 */
export enum FooterType {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum FormsDetailsType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
}

export interface FreeLinePlanningLineRequestDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  subName?: string | null;
}

/** @format int32 */
export enum GuaranteeType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface HoldbackRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  invoiceId?: number;
  documentReference?: string | null;
  /** @format date-time */
  date?: string | null;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  /** @format int32 */
  companyId?: number;
  companyName?: string | null;
  companyAddress?: string | null;
  companyAddressComplement?: string | null;
  companyZipCode?: string | null;
  companyCity?: string | null;
  companyCountry?: string | null;
  companyCode?: string | null;
  status?: HoldbackStatus;
  /** @format double */
  totalTtc?: number;
  /** @format double */
  remainingToPayTtc?: number;
  /** @format date-time */
  limiteDate?: string;
}

/** @format int32 */
export enum HoldbackStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface IndexRequestDto {
  /** @format int32 */
  id?: number;
  index?: string | null;
}

export interface InstitutionPerimeterRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  parentId?: number | null;
}

export interface InstitutionRequestDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  isDefault?: boolean;
  legalForm?: LegalForm;
  address?: string | null;
  addressComplement?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
  secondaryPhoneNumber?: string | null;
  email?: string | null;
  webSite?: string | null;
  registrationNumber?: string | null;
  isSubjectTVA?: boolean;
  tvaNumber?: string | null;
  siret?: string | null;
  rcsCity?: string | null;
  /** @format double */
  capital?: number | null;
  apeCode?: string | null;
  guaranteeType?: GuaranteeType;
  insuranceName?: string | null;
  insuranceCoverage?: string | null;
  insuranceAddress?: string | null;
  insuranceAddressComplement?: string | null;
  insuranceZipCode?: string | null;
  insuranceCity?: string | null;
  insuranceCountry?: string | null;
  color?: string | null;
  /** @format int32 */
  logoWidth?: number;
  secondaryColor?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  code?: string | null;
}

export interface InstitutionSettingPaymentConditionRequestDto {
  /** @format int32 */
  paymentConditionId?: number;
  isPaymentConditionDefault?: boolean;
}

export interface InstitutionSettingReferencielTvaRequestDto {
  /** @format int32 */
  referencielTvaId?: number;
  creditAccount?: string | null;
  collectedTvaAccount?: string | null;
  isDefault?: boolean;
}

export interface InstitutionSettingRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  institutionId?: number;
  /** @format double */
  supplyMarginDefault?: number | null;
  /** @format double */
  manPowerMarginDefault?: number;
  /** @format double */
  subContractingMarginDefault?: number;
  /** @format double */
  equipementMarginDefault?: number;
  /** @format double */
  variousMarginDefault?: number;
  footer?: string | null;
  footerType?: FooterType;
  /** @format int32 */
  laborMarginDefault?: number;
  termsOfSales?: string | null;
  holdBackAccount?: string | null;
  forceSuggestAffair?: boolean;
}

export interface Int32StringTuple {
  /** @format int32 */
  item1?: number;
  item2?: string | null;
}

export interface InterventionListRequestDto {
  /** @format int32 */
  customFieldTemplateId?: number | null;
  dates?: string[] | null;
  collaboratorIds?: number[] | null;
  /** @format double */
  duration?: number;
  status?: InterventionStatus;
  /** @format int32 */
  institutionId?: number;
  /** @format int32 */
  companyId?: number;
  /** @format int32 */
  affairId?: number;
  message?: string | null;
}

export interface InterventionRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  customFieldTemplateId?: number | null;
  /** @format date-time */
  date?: string;
  collaboratorIds?: number[] | null;
  /** @format double */
  duration?: number;
  status?: InterventionStatus;
  /** @format int32 */
  companyId?: number;
  /** @format int32 */
  affairId?: number;
  message?: string | null;
}

/** @format int32 */
export enum InterventionStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface InventoryDetailRequestDto {
  /** @format int32 */
  id?: number;
  reference?: string | null;
  description?: string | null;
  /** @format double */
  quantity?: number | null;
  /** @format int32 */
  unitId?: number;
  /** @format int32 */
  productId?: number;
  /** @format int32 */
  inventoryId?: number;
}

export interface InventoryRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  description?: string | null;
  status?: InventoryStatus;
  path?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  note?: string | null;
  /** @format date-time */
  inventoryDate?: string;
  /** @format int32 */
  referenceCounted?: number;
  /** @format int32 */
  collaboratorId?: number | null;
  /** @format int32 */
  storageLocationId?: number | null;
  internalNote?: string | null;
  isShowDescription?: boolean;
  isHideUser?: boolean;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
}

/** @format int32 */
export enum InventoryStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface InvoiceBTPRequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format int32 */
  estimatedDurationNumber?: number | null;
  estimatedDurationType?: Duration;
}

export interface InvoiceCEERequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  technicalVisitDate?: string;
  /** @format double */
  amountPremium?: number;
  precarityType?: string | null;
  parcelNumber?: string | null;
}

export interface InvoiceOptionRequestDto {
  /** @format int32 */
  id?: number;
  hideBankAccount?: boolean;
  showSituationDetails?: boolean;
  calculateMargin?: boolean;
  calculateUnitPriceHtByUnitPurchasePriceHt?: boolean;
  showDiscountColumn?: boolean;
  isReverseCharge?: boolean;
  hideQuantityColumn?: boolean;
  hideReferencielTvaColumn?: boolean;
  hideLinesQuantityEmpty?: boolean;
  hideUnitPriceHtColumn?: boolean;
  hidePriceHtColumn?: boolean;
  hideTotal?: boolean;
  showDiscount?: boolean;
  hidePaymentConditions?: boolean;
  hideCompany?: boolean;
  showCompanySiret?: boolean;
  showCompanyAddressLabel?: boolean;
  showCompanyMail?: boolean;
  showCompanyPhoneNumber?: boolean;
  showDetailWorkLine?: boolean;
  showCompanyCode?: boolean;
  showProductPicturesAttached?: boolean;
  showCompanyTva?: boolean;
  showWaterMark?: boolean;
  showDescription?: boolean;
  showThumbnail?: boolean;
  showReferenceColumn?: boolean;
  showAffairCode?: boolean;
  hideUser?: boolean;
  hideInstitutionLogo?: boolean;
  hideInstitutionName?: boolean;
  hideInstitutionAddress?: boolean;
  hideAffair?: boolean;
  hideIndexColumn?: boolean;
  hideUnitColumn?: boolean;
  /** @format int32 */
  paymentConditionDefaultId?: number | null;
  additionalLegalNotices?: string | null;
  comment?: string | null;
  showTermsOfSales?: boolean;
  /** @format int32 */
  invoiceId?: number;
}

export interface InvoiceProviderRepartitionRequestDto {
  /** @format int32 */
  affairId?: number;
  /** @format int32 */
  companyId?: number;
  /** @format double */
  priceHt?: number;
  /** @format int32 */
  referencielTvaId?: number;
  /** @format int32 */
  index?: number;
}

export interface InvoiceProviderRequestDto {
  /** @format int32 */
  id?: number;
  documentReference?: string | null;
  /** @format date-time */
  documentDate?: string;
  /** @format date-time */
  valueDate?: string | null;
  description?: string | null;
  /** @format double */
  totalHT?: number | null;
  /** @format double */
  totalTTC?: number | null;
  /** @format double */
  remainingToPayTtc?: number;
  /** @format int32 */
  purchaseCategoryId?: number | null;
  /** @format int32 */
  providerId?: number | null;
  path?: string | null;
  isProcessed?: boolean;
  /** @format int32 */
  folderId?: number | null;
  internalNote?: string | null;
  source?: string | null;
  /** @format date-time */
  sourceDate?: string | null;
  /** @format int32 */
  institutionId?: number;
  invoiceProviderStatus?: InvoiceProviderStatus;
}

/** @format int32 */
export enum InvoiceProviderStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface InvoiceProviderTagInvoiceProviderRequestDto {
  invoiceProviderIds?: number[] | null;
  invoiceProviderTagIds?: number[] | null;
}

export interface InvoiceProviderTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface InvoiceProviderTvaRequestDto {
  /** @format int32 */
  referentielTvaId?: number;
  /** @format double */
  value?: number;
  /** @format double */
  valueTva?: number;
}

export interface InvoiceRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  affairAddress?: string | null;
  affairAddressComplement?: string | null;
  affairCity?: string | null;
  affairZipCode?: string | null;
  affairCountry?: string | null;
  hideAffair?: boolean;
  type?: DocumentType;
  nature?: Nature;
  /** @format date-time */
  countedDate?: string;
  isCounted?: boolean;
  /** @format double */
  discountGlobalNumber?: number;
  discountGlobalType?: AmountType;
  /** @format date-time */
  documentDate?: string;
  /** @format date-time */
  limitDate?: string | null;
  comments?: string | null;
  description?: string | null;
  path?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  /** @format int32 */
  documentStep?: number | null;
  source?: string | null;
  usedUrlGed?: boolean;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format double */
  remainingToPayTtc?: number;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  isTemplate?: boolean;
  /** @format int32 */
  bankAccountId?: number | null;
  bankAccountLabel?: string | null;
  bankAccountName?: string | null;
  bankAccountIban?: string | null;
  bankAccountBic?: string | null;
  /** @format double */
  marginBrutHt?: number | null;
  /** @format double */
  marginRate?: number | null;
  status?: DocumentStatus;
  /** @format int32 */
  companyId?: number;
  companyName?: string | null;
  companyAddress?: string | null;
  companyAddressComplement?: string | null;
  companyZipCode?: string | null;
  companyCity?: string | null;
  companyCountry?: string | null;
  companyPhoneNumber?: string | null;
  companyMail?: string | null;
  companySiret?: string | null;
  companyTva?: string | null;
  companyAddressLabel?: string | null;
  /** @format int32 */
  companyAddressId?: number | null;
  companyCode?: string | null;
  footer?: string | null;
  footerType?: FooterType;
  /** @format double */
  totalHt?: number;
  /** @format double */
  totalTtc?: number;
  /** @format int32 */
  paymentConditionId?: number;
  /** @format int32 */
  orderFormCustomerId?: number | null;
  /** @format int32 */
  quoteId?: number | null;
  invoiceCEE?: InvoiceCEERequestDto;
  invoiceBTP?: InvoiceBTPRequestDto;
  documentTvas?: DocumentTvaRequestDto[] | null;
  affairCode?: string | null;
  showThumbnail?: boolean;
  /** @format int32 */
  folderId?: number | null;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
  internalNote?: string | null;
  additionalLegalNotices?: string | null;
  label?: string | null;
  situationType?: boolean;
}

export interface InvoiceTagInvoiceRequestDto {
  invoiceIds?: number[] | null;
  invoiceTagIds?: number[] | null;
}

export interface InvoiceTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface JobRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  institutionId?: number | null;
}

export interface JournalRequestDto {
  /** @format int32 */
  id?: number;
  code?: string | null;
  label?: string | null;
  type?: JournalType;
}

/** @format int32 */
export enum JournalType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum LeaseDurationType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

export interface LeaseRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  codeCRO?: string | null;
  /** @format int32 */
  propertyId?: number | null;
  propertyArea?: string | null;
  propertyAddress?: string | null;
  propertyAddressComplement?: string | null;
  propertyZipCode?: string | null;
  propertyCity?: string | null;
  propertyCountry?: string | null;
  /** @format double */
  propertyTaxProvisioned?: number | null;
  /** @format double */
  propertyLatitude?: number | null;
  /** @format double */
  propertyLongitude?: number | null;
  tenantFirstName?: string | null;
  tenantLastName?: string | null;
  landlordName?: string | null;
  landlordAddress?: string | null;
  landlordAddressComplement?: string | null;
  landlordZipCode?: string | null;
  landlordCity?: string | null;
  landlordCountry?: string | null;
  /** @format int32 */
  landlordContactId?: number | null;
  landlordContactCivility?: Civility;
  landlordContactFirstName?: string | null;
  landlordContactLastName?: string | null;
  landlordContactEmail?: string | null;
  landlordContactPhone?: string | null;
  tenantId?: string | null;
  tenantName?: string | null;
  tenantAddress?: string | null;
  tenantAddressComplement?: string | null;
  tenantZipCode?: string | null;
  tenantCity?: string | null;
  tenantCountry?: string | null;
  /** @format int32 */
  tenantContactId?: number | null;
  tenantContactCivility?: Civility;
  tenantContactFirstName?: string | null;
  tenantContactLastName?: string | null;
  tenantContactEmail?: string | null;
  tenantContactPhone?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format double */
  duration?: number | null;
  durationUnit?: LeaseDurationType;
  /** @format int32 */
  paymentDay?: number | null;
  /** @format double */
  rentHcHt?: number | null;
  /** @format int32 */
  tvaRentId?: number | null;
  isChargesPackage?: boolean;
  /** @format double */
  chargeHt?: number | null;
  /** @format int32 */
  tvaChargeId?: number | null;
  /** @format double */
  securityDeposit?: number | null;
  tenantIsParticular?: boolean;
  tenantCivility?: Civility;
  isRentReview?: boolean;
  isSubscriptReferenceRentReview?: boolean;
  rentReviewSubScriptType?: string | null;
  rentReviewSubScript?: string | null;
  /** @format double */
  rentReviewPercentage?: number | null;
  /** @format int32 */
  rentReviewPeriodicityId?: number | null;
  isSecurityDepositReview?: boolean;
  /** @format int32 */
  securityDepositReviewPeriodicityId?: number | null;
  /** @format date-time */
  startDateFirstReceipt?: string | null;
  subscriptFirstReceipt?: string | null;
  /** @format date-time */
  endDateFirstQuittance?: string | null;
  /** @format int32 */
  periodicityId?: number | null;
  /** @format int32 */
  propertyTypeId?: number | null;
  /** @format int32 */
  leaseTypeId?: number | null;
  /** @format int32 */
  affairId?: number;
  /** @format int32 */
  institutionId?: number;
  /** @format int32 */
  tvaSecurityDepositId?: number | null;
  /** @format int32 */
  tvaPropertyTaxId?: number | null;
  tacitAgreement?: boolean;
  /** @format double */
  prorataShare?: number | null;
}

export interface LeaseTypeRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  description?: string | null;
}

/** @format int32 */
export enum LegalForm {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

export interface LinePositionRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  position?: number;
  name?: string | null;
  color?: string | null;
  group?: string | null;
  isLocked?: boolean;
  typeLine?: TypeLine;
  /** @format int32 */
  planningLineId?: number | null;
}

/** @format int32 */
export enum MeasureUnit {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface MissionRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  parentId?: number;
  label?: string | null;
  /** @format int32 */
  analyticsSettingId?: number;
  /** @format int32 */
  unitId?: number | null;
  /** @format double */
  estimatedQuantity?: number;
  /** @format double */
  estimatedUnitPrice?: number;
  /** @format double */
  advancedActualQuantity?: number;
  /** @format double */
  advancedValuatedQuantity?: number;
  /** @format int32 */
  affairId?: number | null;
  /** @format date-time */
  date?: string;
}

export interface ModuleParameterRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  key?: string | null;
  value?: string | null;
  /** @format int32 */
  moduleId?: number;
  /** @format int32 */
  workspaceId?: number;
}

export interface ModuleRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
}

/** @format int32 */
export enum Nature {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface OrderFormCustomerBtpRequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format int32 */
  estimatedDurationNumber?: number | null;
  estimatedDurationType?: Duration;
  /** @format double */
  wasteVolumeFrom?: number;
  /** @format double */
  wasteVolumeTo?: number;
  /** @format int32 */
  wasteVolumeUnity?: number;
  /** @format double */
  wastePriceTtc?: number;
  wasteCollectionPointIds?: number[] | null;
  wasteTypeIds?: number[] | null;
}

export interface OrderFormCustomerCeeRequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  technicalVisitDate?: string;
  /** @format double */
  amountPremium?: number;
  precarityType?: string | null;
  parceNumber?: string | null;
}

export interface OrderFormCustomerDetailRequestDto {
  /** @format int32 */
  id?: number;
  index?: string | null;
  type?: DocumentLineType;
  description?: string | null;
  /** @format double */
  quantity?: number | null;
  /** @format int32 */
  unitId?: number | null;
  /** @format double */
  unitPriceHt?: number | null;
  /** @format int32 */
  referencielTvaId?: number | null;
  /** @format double */
  disbursedNetHt?: number | null;
  /** @format double */
  marginBrutHt?: number | null;
  /** @format double */
  marginRate?: number | null;
  /** @format double */
  quantityDeducted?: number | null;
  reference?: string | null;
  /** @format double */
  situationProgress?: number | null;
  /** @format double */
  previousSituationProgress?: number | null;
  /** @format double */
  publicPrice?: number | null;
  /** @format double */
  discount?: number | null;
  isDisabled?: boolean;
  /** @format int32 */
  companyPriceId?: number | null;
  isOption?: boolean;
  /** @format double */
  marginCoefficient?: number | null;
  /** @format int32 */
  parentId?: number | null;
  /** @format int32 */
  orderFormCustomerId?: number;
  /** @format int32 */
  productId?: number | null;
  /** @format int32 */
  analyticSettingId?: number | null;
}

export interface OrderFormCustomerDownPaymentsRequestRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  number?: number;
  downPaymentRequestType?: AmountType;
  downPaymentRequestDue?: PaymentRequestDue;
  label?: string | null;
  /** @format int32 */
  orderFormCustomerId?: number | null;
  /** @format int32 */
  institutionSettingsId?: number | null;
}

export interface OrderFormCustomerOptionRequestDto {
  /** @format int32 */
  id?: number;
  hideBankAccount?: boolean;
  calculateMargin?: boolean;
  calculateUnitPriceHtByUnitPurchasePriceHt?: boolean;
  showDiscountColumn?: boolean;
  isReverseCharge?: boolean;
  hideQuantityColumn?: boolean;
  hideReferencielTvaColumn?: boolean;
  hideLinesQuantityEmpty?: boolean;
  hideUnitPriceHtColumn?: boolean;
  hidePriceHtColumn?: boolean;
  hideTotal?: boolean;
  showDiscount?: boolean;
  hidePaymentConditions?: boolean;
  hideCompany?: boolean;
  showCompanySiret?: boolean;
  showCompanyAddressLabel?: boolean;
  showCompanyMail?: boolean;
  showCompanyPhoneNumber?: boolean;
  showDetailWorkLine?: boolean;
  showCompanyCode?: boolean;
  showProductPicturesAttached?: boolean;
  showCompanyTva?: boolean;
  showWaterMark?: boolean;
  showDescription?: boolean;
  showThumbnail?: boolean;
  showReferenceColumn?: boolean;
  showAffairCode?: boolean;
  hideUser?: boolean;
  hideInstitutionLogo?: boolean;
  hideInstitutionName?: boolean;
  hideInstitutionAddress?: boolean;
  hideAffair?: boolean;
  hideIndexColumn?: boolean;
  hideUnitColumn?: boolean;
  documentValidity?: DocumentValidity;
  additionalLegalNotices?: string | null;
  comment?: string | null;
  showTermsOfSales?: boolean;
  /** @format int32 */
  orderFormCustomerId?: number;
}

export interface OrderFormCustomerRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  affairAddress?: string | null;
  affairAddressComplement?: string | null;
  affairCity?: string | null;
  affairZipCode?: string | null;
  affairCountry?: string | null;
  affairCode?: string | null;
  type?: DocumentType;
  /** @format double */
  discountGlobalNumber?: number;
  discountGlobalType?: AmountType;
  /** @format date-time */
  documentDate?: string;
  /** @format date-time */
  limitDate?: string;
  /** @format int32 */
  validityDocument?: number;
  comments?: string | null;
  description?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  isTemplate?: boolean;
  /** @format int32 */
  bankAccountId?: number | null;
  bankAccountLabel?: string | null;
  bankAccountName?: string | null;
  bankAccountIban?: string | null;
  bankAccountBic?: string | null;
  status?: OrderFormCustomerStatus;
  /** @format int32 */
  companyId?: number;
  companyName?: string | null;
  companyAddress?: string | null;
  companyAddressComplement?: string | null;
  companyZipCode?: string | null;
  companyCity?: string | null;
  companyCountry?: string | null;
  companyPhoneNumber?: string | null;
  companyMail?: string | null;
  companySiret?: string | null;
  companyTva?: string | null;
  /** @format int32 */
  companyAddressId?: number | null;
  companyAddressLabel?: string | null;
  companyCode?: string | null;
  footer?: string | null;
  footerType?: FooterType;
  /** @format double */
  totalHt?: number;
  /** @format double */
  totalTtc?: number;
  /** @format double */
  marginBrutHt?: number | null;
  /** @format double */
  marginRate?: number | null;
  documentTvas?: DocumentTvaRequestDto[] | null;
  orderFormCustomerBtp?: OrderFormCustomerBtpRequestDto;
  orderFormCustomerCee?: OrderFormCustomerCeeRequestDto;
  path?: string | null;
  source?: string | null;
  usedUrlGed?: boolean;
  internalNote?: string | null;
  additionalLegalNotices?: string | null;
  /** @format int32 */
  folderId?: number | null;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
  /** @format int32 */
  quoteId?: number | null;
  /** @format double */
  remainingToBillHt?: number | null;
  label?: string | null;
}

/** @format int32 */
export enum OrderFormCustomerStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface OrderFormCustomerTagOrderFormCustomerRequestDto {
  orderFormCustomerIds?: number[] | null;
  orderFormCustomerTagIds?: number[] | null;
}

export interface OrderFormCustomerTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface OrderFormDetailRequestDto {
  /** @format int32 */
  id?: number;
  index?: string | null;
  reference?: string | null;
  description?: string | null;
  /** @format double */
  quantity?: number;
  /** @format double */
  discount?: number | null;
  /** @format double */
  unitPriceHt?: number | null;
  type?: FormsDetailsType;
  /** @format int32 */
  unitId?: number;
  /** @format double */
  amountHt?: number;
  /** @format int32 */
  referencielTvaId?: number;
  /** @format int32 */
  productProviderId?: number | null;
  /** @format int32 */
  orderFormId?: number;
  /** @format int32 */
  analyticSettingId?: number | null;
}

export interface OrderFormOptionRequestDto {
  /** @format int32 */
  id?: number;
  hideCompanyProvider?: boolean;
  showCompanyProviderPhoneNumber?: boolean;
  showCompanyProviderMail?: boolean;
  showCompanyProviderCode?: boolean;
  showCompanyProviderTvaNumber?: boolean;
  showCompanyProviderSiret?: boolean;
  showCompanyProviderAddressLabel?: boolean;
  hideCompanyCustomer?: boolean;
  isReverseCharge?: boolean;
  showDiscount?: boolean;
  showDiscountColumn?: boolean;
  showDescription?: boolean;
  hideTotal?: boolean;
  hideUnitPriceHtColumn?: boolean;
  hidePriceHtColumn?: boolean;
  hideReferencielTvaColumn?: boolean;
  showCompanyCustomerPhoneNumber?: boolean;
  showCompanyCustomerMail?: boolean;
  showCompanyCustomerCode?: boolean;
  showCompanyCustomerTvaNumber?: boolean;
  showCompanyCustomerSiret?: boolean;
  showCompanyCustomerAddressLabel?: boolean;
  hideStorageLocation?: boolean;
  showReferenceColumn?: boolean;
  showAffairCode?: boolean;
  hideUser?: boolean;
  hideInstitutionLogo?: boolean;
  hideInstitutionName?: boolean;
  hideInstitutionAddress?: boolean;
  hideAffair?: boolean;
  hideIndexColumn?: boolean;
  hideUnitColumn?: boolean;
  comment?: string | null;
  /** @format int32 */
  orderFormId?: number;
}

export interface OrderFormRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  affairCode?: string | null;
  affairAddress?: string | null;
  affairAddressComplement?: string | null;
  affairCity?: string | null;
  affairZipCode?: string | null;
  affairCountry?: string | null;
  description?: string | null;
  path?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  /** @format int32 */
  companyProviderId?: number | null;
  companyProviderName?: string | null;
  companyProviderMail?: string | null;
  companyProviderCode?: string | null;
  companyProviderPhoneNumber?: string | null;
  companyProviderAddressLabel?: string | null;
  companyProviderAddress?: string | null;
  companyProviderAddressComplement?: string | null;
  companyProviderCity?: string | null;
  companyProviderZipCode?: string | null;
  companyProviderCountry?: string | null;
  companyProviderSiret?: string | null;
  companyProviderTva?: string | null;
  /** @format int32 */
  companyCustomerId?: number | null;
  companyCustomerName?: string | null;
  companyCustomerCode?: string | null;
  companyCustomerAddress?: string | null;
  companyCustomerAddressComplement?: string | null;
  companyCustomerCity?: string | null;
  companyCustomerZipCode?: string | null;
  companyCustomerCountry?: string | null;
  companyCustomerPhoneNumber?: string | null;
  companyCustomerMail?: string | null;
  companyCustomerSiret?: string | null;
  companyCustomerTva?: string | null;
  /** @format int32 */
  companyCustomerAddressId?: number | null;
  companyCustomerAddressLabel?: string | null;
  /** @format int32 */
  storageLocationId?: number | null;
  storageLocationLabel?: string | null;
  storageLocationType?: StorageLocationType;
  storageLocationAddress?: string | null;
  storageLocationAddressComplement?: string | null;
  storageLocationZipCode?: string | null;
  storageLocationCity?: string | null;
  storageLocationPhoneNumber?: string | null;
  storageLocationEmail?: string | null;
  status?: OrderFormStatus;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  /** @format date-time */
  documentDate?: string;
  object?: string | null;
  /** @format date-time */
  deliveryDate?: string;
  /** @format date-time */
  receiptDate?: string;
  /** @format double */
  totalHt?: number;
  /** @format double */
  totalTtc?: number;
  /** @format int32 */
  totalQuantityProduct?: number;
  /** @format int32 */
  paymentConditionId?: number;
  discountGlobalType?: AmountType;
  /** @format double */
  discountGlobalNumber?: number;
  footer?: string | null;
  footerType?: FooterType;
  comments?: string | null;
  deliverToAffairAddress?: boolean;
  internalNote?: string | null;
  label?: string | null;
  isTemplate?: boolean;
  /** @format date-time */
  limitDate?: string | null;
  /** @format date-time */
  estimatedDateReceipt?: string | null;
  /** @format date-time */
  receivedOn?: string | null;
  acknowledgmentNumber?: string | null;
  /** @format date-time */
  interventionDate?: string | null;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
}

/** @format int32 */
export enum OrderFormStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
}

export interface OrderFormTagOrderFormRequestDto {
  orderFormIds?: number[] | null;
  orderFormTagIds?: number[] | null;
}

export interface OrderFormTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface OrderFormTvaRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  value?: number;
  /** @format double */
  valueTva?: number;
  /** @format int32 */
  referencialTvaId?: number;
  /** @format int32 */
  orderFormId?: number;
}

export interface PayAllowanceTypeRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  shortLabel?: string | null;
  paymentVariable?: string | null;
  isValued?: boolean;
  formula?: string | null;
  quantificationType?: QuantificationType;
}

export interface PaymentConditionRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  isDisplayDate?: boolean;
  /** @format int32 */
  days?: number | null;
  isEndMonth?: boolean;
  isUpdatable?: boolean;
  isDeletable?: boolean;
  /** @format int32 */
  workspaceId?: number;
}

export interface PaymentMethodRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  workspaceId?: number;
}

/** @format int32 */
export enum PaymentNature {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface PaymentProviderRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  amount?: number;
  /** @format date-time */
  date?: string;
  notes?: string | null;
  reference?: string | null;
  /** @format double */
  remainsToBeDeducted?: number;
  remittedBank?: boolean;
  /** @format date-time */
  remittedBankDate?: string | null;
  paymentType?: PaymentType;
  paymentNature?: PaymentNature;
  /** @format int32 */
  bankAccountId?: number | null;
  /** @format int32 */
  paymentMethodId?: number;
  /** @format int32 */
  companyId?: number;
  companyName?: string | null;
  /** @format int32 */
  institutionId?: number;
}

export interface PaymentRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  amount?: number;
  /** @format date-time */
  date?: string;
  notes?: string | null;
  reference?: string | null;
  /** @format double */
  remainsToBeDeducted?: number;
  remittedBank?: boolean;
  /** @format date-time */
  remittedBankDate?: string | null;
  paymentType?: PaymentType;
  paymentNature?: PaymentNature;
  /** @format int32 */
  bankAccountId?: number | null;
  /** @format int32 */
  paymentMethodId?: number;
  /** @format int32 */
  companyId?: number;
  companyName?: string | null;
}

/** @format int32 */
export enum PaymentRequestDue {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** @format int32 */
export enum PaymentType {
  Value0 = 0,
  Value1 = 1,
}

export interface PayrollEventRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  payCode?: string | null;
  color?: string | null;
  isValued?: boolean;
}

export interface PeriodicityRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  duration?: number;
  unit?: string | null;
  /** @format int32 */
  workspaceId?: number;
}

export interface PlanningViewRequestDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  isDefault?: boolean;
  isShowColGroup?: boolean;
  isPublicHoliday?: boolean;
  isCurrentDay?: boolean;
  isAutoOrderedByName?: boolean;
  isAutoOrderedByGroup?: boolean;
  isActiveSearch?: boolean;
  readOnly?: boolean;
  linePositions?: LinePositionRequestDto[] | null;
}

export interface ProductCategoryRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  parentId?: number | null;
}

export interface ProductProviderRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  reference?: string | null;
  /** @format double */
  discount?: number | null;
  /** @format double */
  netPrice?: number | null;
  isDefault?: boolean;
  /** @format int32 */
  companyId?: number | null;
  /** @format int32 */
  productId?: number | null;
  useDegressivePurchaseRate?: boolean;
  /** @format double */
  grossMarginHT?: number | null;
  /** @format double */
  marginRate?: number | null;
  /** @format double */
  ecoContribution?: number | null;
  /** @format int32 */
  purchaseCategoryId?: number | null;
  productType?: ProductType;
  description?: string | null;
  /** @format int32 */
  unitId?: number;
  /** @format double */
  quantity?: number;
}

export interface ProductRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  reference?: string | null;
  productType?: ProductType;
  description?: string | null;
  /** @format double */
  quantity?: number | null;
  /** @format double */
  packagingQuantity?: number;
  /** @format double */
  priceHT?: number | null;
  /** @format double */
  dryDisbursed?: number | null;
  /** @format double */
  grossMarginHT?: number | null;
  /** @format double */
  marginRate?: number | null;
  brand?: string | null;
  /** @format int32 */
  unitId?: number | null;
  /** @format int32 */
  parentId?: number | null;
  /** @format int32 */
  saleCategoryId?: number | null;
  urlImage?: string | null;
  /** @format double */
  alertQuantityMin?: number | null;
  /** @format double */
  alertQuantityMax?: number | null;
  destockingType?: DestockingType;
  restockingType?: RestockingType;
  /** @format double */
  ecoContribution?: number | null;
  useDegressiveSaleRate?: boolean;
  /** @format int32 */
  referencielTvaId?: number;
  /** @format int32 */
  discount?: number | null;
  unAvailableForSale?: boolean;
  manageStock?: boolean;
}

/** @format int32 */
export enum ProductType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface ProfileFunctionRequestDto {
  /** @format int32 */
  functionId?: number;
  allowCreate?: boolean;
  allowRead?: boolean;
  allowUpdate?: boolean;
  allowDelete?: boolean;
  allowExecute?: boolean;
}

export interface ProfileRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  /** @format int32 */
  institutionId?: number | null;
  /** @format int32 */
  workspaceId?: number | null;
}

export interface ProgrammedSmsRequestDto {
  collaboratorIds: number[];
  /** @minLength 1 */
  text: string;
  /** @format date-time */
  sendAt: string;
  /** @format int32 */
  eventId?: number | null;
}

export interface PropertyRequestDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format double */
  area?: number | null;
  address?: string | null;
  addressComplement?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  /** @format double */
  taxProvisioned?: number | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
}

export interface PropertyTypeRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
}

export interface PunctualDeductionRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  number?: number;
  /** @format double */
  percent?: number;
  label?: string | null;
}

export interface PurchaseCategoryRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
  /** @format int32 */
  parentPurchaseCategoryId?: number | null;
}

/** @format int32 */
export enum QuantificationType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface QuoteBTPRequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  workStartDate?: string | null;
  /** @format int32 */
  estimatedDurationNumber?: number | null;
  estimatedDurationType?: Duration;
  /** @format double */
  wasteVolumeFrom?: number;
  /** @format double */
  wasteVolumeTo?: number;
  wasteVolumeUnity?: MeasureUnit;
  /** @format double */
  wastePriceTtc?: number;
  quoteBTPWasteCollectionPointIds?: number[] | null;
  quoteBTPWasteTypeIds?: number[] | null;
}

export interface QuoteCEERequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  technicalVisitDate?: string;
  /** @format double */
  amountPremium?: number;
  precarityType?: string | null;
  parceNumber?: string | null;
}

export interface QuoteDownPaymentsRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  number?: number;
  downPaymentRequestType?: AmountType;
  downPaymentRequestDue?: PaymentRequestDue;
  label?: string | null;
  /** @format int32 */
  quoteId?: number | null;
  /** @format int32 */
  institutionSettingId?: number | null;
}

export interface QuoteOptionRequestDto {
  /** @format int32 */
  id?: number;
  hideWasteCollection?: boolean;
  hideBankAccount?: boolean;
  calculateMargin?: boolean;
  calculateUnitPriceHtByUnitPurchasePriceHt?: boolean;
  showDiscountColumn?: boolean;
  isReverseCharge?: boolean;
  hideQuantityColumn?: boolean;
  hideReferencielTvaColumn?: boolean;
  hideLinesQuantityEmpty?: boolean;
  hideUnitPriceHtColumn?: boolean;
  hidePriceHtColumn?: boolean;
  hideTotal?: boolean;
  showDiscount?: boolean;
  hidePaymentConditions?: boolean;
  hideCompany?: boolean;
  showCompanySiret?: boolean;
  showCompanyAddressLabel?: boolean;
  showCompanyMail?: boolean;
  showCompanyPhoneNumber?: boolean;
  showDetailWorkLine?: boolean;
  showCompanyCode?: boolean;
  showProductPicturesAttached?: boolean;
  showCompanyTva?: boolean;
  showWaterMark?: boolean;
  showDescription?: boolean;
  showThumbnail?: boolean;
  showReferenceColumn?: boolean;
  showAffairCode?: boolean;
  hideUser?: boolean;
  hideInstitutionLogo?: boolean;
  hideInstitutionName?: boolean;
  hideInstitutionAddress?: boolean;
  hideAffair?: boolean;
  hideIndexColumn?: boolean;
  hideUnitColumn?: boolean;
  documentValidity?: DocumentValidity;
  /** @format int32 */
  documentNumber?: number;
  additionalLegalNotices?: string | null;
  comment?: string | null;
  showTermsOfSales?: boolean;
  /** @format int32 */
  quoteId?: number;
}

export interface QuoteRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  affairAddress?: string | null;
  affairAddressComplement?: string | null;
  affairCity?: string | null;
  affairZipCode?: string | null;
  affairCountry?: string | null;
  type?: DocumentType;
  nature?: Nature;
  /** @format double */
  discountGlobalNumber?: number;
  discountGlobalType?: AmountType;
  /** @format date-time */
  documentDate?: string;
  /** @format date-time */
  limitDate?: string;
  /** @format date-time */
  signedDate?: string | null;
  /** @format int32 */
  validityDocument?: number;
  comments?: string | null;
  description?: string | null;
  path?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  isTemplate?: boolean;
  /** @format int32 */
  bankAccountId?: number | null;
  bankAccountLabel?: string | null;
  bankAccountName?: string | null;
  bankAccountIban?: string | null;
  bankAccountBic?: string | null;
  status?: DocumentStatus;
  /** @format int32 */
  companyId?: number;
  companyName?: string | null;
  companyAddress?: string | null;
  companyAddressComplement?: string | null;
  companyZipCode?: string | null;
  companyCity?: string | null;
  companyCountry?: string | null;
  companyPhoneNumber?: string | null;
  companyMail?: string | null;
  companySiret?: string | null;
  companyTva?: string | null;
  companyAddressLabel?: string | null;
  /** @format int32 */
  companyAddressId?: number | null;
  companyCode?: string | null;
  /** @format double */
  totalHt?: number;
  /** @format double */
  totalTtc?: number;
  footer?: string | null;
  footerType?: FooterType;
  /** @format double */
  marginBrutHt?: number | null;
  /** @format double */
  marginRate?: number | null;
  quoteBTP?: QuoteBTPRequestDto;
  quoteCEE?: QuoteCEERequestDto;
  documentTvas?: DocumentTvaRequestDto[] | null;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
  source?: string | null;
  usedUrlGed?: boolean;
  affairCode?: string | null;
  internalNote?: string | null;
  additionalLegalNotices?: string | null;
  label?: string | null;
  /** @format int32 */
  folderId?: number | null;
  /** @format int32 */
  orderFormCustomerId?: number | null;
}

export interface QuoteTagQuoteRequestDto {
  quoteIds?: number[] | null;
  quoteTagIds?: number[] | null;
}

export interface QuoteTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface ReceiptFormDetailRequestDto {
  /** @format int32 */
  id?: number;
  index?: string | null;
  reference?: string | null;
  description?: string | null;
  /** @format double */
  receivedQuantity?: number;
  /** @format double */
  previousQuantitiesReceived?: number;
  /** @format double */
  orderedQuantity?: number;
  /** @format int32 */
  unitId?: number;
  /** @format int32 */
  productProviderId?: number | null;
  /** @format int32 */
  receiptFormId?: number;
  type?: FormsDetailsType;
  /** @format int32 */
  analyticSettingId?: number | null;
  isDisabled?: boolean;
}

export interface ReceiptFormOptionRequestDto {
  /** @format int32 */
  id?: number;
  hideCompanyProvider?: boolean;
  showCompanyProviderPhoneNumber?: boolean;
  showCompanyProviderMail?: boolean;
  showCompanyProviderCode?: boolean;
  showCompanyProviderTvaNumber?: boolean;
  showCompanyProviderSiret?: boolean;
  showCompanyProviderAddressLabel?: boolean;
  hideCompanyCustomer?: boolean;
  isReverseCharge?: boolean;
  showDiscount?: boolean;
  hideCollaborator?: boolean;
  showDescription?: boolean;
  hideOrderedQuantityColumn?: boolean;
  hidePreviousQuantitiesReceived?: boolean;
  showCompanyCustomerPhoneNumber?: boolean;
  showCompanyCustomerMail?: boolean;
  showCompanyCustomerCode?: boolean;
  showCompanyCustomerTvaNumber?: boolean;
  showCompanyCustomerSiret?: boolean;
  showCompanyCustomerAddressLabel?: boolean;
  hideStorageLocation?: boolean;
  showReferenceColumn?: boolean;
  showAffairCode?: boolean;
  hideUser?: boolean;
  hideInstitutionLogo?: boolean;
  hideInstitutionName?: boolean;
  hideInstitutionAddress?: boolean;
  hideAffair?: boolean;
  hideIndexColumn?: boolean;
  hideUnitColumn?: boolean;
  comment?: string | null;
  hideReceivedQuantityColumn?: boolean;
  /** @format int32 */
  receiptFormId?: number;
}

export interface ReceiptFormRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  affairId?: number | null;
  affairName?: string | null;
  affairCode?: string | null;
  affairAddress?: string | null;
  affairAddressComplement?: string | null;
  affairCity?: string | null;
  affairZipCode?: string | null;
  affairCountry?: string | null;
  comments?: string | null;
  /** @format int32 */
  companyCustomerId?: number | null;
  companyCustomerName?: string | null;
  companyCustomerCode?: string | null;
  companyCustomerAddress?: string | null;
  companyCustomerAddressComplement?: string | null;
  companyCustomerCity?: string | null;
  companyCustomerZipCode?: string | null;
  companyCustomerCountry?: string | null;
  companyCustomerPhoneNumber?: string | null;
  companyCustomerMail?: string | null;
  companyCustomerSiret?: string | null;
  companyCustomerTva?: string | null;
  /** @format int32 */
  companyCustomerAddressId?: number | null;
  companyCustomerAddressLabel?: string | null;
  /** @format int32 */
  companyProviderId?: number | null;
  companyProviderName?: string | null;
  companyProviderMail?: string | null;
  companyProviderCode?: string | null;
  companyProviderPhoneNumber?: string | null;
  companyProviderAddressLabel?: string | null;
  companyProviderAddress?: string | null;
  companyProviderAddressComplement?: string | null;
  companyProviderCity?: string | null;
  companyProviderZipCode?: string | null;
  companyProviderCountry?: string | null;
  companyProviderSiret?: string | null;
  companyProviderTva?: string | null;
  /** @format int32 */
  storageLocationId?: number | null;
  storageLocationLabel?: string | null;
  storageLocationType?: StorageLocationType;
  storageLocationAddress?: string | null;
  storageLocationAddressComplement?: string | null;
  storageLocationZipCode?: string | null;
  storageLocationCity?: string | null;
  storageLocationPhoneNumber?: string | null;
  storageLocationEmail?: string | null;
  /** @format int32 */
  collaboratorId?: number | null;
  collaboratorFirstName?: string | null;
  collaboratorLastName?: string | null;
  collaboratorMail?: string | null;
  collaboratorPhoneNumber?: string | null;
  collaboratorSecondaryPhoneNumber?: string | null;
  status?: ReceiptFormStatus;
  /** @format date-time */
  documentDate?: string;
  object?: string | null;
  /** @format date-time */
  receiptDate?: string;
  deliverToAffairAddress?: boolean;
  isFaulty?: boolean;
  /** @format int32 */
  totalQuantityProduct?: number;
  /** @format int32 */
  userId?: number;
  userLastName?: string | null;
  userFirstName?: string | null;
  userPhoneNumber?: string | null;
  userSecondaryPhoneNumber?: string | null;
  userMail?: string | null;
  /** @format int32 */
  folderId?: number | null;
  /** @format int32 */
  orderFormId?: number | null;
  internalNote?: string | null;
  path?: string | null;
  /** @format date-time */
  dateGenerated?: string | null;
  orderFormDocumentReference?: string | null;
  /** @format date-time */
  orderFormDocumentDate?: string | null;
  /** @format int32 */
  receiptFormStep?: number;
  /** @format int32 */
  institutionId?: number;
  institutionName?: string | null;
  institutionLegalForm?: LegalForm;
  institutionAddress?: string | null;
  institutionAddressComplement?: string | null;
  institutionZipCode?: string | null;
  institutionCity?: string | null;
  institutionCountry?: string | null;
  institutionPhoneNumber?: string | null;
  institutionSecondaryPhoneNumber?: string | null;
  institutionEmail?: string | null;
  institutionWebSite?: string | null;
  institutionRegistrationNumber?: string | null;
  institutionIsSubjectTVA?: boolean;
  institutionTvaNumber?: string | null;
  institutionSiret?: string | null;
  institutionRcsCity?: string | null;
  /** @format double */
  institutionCapital?: number | null;
  institutionApeCode?: string | null;
  institutionGuaranteeType?: GuaranteeType;
  institutionInsuranceName?: string | null;
  institutionInsuranceCoverage?: string | null;
  institutionInsuranceAddress?: string | null;
  institutionInsuranceAddressComplement?: string | null;
  institutionInsuranceZipCode?: string | null;
  institutionInsuranceCity?: string | null;
  institutionInsuranceCountry?: string | null;
  institutionLogo?: string | null;
  institutionColor?: string | null;
  institutionSecondaryColor?: string | null;
}

/** @format int32 */
export enum ReceiptFormStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface ReceiptFormTagReceiptFormRequestDto {
  receiptFormIds?: number[] | null;
  receiptFormTagIds?: number[] | null;
}

export interface ReceiptFormTagRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
}

export interface RegisterRequestDto {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /** @minLength 1 */
  password: string;
  /** @minLength 1 */
  confirmPassword: string;
  firstName?: string | null;
  lastName?: string | null;
  civility?: Civility;
  /** @format tel */
  phoneNumber?: string | null;
  workspaceIds?: Record<string, boolean | null>;
}

export interface ReportDetailPayAllowanceTypeRequestDto {
  /** @format double */
  quantity?: number | null;
  /** @format double */
  amount?: number | null;
  checkbox?: boolean | null;
  /** @format int32 */
  payAllowanceTypeId?: number;
}

export interface ReportDetailRequestDto {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string | null;
  /** @format double */
  duration?: number | null;
  /** @format double */
  durationValue?: number | null;
  isFacturable?: boolean;
  message?: string | null;
  status?: ReportingStatus;
  /** @format int32 */
  collaboratorId?: number | null;
  /** @format int32 */
  payrollEventId?: number | null;
  /** @format int32 */
  affairId?: number | null;
  /** @format int32 */
  missionId?: number | null;
  /** @format int32 */
  eventId?: number | null;
  /** @format int32 */
  interventionId?: number | null;
  source?: string | null;
}

/** @format int32 */
export enum ReportEntryMode {
  Value0 = 0,
  Value1 = 1,
}

export interface ReportingSettingRequestDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  payrollEventIdInterventionDefault?: number | null;
  /** @format int32 */
  missionIdInterventionDefault?: number | null;
}

/** @format int32 */
export enum ReportingStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

/** @format int32 */
export enum RestockingType {
  Value0 = 0,
}

export interface ReviewRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  number?: number;
  /** @format double */
  percent?: number;
  /** @format int32 */
  referencielTvaId?: number;
  label?: string | null;
  includePrestation?: boolean;
}

export interface SaleCategoryRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  color?: string | null;
  /** @format int32 */
  parentSaleCategoryId?: number | null;
}

export interface SendInvoiceRequestDto {
  /** @format int32 */
  invoiceId?: number;
  mailTos?: string[] | null;
  ccs?: string[] | null;
  bccs?: string[] | null;
}

export interface SmtpSettingRequestDto {
  /** @format int32 */
  id?: number;
  host?: string | null;
  /** @format int32 */
  port?: number;
  sender?: string | null;
  bccs?: string[] | null;
  username?: string | null;
  password?: string | null;
}

/** @format int32 */
export enum StatisticsPeriod {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface StockRequestDto {
  /** @format int32 */
  id?: number;
  /** @format double */
  quantity?: number;
  /** @format int32 */
  productId?: number;
  /** @format int32 */
  storageLocationId?: number;
}

export interface StorageLocationRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  storageLocationType?: StorageLocationType;
  address?: string | null;
  addressComplement?: string | null;
  zipCode?: string | null;
  city?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  phoneNumber?: string | null;
  email?: string | null;
}

/** @format int32 */
export enum StorageLocationType {
  Value0 = 0,
  Value1 = 1,
}

export interface TermsAndConditionsRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  description?: string | null;
  /** @format int32 */
  institutionSettingId?: number;
}

/** @format int32 */
export enum TypeField {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
}

/** @format int32 */
export enum TypeLine {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface UserParameterRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  key?: string | null;
  value?: string | null;
  /** @format int32 */
  userId?: number;
}

export interface UserRequestDto {
  /** @format int32 */
  id?: number;
  phoneNumber?: string | null;
  civility?: Civility;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  isActive?: boolean;
  workspaceIds?: Record<string, boolean | null>;
}

export interface WasteCollectionPointRequestDto {
  /** @format int32 */
  id?: number;
  label?: string | null;
  address?: string | null;
  addressComplement?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  /** @format int32 */
  wasteCollectionPointTypeId?: number;
}

export interface WorkspaceRequestDto {
  /** @format int32 */
  id?: number;
  socialReason?: string | null;
  mergeInstitutionsData?: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Orisis API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accounting = {
    /**
     * No description
     *
     * @tags AccountingAccount
     * @name AccountingAccountGetAccountingAccountByIdList
     * @request GET:/Accounting/AccountingAccount/GetAccountingAccountById
     * @secure
     */
    accountingAccountGetAccountingAccountByIdList: (
      query?: {
        /** @format int32 */
        accountingAccountId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Accounting/AccountingAccount/GetAccountingAccountById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingAccount
     * @name AccountingAccountGetAccountingAccountsList
     * @request GET:/Accounting/AccountingAccount/GetAccountingAccounts
     * @secure
     */
    accountingAccountGetAccountingAccountsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingAccount/GetAccountingAccounts`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingAccount
     * @name AccountingAccountCreateAccountingAccountCreate
     * @request POST:/Accounting/AccountingAccount/CreateAccountingAccount
     * @secure
     */
    accountingAccountCreateAccountingAccountCreate: (data: AccountingAccountRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingAccount/CreateAccountingAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingAccount
     * @name AccountingAccountUpdateAccountingAccountUpdate
     * @request PUT:/Accounting/AccountingAccount/UpdateAccountingAccount
     * @secure
     */
    accountingAccountUpdateAccountingAccountUpdate: (data: AccountingAccountRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingAccount/UpdateAccountingAccount`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingAccount
     * @name AccountingAccountDeleteDelete
     * @request DELETE:/Accounting/AccountingAccount/Delete
     * @secure
     */
    accountingAccountDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingAccount/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingSetting
     * @name AccountingSettingGetAccountingSettingByIdList
     * @request GET:/Accounting/AccountingSetting/GetAccountingSettingById
     * @secure
     */
    accountingSettingGetAccountingSettingByIdList: (
      query?: {
        /** @format int32 */
        accountingSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Accounting/AccountingSetting/GetAccountingSettingById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingSetting
     * @name AccountingSettingGetAccountingSettingsList
     * @request GET:/Accounting/AccountingSetting/GetAccountingSettings
     * @secure
     */
    accountingSettingGetAccountingSettingsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingSetting/GetAccountingSettings`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingSetting
     * @name AccountingSettingCreateAccountingSettingCreate
     * @request POST:/Accounting/AccountingSetting/CreateAccountingSetting
     * @secure
     */
    accountingSettingCreateAccountingSettingCreate: (data: AccountingSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingSetting/CreateAccountingSetting`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingSetting
     * @name AccountingSettingUpdateAccountingSettingUpdate
     * @request PUT:/Accounting/AccountingSetting/UpdateAccountingSetting
     * @secure
     */
    accountingSettingUpdateAccountingSettingUpdate: (data: AccountingSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingSetting/UpdateAccountingSetting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AccountingSetting
     * @name AccountingSettingDeleteDelete
     * @request DELETE:/Accounting/AccountingSetting/Delete
     * @secure
     */
    accountingSettingDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/AccountingSetting/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Journal
     * @name JournalGetJournalByIdList
     * @request GET:/Accounting/Journal/GetJournalById
     * @secure
     */
    journalGetJournalByIdList: (
      query?: {
        /** @format int32 */
        journalId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Accounting/Journal/GetJournalById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Journal
     * @name JournalGetJournalsList
     * @request GET:/Accounting/Journal/GetJournals
     * @secure
     */
    journalGetJournalsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/Journal/GetJournals`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Journal
     * @name JournalCreateJournalCreate
     * @request POST:/Accounting/Journal/CreateJournal
     * @secure
     */
    journalCreateJournalCreate: (data: JournalRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/Journal/CreateJournal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Journal
     * @name JournalUpdateJournalUpdate
     * @request PUT:/Accounting/Journal/UpdateJournal
     * @secure
     */
    journalUpdateJournalUpdate: (data: JournalRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/Journal/UpdateJournal`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Journal
     * @name JournalDeleteDelete
     * @request DELETE:/Accounting/Journal/Delete
     * @secure
     */
    journalDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Accounting/Journal/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  directory = {
    /**
     * No description
     *
     * @tags Activity
     * @name ActivityGetActivitiesList
     * @request GET:/Directory/Activity/GetActivities
     * @secure
     */
    activityGetActivitiesList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        collaboratorId?: number;
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Activity/GetActivities`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityGetActivityByIdList
     * @request GET:/Directory/Activity/GetActivityById
     * @secure
     */
    activityGetActivityByIdList: (
      query?: {
        /** @format int32 */
        activityId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Activity/GetActivityById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityGetActivitiesArchivedList
     * @request GET:/Directory/Activity/GetActivitiesArchived
     * @secure
     */
    activityGetActivitiesArchivedList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        collaboratorId?: number;
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Activity/GetActivitiesArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityGetActivityArchivedByIdList
     * @request GET:/Directory/Activity/GetActivityArchivedById
     * @secure
     */
    activityGetActivityArchivedByIdList: (
      query?: {
        /** @format int32 */
        activityId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Activity/GetActivityArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityGetLastActivitiesOfCompaniesList
     * @request GET:/Directory/Activity/GetLastActivitiesOfCompanies
     * @secure
     */
    activityGetLastActivitiesOfCompaniesList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Activity/GetLastActivitiesOfCompanies`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityGetRemindersList
     * @request GET:/Directory/Activity/GetReminders
     * @secure
     */
    activityGetRemindersList: (
      query?: {
        /** @format int32 */
        collaboratorId?: number;
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Activity/GetReminders`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityCreateActivityCreate
     * @request POST:/Directory/Activity/CreateActivity
     * @secure
     */
    activityCreateActivityCreate: (data: ActivityRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Activity/CreateActivity`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityUpdateActivityUpdate
     * @request PUT:/Directory/Activity/UpdateActivity
     * @secure
     */
    activityUpdateActivityUpdate: (data: ActivityRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Activity/UpdateActivity`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityRestoreUpdate
     * @request PUT:/Directory/Activity/Restore
     * @secure
     */
    activityRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Activity/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityArchiveDelete
     * @request DELETE:/Directory/Activity/Archive
     * @secure
     */
    activityArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Activity/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityDeleteDelete
     * @request DELETE:/Directory/Activity/Delete
     * @secure
     */
    activityDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Activity/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeGetActivityChanelTypesList
     * @request GET:/Directory/ActivityChanelType/GetActivityChanelTypes
     * @secure
     */
    activityChanelTypeGetActivityChanelTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/GetActivityChanelTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeGetActivityChanelTypeByIdList
     * @request GET:/Directory/ActivityChanelType/GetActivityChanelTypeById
     * @secure
     */
    activityChanelTypeGetActivityChanelTypeByIdList: (
      query?: {
        /** @format int32 */
        activityChanelTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/GetActivityChanelTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeGetActivityChanelTypesArchivedList
     * @request GET:/Directory/ActivityChanelType/GetActivityChanelTypesArchived
     * @secure
     */
    activityChanelTypeGetActivityChanelTypesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/GetActivityChanelTypesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeGetActivityChanelTypeArchivedByIdList
     * @request GET:/Directory/ActivityChanelType/GetActivityChanelTypeArchivedById
     * @secure
     */
    activityChanelTypeGetActivityChanelTypeArchivedByIdList: (
      query?: {
        /** @format int32 */
        activityChanelTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/GetActivityChanelTypeArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeCreateActivityChanelTypeCreate
     * @request POST:/Directory/ActivityChanelType/CreateActivityChanelType
     * @secure
     */
    activityChanelTypeCreateActivityChanelTypeCreate: (
      data: ActivityChanelTypeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/CreateActivityChanelType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeUpdateActivityChanelTypeUpdate
     * @request PUT:/Directory/ActivityChanelType/UpdateActivityChanelType
     * @secure
     */
    activityChanelTypeUpdateActivityChanelTypeUpdate: (
      data: ActivityChanelTypeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/UpdateActivityChanelType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeRestoreUpdate
     * @request PUT:/Directory/ActivityChanelType/Restore
     * @secure
     */
    activityChanelTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeArchiveDelete
     * @request DELETE:/Directory/ActivityChanelType/Archive
     * @secure
     */
    activityChanelTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityChanelType
     * @name ActivityChanelTypeDeleteDelete
     * @request DELETE:/Directory/ActivityChanelType/Delete
     * @secure
     */
    activityChanelTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityChanelType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeGetActivityResultTypesList
     * @request GET:/Directory/ActivityResultType/GetActivityResultTypes
     * @secure
     */
    activityResultTypeGetActivityResultTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/GetActivityResultTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeGetActivityResultTypeByIdList
     * @request GET:/Directory/ActivityResultType/GetActivityResultTypeById
     * @secure
     */
    activityResultTypeGetActivityResultTypeByIdList: (
      query?: {
        /** @format int32 */
        activityResultTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/GetActivityResultTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeGetActivityResultTypesArchivedList
     * @request GET:/Directory/ActivityResultType/GetActivityResultTypesArchived
     * @secure
     */
    activityResultTypeGetActivityResultTypesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/GetActivityResultTypesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeGetActivityResultTypeArchivedByIdList
     * @request GET:/Directory/ActivityResultType/GetActivityResultTypeArchivedById
     * @secure
     */
    activityResultTypeGetActivityResultTypeArchivedByIdList: (
      query?: {
        /** @format int32 */
        activityResultTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/GetActivityResultTypeArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeCreateActivityResultTypeCreate
     * @request POST:/Directory/ActivityResultType/CreateActivityResultType
     * @secure
     */
    activityResultTypeCreateActivityResultTypeCreate: (
      data: ActivityResultTypeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/CreateActivityResultType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeUpdateActivityResultTypeUpdate
     * @request PUT:/Directory/ActivityResultType/UpdateActivityResultType
     * @secure
     */
    activityResultTypeUpdateActivityResultTypeUpdate: (
      data: ActivityResultTypeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/UpdateActivityResultType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeRestoreUpdate
     * @request PUT:/Directory/ActivityResultType/Restore
     * @secure
     */
    activityResultTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeArchiveDelete
     * @request DELETE:/Directory/ActivityResultType/Archive
     * @secure
     */
    activityResultTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivityResultType
     * @name ActivityResultTypeDeleteDelete
     * @request DELETE:/Directory/ActivityResultType/Delete
     * @secure
     */
    activityResultTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivityResultType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeGetActivitySourceTypesList
     * @request GET:/Directory/ActivitySourceType/GetActivitySourceTypes
     * @secure
     */
    activitySourceTypeGetActivitySourceTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/GetActivitySourceTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeGetActivitySourceTypeByIdList
     * @request GET:/Directory/ActivitySourceType/GetActivitySourceTypeById
     * @secure
     */
    activitySourceTypeGetActivitySourceTypeByIdList: (
      query?: {
        /** @format int32 */
        activitySourceTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/GetActivitySourceTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeGetActivitySourceTypesArchivedList
     * @request GET:/Directory/ActivitySourceType/GetActivitySourceTypesArchived
     * @secure
     */
    activitySourceTypeGetActivitySourceTypesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/GetActivitySourceTypesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeGetActivitySourceTypeArchivedByIdList
     * @request GET:/Directory/ActivitySourceType/GetActivitySourceTypeArchivedById
     * @secure
     */
    activitySourceTypeGetActivitySourceTypeArchivedByIdList: (
      query?: {
        /** @format int32 */
        activitySourceTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/GetActivitySourceTypeArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeCreateActivitySourceTypeCreate
     * @request POST:/Directory/ActivitySourceType/CreateActivitySourceType
     * @secure
     */
    activitySourceTypeCreateActivitySourceTypeCreate: (
      data: ActivitySourceTypeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/CreateActivitySourceType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeUpdateActivitySourceTypeUpdate
     * @request PUT:/Directory/ActivitySourceType/UpdateActivitySourceType
     * @secure
     */
    activitySourceTypeUpdateActivitySourceTypeUpdate: (
      data: ActivitySourceTypeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/UpdateActivitySourceType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeRestoreUpdate
     * @request PUT:/Directory/ActivitySourceType/Restore
     * @secure
     */
    activitySourceTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeArchiveDelete
     * @request DELETE:/Directory/ActivitySourceType/Archive
     * @secure
     */
    activitySourceTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ActivitySourceType
     * @name ActivitySourceTypeDeleteDelete
     * @request DELETE:/Directory/ActivitySourceType/Delete
     * @secure
     */
    activitySourceTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ActivitySourceType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairGetAffairsList
     * @request GET:/Directory/Affair/GetAffairs
     * @secure
     */
    affairGetAffairsList: (
      data: number[],
      query?: {
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/GetAffairs`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairGetAffairByIdList
     * @request GET:/Directory/Affair/GetAffairById
     * @secure
     */
    affairGetAffairByIdList: (
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/GetAffairById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairGetAffairCustomFieldsByAffairIdList
     * @request GET:/Directory/Affair/GetAffairCustomFieldsByAffairId
     * @secure
     */
    affairGetAffairCustomFieldsByAffairIdList: (
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/GetAffairCustomFieldsByAffairId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairGetAffairArchivedByIdList
     * @request GET:/Directory/Affair/GetAffairArchivedById
     * @secure
     */
    affairGetAffairArchivedByIdList: (
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/GetAffairArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairGetAffairsArchivedList
     * @request GET:/Directory/Affair/GetAffairsArchived
     * @secure
     */
    affairGetAffairsArchivedList: (
      data: number[],
      query?: {
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/GetAffairsArchived`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairCreateAffairCreate
     * @request POST:/Directory/Affair/CreateAffair
     * @secure
     */
    affairCreateAffairCreate: (data: AffairRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Affair/CreateAffair`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairDuplicateAffairCreate
     * @request POST:/Directory/Affair/DuplicateAffair
     * @secure
     */
    affairDuplicateAffairCreate: (
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/DuplicateAffair`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairUpdateAffairUpdate
     * @request PUT:/Directory/Affair/UpdateAffair
     * @secure
     */
    affairUpdateAffairUpdate: (data: AffairRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Affair/UpdateAffair`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairUpdateAffairTagAffairUpdate
     * @request PUT:/Directory/Affair/UpdateAffairTagAffair
     * @secure
     */
    affairUpdateAffairTagAffairUpdate: (data: AffairTagAffairRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Affair/UpdateAffairTagAffair`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairUpdateAffairCustomFieldUpdate
     * @request PUT:/Directory/Affair/UpdateAffairCustomField
     * @secure
     */
    affairUpdateAffairCustomFieldUpdate: (
      data: Record<string, string>,
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/UpdateAffairCustomField`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairUpdateAffairContactsUpdate
     * @request PUT:/Directory/Affair/UpdateAffairContacts
     * @secure
     */
    affairUpdateAffairContactsUpdate: (
      data: AffairContactRequestDto[],
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/UpdateAffairContacts`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairUpdateAffairCustomPerimeterUpdate
     * @request PUT:/Directory/Affair/UpdateAffairCustomPerimeter
     * @secure
     */
    affairUpdateAffairCustomPerimeterUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/UpdateAffairCustomPerimeter`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairUploadFileUpdate
     * @request PUT:/Directory/Affair/UploadFile
     * @secure
     */
    affairUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Affair/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairRestoreUpdate
     * @request PUT:/Directory/Affair/Restore
     * @secure
     */
    affairRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Affair/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairArchiveDelete
     * @request DELETE:/Directory/Affair/Archive
     * @secure
     */
    affairArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Affair/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Affair
     * @name AffairDeleteDelete
     * @request DELETE:/Directory/Affair/Delete
     * @secure
     */
    affairDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Affair/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryGetAffairCategoriesTreeList
     * @request GET:/Directory/AffairCategory/GetAffairCategoriesTree
     * @secure
     */
    affairCategoryGetAffairCategoriesTreeList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/GetAffairCategoriesTree`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryGetAffairCategoriesList
     * @request GET:/Directory/AffairCategory/GetAffairCategories
     * @secure
     */
    affairCategoryGetAffairCategoriesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/GetAffairCategories`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryGetAffairCategoriesArchivedList
     * @request GET:/Directory/AffairCategory/GetAffairCategoriesArchived
     * @secure
     */
    affairCategoryGetAffairCategoriesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/GetAffairCategoriesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryGetAffairCategoryByIdList
     * @request GET:/Directory/AffairCategory/GetAffairCategoryById
     * @secure
     */
    affairCategoryGetAffairCategoryByIdList: (
      query?: {
        /** @format int32 */
        affairCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/GetAffairCategoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryGetAffairCategoryArchivedByIdList
     * @request GET:/Directory/AffairCategory/GetAffairCategoryArchivedById
     * @secure
     */
    affairCategoryGetAffairCategoryArchivedByIdList: (
      query?: {
        /** @format int32 */
        affairCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/GetAffairCategoryArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryCreateAffairCategoryCreate
     * @request POST:/Directory/AffairCategory/CreateAffairCategory
     * @secure
     */
    affairCategoryCreateAffairCategoryCreate: (data: AffairCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/CreateAffairCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryUpdateAffairCategoryUpdate
     * @request PUT:/Directory/AffairCategory/UpdateAffairCategory
     * @secure
     */
    affairCategoryUpdateAffairCategoryUpdate: (data: AffairCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/UpdateAffairCategory`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryRestoreUpdate
     * @request PUT:/Directory/AffairCategory/Restore
     * @secure
     */
    affairCategoryRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryArchiveDelete
     * @request DELETE:/Directory/AffairCategory/Archive
     * @secure
     */
    affairCategoryArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCategory
     * @name AffairCategoryDeleteDelete
     * @request DELETE:/Directory/AffairCategory/Delete
     * @secure
     */
    affairCategoryDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCategory/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingGetAffairCostingByIdList
     * @request GET:/Directory/AffairCosting/GetAffairCostingById
     * @secure
     */
    affairCostingGetAffairCostingByIdList: (
      query?: {
        /** @format int32 */
        affairCostingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/GetAffairCostingById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingGetAffairCostingsList
     * @request GET:/Directory/AffairCosting/GetAffairCostings
     * @secure
     */
    affairCostingGetAffairCostingsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        affairId?: number;
        sourceType?: AffairCostingSourceType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/GetAffairCostings`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingGetAffairCostingArchivedByIdList
     * @request GET:/Directory/AffairCosting/GetAffairCostingArchivedById
     * @secure
     */
    affairCostingGetAffairCostingArchivedByIdList: (
      query?: {
        /** @format int32 */
        affairCostingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/GetAffairCostingArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingGetAffairCostingsArchivedList
     * @request GET:/Directory/AffairCosting/GetAffairCostingsArchived
     * @secure
     */
    affairCostingGetAffairCostingsArchivedList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        affairId?: number;
        sourceType?: AffairCostingSourceType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/GetAffairCostingsArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingCreateAffairCostingCreate
     * @request POST:/Directory/AffairCosting/CreateAffairCosting
     * @secure
     */
    affairCostingCreateAffairCostingCreate: (data: AffairCostingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/CreateAffairCosting`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingUpdateAffairCostingUpdate
     * @request PUT:/Directory/AffairCosting/UpdateAffairCosting
     * @secure
     */
    affairCostingUpdateAffairCostingUpdate: (data: AffairCostingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/UpdateAffairCosting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingRestoreUpdate
     * @request PUT:/Directory/AffairCosting/Restore
     * @secure
     */
    affairCostingRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingArchiveDelete
     * @request DELETE:/Directory/AffairCosting/Archive
     * @secure
     */
    affairCostingArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairCosting
     * @name AffairCostingDeleteDelete
     * @request DELETE:/Directory/AffairCosting/Delete
     * @secure
     */
    affairCostingDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairCosting/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagGetAffairTagsList
     * @request GET:/Directory/AffairTag/GetAffairTags
     * @secure
     */
    affairTagGetAffairTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/GetAffairTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagGetAffairTagByIdList
     * @request GET:/Directory/AffairTag/GetAffairTagById
     * @secure
     */
    affairTagGetAffairTagByIdList: (
      query?: {
        /** @format int32 */
        affairTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/GetAffairTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagGetAffairTagsArchivedList
     * @request GET:/Directory/AffairTag/GetAffairTagsArchived
     * @secure
     */
    affairTagGetAffairTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/GetAffairTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagGetAffairTagArchivedByIdList
     * @request GET:/Directory/AffairTag/GetAffairTagArchivedById
     * @secure
     */
    affairTagGetAffairTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        affairTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/GetAffairTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagCreateAffairTagCreate
     * @request POST:/Directory/AffairTag/CreateAffairTag
     * @secure
     */
    affairTagCreateAffairTagCreate: (data: AffairTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/CreateAffairTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagUpdateAffairTagUpdate
     * @request PUT:/Directory/AffairTag/UpdateAffairTag
     * @secure
     */
    affairTagUpdateAffairTagUpdate: (data: AffairTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/UpdateAffairTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagRestoreUpdate
     * @request PUT:/Directory/AffairTag/Restore
     * @secure
     */
    affairTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagArchiveDelete
     * @request DELETE:/Directory/AffairTag/Archive
     * @secure
     */
    affairTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairTag
     * @name AffairTagDeleteDelete
     * @request DELETE:/Directory/AffairTag/Delete
     * @secure
     */
    affairTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairType
     * @name AffairTypeGetAffairTypesList
     * @request GET:/Directory/AffairType/GetAffairTypes
     * @secure
     */
    affairTypeGetAffairTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairType/GetAffairTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairType
     * @name AffairTypeGetAffairTypeByIdList
     * @request GET:/Directory/AffairType/GetAffairTypeById
     * @secure
     */
    affairTypeGetAffairTypeByIdList: (
      query?: {
        /** @format int32 */
        affairTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/AffairType/GetAffairTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairType
     * @name AffairTypeCreateAffairTypeCreate
     * @request POST:/Directory/AffairType/CreateAffairType
     * @secure
     */
    affairTypeCreateAffairTypeCreate: (data: AffairTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairType/CreateAffairType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairType
     * @name AffairTypeUpdateAffairTypeUpdate
     * @request PUT:/Directory/AffairType/UpdateAffairType
     * @secure
     */
    affairTypeUpdateAffairTypeUpdate: (data: AffairTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairType/UpdateAffairType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairType
     * @name AffairTypeRestoreUpdate
     * @request PUT:/Directory/AffairType/Restore
     * @secure
     */
    affairTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairType
     * @name AffairTypeArchiveDelete
     * @request DELETE:/Directory/AffairType/Archive
     * @secure
     */
    affairTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AffairType
     * @name AffairTypeDeleteDelete
     * @request DELETE:/Directory/AffairType/Delete
     * @secure
     */
    affairTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/AffairType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorGetCollaboratorByIdList
     * @request GET:/Directory/Collaborator/GetCollaboratorById
     * @secure
     */
    collaboratorGetCollaboratorByIdList: (
      query?: {
        /** @format int32 */
        collaboratorId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/GetCollaboratorById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorGetCollaboratorArchivedByIdList
     * @request GET:/Directory/Collaborator/GetCollaboratorArchivedById
     * @secure
     */
    collaboratorGetCollaboratorArchivedByIdList: (
      query?: {
        /** @format int32 */
        collaboratorId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/GetCollaboratorArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorGetCollaboratorsList
     * @request GET:/Directory/Collaborator/GetCollaborators
     * @secure
     */
    collaboratorGetCollaboratorsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/GetCollaborators`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorGetCollaboratorsArchivedList
     * @request GET:/Directory/Collaborator/GetCollaboratorsArchived
     * @secure
     */
    collaboratorGetCollaboratorsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/GetCollaboratorsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorGetCollaboratorByUserIdList
     * @request GET:/Directory/Collaborator/GetCollaboratorByUserId
     * @secure
     */
    collaboratorGetCollaboratorByUserIdList: (
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/GetCollaboratorByUserId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorCreateCollaboratorCreate
     * @request POST:/Directory/Collaborator/CreateCollaborator
     * @secure
     */
    collaboratorCreateCollaboratorCreate: (data: CollaboratorRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/CreateCollaborator`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorUpdateCollaboratorUpdate
     * @request PUT:/Directory/Collaborator/UpdateCollaborator
     * @secure
     */
    collaboratorUpdateCollaboratorUpdate: (data: CollaboratorRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/UpdateCollaborator`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorUpdateCollaboratorTagCollaboratorUpdate
     * @request PUT:/Directory/Collaborator/UpdateCollaboratorTagCollaborator
     * @secure
     */
    collaboratorUpdateCollaboratorTagCollaboratorUpdate: (
      data: CollaboratorTagCollaboratorRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/UpdateCollaboratorTagCollaborator`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorUpdateCollaboratorCustomPerimeterUpdate
     * @request PUT:/Directory/Collaborator/UpdateCollaboratorCustomPerimeter
     * @secure
     */
    collaboratorUpdateCollaboratorCustomPerimeterUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        collaboratorId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/UpdateCollaboratorCustomPerimeter`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorRestoreUpdate
     * @request PUT:/Directory/Collaborator/Restore
     * @secure
     */
    collaboratorRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorArchiveDelete
     * @request DELETE:/Directory/Collaborator/Archive
     * @secure
     */
    collaboratorArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Collaborator
     * @name CollaboratorDeleteDelete
     * @request DELETE:/Directory/Collaborator/Delete
     * @secure
     */
    collaboratorDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Collaborator/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalGetCollaboratorGoalByIdList
     * @request GET:/Directory/CollaboratorGoal/GetCollaboratorGoalById
     * @secure
     */
    collaboratorGoalGetCollaboratorGoalByIdList: (
      query?: {
        /** @format int32 */
        collaboratorGoalId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/GetCollaboratorGoalById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalGetCollaboratorGoalArchivedByIdList
     * @request GET:/Directory/CollaboratorGoal/GetCollaboratorGoalArchivedById
     * @secure
     */
    collaboratorGoalGetCollaboratorGoalArchivedByIdList: (
      query?: {
        /** @format int32 */
        collaboratorGoalId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/GetCollaboratorGoalArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalGetCollaboratorGoalsList
     * @request GET:/Directory/CollaboratorGoal/GetCollaboratorGoals
     * @secure
     */
    collaboratorGoalGetCollaboratorGoalsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/GetCollaboratorGoals`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalGetCollaboratorGoalsArchivedList
     * @request GET:/Directory/CollaboratorGoal/GetCollaboratorGoalsArchived
     * @secure
     */
    collaboratorGoalGetCollaboratorGoalsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/GetCollaboratorGoalsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalCreateCollaboratorGoalCreate
     * @request POST:/Directory/CollaboratorGoal/CreateCollaboratorGoal
     * @secure
     */
    collaboratorGoalCreateCollaboratorGoalCreate: (data: CollaboratorGoalRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/CreateCollaboratorGoal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalUpdateCollaboratorGoalUpdate
     * @request PUT:/Directory/CollaboratorGoal/UpdateCollaboratorGoal
     * @secure
     */
    collaboratorGoalUpdateCollaboratorGoalUpdate: (data: CollaboratorGoalRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/UpdateCollaboratorGoal`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalRestoreUpdate
     * @request PUT:/Directory/CollaboratorGoal/Restore
     * @secure
     */
    collaboratorGoalRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalArchiveDelete
     * @request DELETE:/Directory/CollaboratorGoal/Archive
     * @secure
     */
    collaboratorGoalArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorGoal
     * @name CollaboratorGoalDeleteDelete
     * @request DELETE:/Directory/CollaboratorGoal/Delete
     * @secure
     */
    collaboratorGoalDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorGoal/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagGetCollaboratorTagsList
     * @request GET:/Directory/CollaboratorTag/GetCollaboratorTags
     * @secure
     */
    collaboratorTagGetCollaboratorTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/GetCollaboratorTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagGetCollaboratorTagByIdList
     * @request GET:/Directory/CollaboratorTag/GetCollaboratorTagById
     * @secure
     */
    collaboratorTagGetCollaboratorTagByIdList: (
      query?: {
        /** @format int32 */
        collaboratorTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/GetCollaboratorTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagGetCollaboratorTagsArchivedList
     * @request GET:/Directory/CollaboratorTag/GetCollaboratorTagsArchived
     * @secure
     */
    collaboratorTagGetCollaboratorTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/GetCollaboratorTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagGetCollaboratorTagArchivedByIdList
     * @request GET:/Directory/CollaboratorTag/GetCollaboratorTagArchivedById
     * @secure
     */
    collaboratorTagGetCollaboratorTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        collaboratorTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/GetCollaboratorTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagCreateCollaboratorTagCreate
     * @request POST:/Directory/CollaboratorTag/CreateCollaboratorTag
     * @secure
     */
    collaboratorTagCreateCollaboratorTagCreate: (data: CollaboratorTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/CreateCollaboratorTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagUpdateCollaboratorTagUpdate
     * @request PUT:/Directory/CollaboratorTag/UpdateCollaboratorTag
     * @secure
     */
    collaboratorTagUpdateCollaboratorTagUpdate: (data: CollaboratorTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/UpdateCollaboratorTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagRestoreUpdate
     * @request PUT:/Directory/CollaboratorTag/Restore
     * @secure
     */
    collaboratorTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagArchiveDelete
     * @request DELETE:/Directory/CollaboratorTag/Archive
     * @secure
     */
    collaboratorTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CollaboratorTag
     * @name CollaboratorTagDeleteDelete
     * @request DELETE:/Directory/CollaboratorTag/Delete
     * @secure
     */
    collaboratorTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CollaboratorTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyGetCompaniesList
     * @request GET:/Directory/Company/GetCompanies
     * @secure
     */
    companyGetCompaniesList: (
      data: number[],
      query?: {
        code?: string;
        withIndicators?: boolean;
        /** @format int32 */
        companyTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Company/GetCompanies`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyGetCompaniesArchivedList
     * @request GET:/Directory/Company/GetCompaniesArchived
     * @secure
     */
    companyGetCompaniesArchivedList: (
      data: number[],
      query?: {
        code?: string;
        withIndicators?: boolean;
        /** @format int32 */
        companyTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Company/GetCompaniesArchived`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyGetCompanyByIdList
     * @request GET:/Directory/Company/GetCompanyById
     * @secure
     */
    companyGetCompanyByIdList: (
      query?: {
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Company/GetCompanyById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyGetCompanyArchivedByIdList
     * @request GET:/Directory/Company/GetCompanyArchivedById
     * @secure
     */
    companyGetCompanyArchivedByIdList: (
      query?: {
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Company/GetCompanyArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyCreateCompanyCreate
     * @request POST:/Directory/Company/CreateCompany
     * @secure
     */
    companyCreateCompanyCreate: (data: CompanyRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Company/CreateCompany`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyUpdateCompanyUpdate
     * @request PUT:/Directory/Company/UpdateCompany
     * @secure
     */
    companyUpdateCompanyUpdate: (data: CompanyRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Company/UpdateCompany`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyUpdateCompanyTagCompanyUpdate
     * @request PUT:/Directory/Company/UpdateCompanyTagCompany
     * @secure
     */
    companyUpdateCompanyTagCompanyUpdate: (data: CompanyTagCompanyRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Company/UpdateCompanyTagCompany`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyUpdateCompaniesAccountUpdate
     * @request PUT:/Directory/Company/UpdateCompaniesAccount
     * @secure
     */
    companyUpdateCompaniesAccountUpdate: (data: Int32StringTuple[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Company/UpdateCompaniesAccount`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyUpdateCompanyCustomPerimeterUpdate
     * @request PUT:/Directory/Company/UpdateCompanyCustomPerimeter
     * @secure
     */
    companyUpdateCompanyCustomPerimeterUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Company/UpdateCompanyCustomPerimeter`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyRestoreUpdate
     * @request PUT:/Directory/Company/Restore
     * @secure
     */
    companyRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Company/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyArchiveDelete
     * @request DELETE:/Directory/Company/Archive
     * @secure
     */
    companyArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Company/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyDeleteDelete
     * @request DELETE:/Directory/Company/Delete
     * @secure
     */
    companyDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Company/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyDeleteAddressesFromCompanyDelete
     * @request DELETE:/Directory/Company/DeleteAddressesFromCompany
     * @secure
     */
    companyDeleteAddressesFromCompanyDelete: (
      data: number[],
      query?: {
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Company/DeleteAddressesFromCompany`,
        method: "DELETE",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorGetCompanyIndicatorsList
     * @request GET:/Directory/CompanyIndicator/GetCompanyIndicators
     * @secure
     */
    companyIndicatorGetCompanyIndicatorsList: (
      query?: {
        /** @format int32 */
        companyTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/GetCompanyIndicators`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorGetCompanyIndicatorByIdList
     * @request GET:/Directory/CompanyIndicator/GetCompanyIndicatorById
     * @secure
     */
    companyIndicatorGetCompanyIndicatorByIdList: (
      query?: {
        /** @format int32 */
        companyIndicatorId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/GetCompanyIndicatorById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorGetCompanyIndicatorsArchivedList
     * @request GET:/Directory/CompanyIndicator/GetCompanyIndicatorsArchived
     * @secure
     */
    companyIndicatorGetCompanyIndicatorsArchivedList: (
      query?: {
        /** @format int32 */
        companyTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/GetCompanyIndicatorsArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorGetCompanyIndicatorArchivedByIdList
     * @request GET:/Directory/CompanyIndicator/GetCompanyIndicatorArchivedById
     * @secure
     */
    companyIndicatorGetCompanyIndicatorArchivedByIdList: (
      query?: {
        /** @format int32 */
        companyIndicatorId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/GetCompanyIndicatorArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorCreateCompanyIndicatorCreate
     * @request POST:/Directory/CompanyIndicator/CreateCompanyIndicator
     * @secure
     */
    companyIndicatorCreateCompanyIndicatorCreate: (data: CompanyIndicatorRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/CreateCompanyIndicator`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorUpdateCompanyIndicatorUpdate
     * @request PUT:/Directory/CompanyIndicator/UpdateCompanyIndicator
     * @secure
     */
    companyIndicatorUpdateCompanyIndicatorUpdate: (data: CompanyIndicatorRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/UpdateCompanyIndicator`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorUpdateCompanyCompanyIndicatorUpdate
     * @request PUT:/Directory/CompanyIndicator/UpdateCompanyCompanyIndicator
     * @secure
     */
    companyIndicatorUpdateCompanyCompanyIndicatorUpdate: (
      data: Record<string, string>,
      query?: {
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/UpdateCompanyCompanyIndicator`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorRestoreUpdate
     * @request PUT:/Directory/CompanyIndicator/Restore
     * @secure
     */
    companyIndicatorRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorArchiveDelete
     * @request DELETE:/Directory/CompanyIndicator/Archive
     * @secure
     */
    companyIndicatorArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyIndicator
     * @name CompanyIndicatorDeleteDelete
     * @request DELETE:/Directory/CompanyIndicator/Delete
     * @secure
     */
    companyIndicatorDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyIndicator/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagGetCompanyTagsList
     * @request GET:/Directory/CompanyTag/GetCompanyTags
     * @secure
     */
    companyTagGetCompanyTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/GetCompanyTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagGetCompanyTagByIdList
     * @request GET:/Directory/CompanyTag/GetCompanyTagById
     * @secure
     */
    companyTagGetCompanyTagByIdList: (
      query?: {
        /** @format int32 */
        companyTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/GetCompanyTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagGetCompanyTagsArchivedList
     * @request GET:/Directory/CompanyTag/GetCompanyTagsArchived
     * @secure
     */
    companyTagGetCompanyTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/GetCompanyTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagGetCompanyTagArchivedByIdList
     * @request GET:/Directory/CompanyTag/GetCompanyTagArchivedById
     * @secure
     */
    companyTagGetCompanyTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        companyTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/GetCompanyTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagCreateCompanyTagCreate
     * @request POST:/Directory/CompanyTag/CreateCompanyTag
     * @secure
     */
    companyTagCreateCompanyTagCreate: (data: CompanyTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/CreateCompanyTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagUpdateCompanyTagUpdate
     * @request PUT:/Directory/CompanyTag/UpdateCompanyTag
     * @secure
     */
    companyTagUpdateCompanyTagUpdate: (data: CompanyTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/UpdateCompanyTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagRestoreUpdate
     * @request PUT:/Directory/CompanyTag/Restore
     * @secure
     */
    companyTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagArchiveDelete
     * @request DELETE:/Directory/CompanyTag/Archive
     * @secure
     */
    companyTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyTag
     * @name CompanyTagDeleteDelete
     * @request DELETE:/Directory/CompanyTag/Delete
     * @secure
     */
    companyTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyType
     * @name CompanyTypeGetCompanyTypesList
     * @request GET:/Directory/CompanyType/GetCompanyTypes
     * @secure
     */
    companyTypeGetCompanyTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/CompanyType/GetCompanyTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyType
     * @name CompanyTypeGetCompanyTypeByIdList
     * @request GET:/Directory/CompanyType/GetCompanyTypeById
     * @secure
     */
    companyTypeGetCompanyTypeByIdList: (
      query?: {
        /** @format int32 */
        companyTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/CompanyType/GetCompanyTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactGetContactsList
     * @request GET:/Directory/Contact/GetContacts
     * @secure
     */
    contactGetContactsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/GetContacts`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactGetContactsArchivedList
     * @request GET:/Directory/Contact/GetContactsArchived
     * @secure
     */
    contactGetContactsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/GetContactsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactGetContactByIdList
     * @request GET:/Directory/Contact/GetContactById
     * @secure
     */
    contactGetContactByIdList: (
      query?: {
        /** @format int32 */
        contactId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Contact/GetContactById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactGetContactArchivedByIdList
     * @request GET:/Directory/Contact/GetContactArchivedById
     * @secure
     */
    contactGetContactArchivedByIdList: (
      query?: {
        /** @format int32 */
        contactId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/Contact/GetContactArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactCreateContactCreate
     * @request POST:/Directory/Contact/CreateContact
     * @secure
     */
    contactCreateContactCreate: (data: ContactRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/CreateContact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactUpdateContactUpdate
     * @request PUT:/Directory/Contact/UpdateContact
     * @secure
     */
    contactUpdateContactUpdate: (data: ContactRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/UpdateContact`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactUpdateContactTagContactUpdate
     * @request PUT:/Directory/Contact/UpdateContactTagContact
     * @secure
     */
    contactUpdateContactTagContactUpdate: (data: ContactTagContactRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/UpdateContactTagContact`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactRestoreUpdate
     * @request PUT:/Directory/Contact/Restore
     * @secure
     */
    contactRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactArchiveDelete
     * @request DELETE:/Directory/Contact/Archive
     * @secure
     */
    contactArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactDeleteDelete
     * @request DELETE:/Directory/Contact/Delete
     * @secure
     */
    contactDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/Contact/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagGetContactTagsList
     * @request GET:/Directory/ContactTag/GetContactTags
     * @secure
     */
    contactTagGetContactTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/GetContactTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagGetContactTagByIdList
     * @request GET:/Directory/ContactTag/GetContactTagById
     * @secure
     */
    contactTagGetContactTagByIdList: (
      query?: {
        /** @format int32 */
        contactTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/GetContactTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagGetContactTagsArchivedList
     * @request GET:/Directory/ContactTag/GetContactTagsArchived
     * @secure
     */
    contactTagGetContactTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/GetContactTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagGetContactTagArchivedByIdList
     * @request GET:/Directory/ContactTag/GetContactTagArchivedById
     * @secure
     */
    contactTagGetContactTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        contactTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/GetContactTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagCreateContactTagCreate
     * @request POST:/Directory/ContactTag/CreateContactTag
     * @secure
     */
    contactTagCreateContactTagCreate: (data: ContactTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/CreateContactTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagUpdateContactTagUpdate
     * @request PUT:/Directory/ContactTag/UpdateContactTag
     * @secure
     */
    contactTagUpdateContactTagUpdate: (data: ContactTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/UpdateContactTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagRestoreUpdate
     * @request PUT:/Directory/ContactTag/Restore
     * @secure
     */
    contactTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagArchiveDelete
     * @request DELETE:/Directory/ContactTag/Archive
     * @secure
     */
    contactTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContactTag
     * @name ContactTagDeleteDelete
     * @request DELETE:/Directory/ContactTag/Delete
     * @secure
     */
    contactTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Directory/ContactTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  reporting = {
    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingGetAnalyticsSettingByIdList
     * @request GET:/Reporting/AnalyticsSetting/GetAnalyticsSettingById
     * @secure
     */
    analyticsSettingGetAnalyticsSettingByIdList: (
      query?: {
        /** @format int32 */
        analyticsSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/GetAnalyticsSettingById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingGetAnalyticsSettingsList
     * @request GET:/Reporting/AnalyticsSetting/GetAnalyticsSettings
     * @secure
     */
    analyticsSettingGetAnalyticsSettingsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/GetAnalyticsSettings`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingGetAnalyticsSettingArchivedByIdList
     * @request GET:/Reporting/AnalyticsSetting/GetAnalyticsSettingArchivedById
     * @secure
     */
    analyticsSettingGetAnalyticsSettingArchivedByIdList: (
      query?: {
        /** @format int32 */
        analyticsSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/GetAnalyticsSettingArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingGetAnalyticsSettingsArchivedList
     * @request GET:/Reporting/AnalyticsSetting/GetAnalyticsSettingsArchived
     * @secure
     */
    analyticsSettingGetAnalyticsSettingsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/GetAnalyticsSettingsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingGetAnalyticsSettingsTemplatesList
     * @request GET:/Reporting/AnalyticsSetting/GetAnalyticsSettingsTemplates
     * @secure
     */
    analyticsSettingGetAnalyticsSettingsTemplatesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/GetAnalyticsSettingsTemplates`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingCreateAnalyticsSettingsCreate
     * @request POST:/Reporting/AnalyticsSetting/CreateAnalyticsSettings
     * @secure
     */
    analyticsSettingCreateAnalyticsSettingsCreate: (data: AnalyticsSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/CreateAnalyticsSettings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingUpdateAnalyticsSettingUpdate
     * @request PUT:/Reporting/AnalyticsSetting/UpdateAnalyticsSetting
     * @secure
     */
    analyticsSettingUpdateAnalyticsSettingUpdate: (data: AnalyticsSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/UpdateAnalyticsSetting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingRestoreUpdate
     * @request PUT:/Reporting/AnalyticsSetting/Restore
     * @secure
     */
    analyticsSettingRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingArchiveDelete
     * @request DELETE:/Reporting/AnalyticsSetting/Archive
     * @secure
     */
    analyticsSettingArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnalyticsSetting
     * @name AnalyticsSettingDeleteDelete
     * @request DELETE:/Reporting/AnalyticsSetting/Delete
     * @secure
     */
    analyticsSettingDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/AnalyticsSetting/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionGetMissionByIdList
     * @request GET:/Reporting/Mission/GetMissionById
     * @secure
     */
    missionGetMissionByIdList: (
      query?: {
        /** @format int32 */
        missionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/Mission/GetMissionById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionGetMissionsList
     * @request GET:/Reporting/Mission/GetMissions
     * @secure
     */
    missionGetMissionsList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        analyticSettingId?: number;
        templateOnly?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/Mission/GetMissions`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionGetMissionArchivedByIdList
     * @request GET:/Reporting/Mission/GetMissionArchivedById
     * @secure
     */
    missionGetMissionArchivedByIdList: (
      query?: {
        /** @format int32 */
        missionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/Mission/GetMissionArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionGetMissionsArchivedList
     * @request GET:/Reporting/Mission/GetMissionsArchived
     * @secure
     */
    missionGetMissionsArchivedList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        analyticSettingId?: number;
        templateOnly?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/Mission/GetMissionsArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionCreateMissionCreate
     * @request POST:/Reporting/Mission/CreateMission
     * @secure
     */
    missionCreateMissionCreate: (data: MissionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/Mission/CreateMission`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionUpdateMissionUpdate
     * @request PUT:/Reporting/Mission/UpdateMission
     * @secure
     */
    missionUpdateMissionUpdate: (data: MissionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/Mission/UpdateMission`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionRestoreUpdate
     * @request PUT:/Reporting/Mission/Restore
     * @secure
     */
    missionRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/Mission/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionArchiveDelete
     * @request DELETE:/Reporting/Mission/Archive
     * @secure
     */
    missionArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/Mission/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mission
     * @name MissionDeleteDelete
     * @request DELETE:/Reporting/Mission/Delete
     * @secure
     */
    missionDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/Mission/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeGetPayAllowanceTypeByIdList
     * @request GET:/Reporting/PayAllowanceType/GetPayAllowanceTypeById
     * @secure
     */
    payAllowanceTypeGetPayAllowanceTypeByIdList: (
      query?: {
        /** @format int32 */
        payAllowanceTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/GetPayAllowanceTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeGetPayAllowanceTypesList
     * @request GET:/Reporting/PayAllowanceType/GetPayAllowanceTypes
     * @secure
     */
    payAllowanceTypeGetPayAllowanceTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/GetPayAllowanceTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeGetPayAllowanceTypeArchivedByIdList
     * @request GET:/Reporting/PayAllowanceType/GetPayAllowanceTypeArchivedById
     * @secure
     */
    payAllowanceTypeGetPayAllowanceTypeArchivedByIdList: (
      query?: {
        /** @format int32 */
        payAllowanceTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/GetPayAllowanceTypeArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeGetPayAllowanceTypesArchivedList
     * @request GET:/Reporting/PayAllowanceType/GetPayAllowanceTypesArchived
     * @secure
     */
    payAllowanceTypeGetPayAllowanceTypesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/GetPayAllowanceTypesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeCreatePayAllowanceTypeCreate
     * @request POST:/Reporting/PayAllowanceType/CreatePayAllowanceType
     * @secure
     */
    payAllowanceTypeCreatePayAllowanceTypeCreate: (data: PayAllowanceTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/CreatePayAllowanceType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeUpdatePayAllowanceTypeUpdate
     * @request PUT:/Reporting/PayAllowanceType/UpdatePayAllowanceType
     * @secure
     */
    payAllowanceTypeUpdatePayAllowanceTypeUpdate: (data: PayAllowanceTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/UpdatePayAllowanceType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeRestoreUpdate
     * @request PUT:/Reporting/PayAllowanceType/Restore
     * @secure
     */
    payAllowanceTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeArchiveDelete
     * @request DELETE:/Reporting/PayAllowanceType/Archive
     * @secure
     */
    payAllowanceTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayAllowanceType
     * @name PayAllowanceTypeDeleteDelete
     * @request DELETE:/Reporting/PayAllowanceType/Delete
     * @secure
     */
    payAllowanceTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayAllowanceType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventGetPayrollEventByIdList
     * @request GET:/Reporting/PayrollEvent/GetPayrollEventById
     * @secure
     */
    payrollEventGetPayrollEventByIdList: (
      query?: {
        /** @format int32 */
        payrollEventId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/GetPayrollEventById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventGetPayrollEventsList
     * @request GET:/Reporting/PayrollEvent/GetPayrollEvents
     * @secure
     */
    payrollEventGetPayrollEventsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/GetPayrollEvents`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventGetPayrollEventArchivedByIdList
     * @request GET:/Reporting/PayrollEvent/GetPayrollEventArchivedById
     * @secure
     */
    payrollEventGetPayrollEventArchivedByIdList: (
      query?: {
        /** @format int32 */
        payrollEventId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/GetPayrollEventArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventGetPayrollEventsArchivedList
     * @request GET:/Reporting/PayrollEvent/GetPayrollEventsArchived
     * @secure
     */
    payrollEventGetPayrollEventsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/GetPayrollEventsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventCreatePayrollEventCreate
     * @request POST:/Reporting/PayrollEvent/CreatePayrollEvent
     * @secure
     */
    payrollEventCreatePayrollEventCreate: (data: PayrollEventRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/CreatePayrollEvent`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventUpdatePayrollEventUpdate
     * @request PUT:/Reporting/PayrollEvent/UpdatePayrollEvent
     * @secure
     */
    payrollEventUpdatePayrollEventUpdate: (data: PayrollEventRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/UpdatePayrollEvent`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventRestoreUpdate
     * @request PUT:/Reporting/PayrollEvent/Restore
     * @secure
     */
    payrollEventRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventArchiveDelete
     * @request DELETE:/Reporting/PayrollEvent/Archive
     * @secure
     */
    payrollEventArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollEvent
     * @name PayrollEventDeleteDelete
     * @request DELETE:/Reporting/PayrollEvent/Delete
     * @secure
     */
    payrollEventDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/PayrollEvent/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailGetReportDetailByIdList
     * @request GET:/Reporting/ReportDetail/GetReportDetailById
     * @secure
     */
    reportDetailGetReportDetailByIdList: (
      query?: {
        /** @format int32 */
        reportDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/GetReportDetailById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailGetReportDetailsMinifiedList
     * @request GET:/Reporting/ReportDetail/GetReportDetailsMinified
     * @secure
     */
    reportDetailGetReportDetailsMinifiedList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        globalReport?: boolean;
        collaboratorIds?: number[];
        reportDetailIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/GetReportDetailsMinified`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailGetReportDetailsList
     * @request GET:/Reporting/ReportDetail/GetReportDetails
     * @secure
     */
    reportDetailGetReportDetailsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        affairId?: number;
        groupedByMission?: boolean;
        /** @format int32 */
        missionId?: number;
        collaboratorIds?: number[];
        reportDetailIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/GetReportDetails`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailCreateReportDetailCreate
     * @request POST:/Reporting/ReportDetail/CreateReportDetail
     * @secure
     */
    reportDetailCreateReportDetailCreate: (data: ReportDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/CreateReportDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailGenerateReportDetailsFromPlanningCreate
     * @request POST:/Reporting/ReportDetail/GenerateReportDetailsFromPlanning
     * @secure
     */
    reportDetailGenerateReportDetailsFromPlanningCreate: (
      data: number[],
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/GenerateReportDetailsFromPlanning`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailUpdateReportDetailUpdate
     * @request PUT:/Reporting/ReportDetail/UpdateReportDetail
     * @secure
     */
    reportDetailUpdateReportDetailUpdate: (data: ReportDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/UpdateReportDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailUpdateReportPayAllowanceTypeUpdate
     * @request PUT:/Reporting/ReportDetail/UpdateReportPayAllowanceType
     * @secure
     */
    reportDetailUpdateReportPayAllowanceTypeUpdate: (
      data: ReportDetailPayAllowanceTypeRequestDto[],
      query?: {
        /** @format int32 */
        reportDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/UpdateReportPayAllowanceType`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailUpdateReportsStatusUpdate
     * @request PUT:/Reporting/ReportDetail/UpdateReportsStatus
     * @secure
     */
    reportDetailUpdateReportsStatusUpdate: (data: Record<string, ReportingStatus>, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/UpdateReportsStatus`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportDetail
     * @name ReportDetailDeleteDelete
     * @request DELETE:/Reporting/ReportDetail/Delete
     * @secure
     */
    reportDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingSetting
     * @name ReportingSettingGetReportingSettingByIdList
     * @request GET:/Reporting/ReportingSetting/GetReportingSettingById
     * @secure
     */
    reportingSettingGetReportingSettingByIdList: (
      query?: {
        /** @format int32 */
        reportingSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Reporting/ReportingSetting/GetReportingSettingById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingSetting
     * @name ReportingSettingGetReportingSettingList
     * @request GET:/Reporting/ReportingSetting/GetReportingSetting
     * @secure
     */
    reportingSettingGetReportingSettingList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportingSetting/GetReportingSetting`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingSetting
     * @name ReportingSettingCreateReportingSettingCreate
     * @request POST:/Reporting/ReportingSetting/CreateReportingSetting
     * @secure
     */
    reportingSettingCreateReportingSettingCreate: (data: ReportingSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportingSetting/CreateReportingSetting`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingSetting
     * @name ReportingSettingUpdateReportingSettingUpdate
     * @request PUT:/Reporting/ReportingSetting/UpdateReportingSetting
     * @secure
     */
    reportingSettingUpdateReportingSettingUpdate: (data: ReportingSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportingSetting/UpdateReportingSetting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportingSetting
     * @name ReportingSettingDeleteDelete
     * @request DELETE:/Reporting/ReportingSetting/Delete
     * @secure
     */
    reportingSettingDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Reporting/ReportingSetting/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  sale = {
    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountGetBankAccountsList
     * @request GET:/Sale/BankAccount/GetBankAccounts
     * @secure
     */
    bankAccountGetBankAccountsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/GetBankAccounts`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountGetBankAccountByIdList
     * @request GET:/Sale/BankAccount/GetBankAccountById
     * @secure
     */
    bankAccountGetBankAccountByIdList: (
      query?: {
        /** @format int32 */
        bankAccountId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/GetBankAccountById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountCreateBankAccountCreate
     * @request POST:/Sale/BankAccount/CreateBankAccount
     * @secure
     */
    bankAccountCreateBankAccountCreate: (data: BankAccountRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/CreateBankAccount`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountUpdateBankAccountUpdate
     * @request PUT:/Sale/BankAccount/UpdateBankAccount
     * @secure
     */
    bankAccountUpdateBankAccountUpdate: (data: BankAccountRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/UpdateBankAccount`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountUploadRibUpdate
     * @request PUT:/Sale/BankAccount/UploadRib
     * @secure
     */
    bankAccountUploadRibUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        bankAccountId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/UploadRib`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountRestoreUpdate
     * @request PUT:/Sale/BankAccount/Restore
     * @secure
     */
    bankAccountRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountArchiveDelete
     * @request DELETE:/Sale/BankAccount/Archive
     * @secure
     */
    bankAccountArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccount
     * @name BankAccountDeleteDelete
     * @request DELETE:/Sale/BankAccount/Delete
     * @secure
     */
    bankAccountDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/BankAccount/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditGetCreditsList
     * @request GET:/Sale/Credit/GetCredits
     * @secure
     */
    creditGetCreditsList: (
      query?: {
        companyIds?: number[];
        status?: DocumentStatus[];
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        invoiceId?: number;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Credit/GetCredits`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditGetCreditByIdList
     * @request GET:/Sale/Credit/GetCreditById
     * @secure
     */
    creditGetCreditByIdList: (
      query?: {
        /** @format int32 */
        creditId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Credit/GetCreditById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditGetLastDocumentReferenceList
     * @request GET:/Sale/Credit/GetLastDocumentReference
     * @secure
     */
    creditGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Credit/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditGetCreditEmptyList
     * @request GET:/Sale/Credit/GetCreditEmpty
     * @secure
     */
    creditGetCreditEmptyList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        companyId?: number;
        /** @format int32 */
        userId?: number;
        nature?: Nature;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Credit/GetCreditEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditCreateCreditCreate
     * @request POST:/Sale/Credit/CreateCredit
     * @secure
     */
    creditCreateCreditCreate: (data: CreditRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Credit/CreateCredit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditDuplicateCreditCreate
     * @request POST:/Sale/Credit/DuplicateCredit
     * @secure
     */
    creditDuplicateCreditCreate: (
      query?: {
        /** @format int32 */
        creditId?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Credit/DuplicateCredit`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditUpdateCreditUpdate
     * @request PUT:/Sale/Credit/UpdateCredit
     * @secure
     */
    creditUpdateCreditUpdate: (data: CreditRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Credit/UpdateCredit`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditUploadPathDocumentUpdate
     * @request PUT:/Sale/Credit/UploadPathDocument
     * @secure
     */
    creditUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        creditId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Credit/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditUploadFileUpdate
     * @request PUT:/Sale/Credit/UploadFile
     * @secure
     */
    creditUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        creditId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Credit/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditUpdateCreditTagCreditUpdate
     * @request PUT:/Sale/Credit/UpdateCreditTagCredit
     * @secure
     */
    creditUpdateCreditTagCreditUpdate: (data: CreditTagCreditRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Credit/UpdateCreditTagCredit`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credit
     * @name CreditDeleteDelete
     * @request DELETE:/Sale/Credit/Delete
     * @secure
     */
    creditDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Credit/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditDetail
     * @name CreditDetailGetCreditDetailsByCreditIdList
     * @request GET:/Sale/CreditDetail/GetCreditDetailsByCreditId
     * @secure
     */
    creditDetailGetCreditDetailsByCreditIdList: (
      query?: {
        /** @format int32 */
        creditId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/CreditDetail/GetCreditDetailsByCreditId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditDetail
     * @name CreditDetailCreateCreditDetailCreate
     * @request POST:/Sale/CreditDetail/CreateCreditDetail
     * @secure
     */
    creditDetailCreateCreditDetailCreate: (data: CreditDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditDetail/CreateCreditDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditDetail
     * @name CreditDetailDuplicateCreditDetailsCreate
     * @request POST:/Sale/CreditDetail/DuplicateCreditDetails
     * @secure
     */
    creditDetailDuplicateCreditDetailsCreate: (
      query?: {
        /** @format int32 */
        creditDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/CreditDetail/DuplicateCreditDetails`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditDetail
     * @name CreditDetailUpdateCreditDetailUpdate
     * @request PUT:/Sale/CreditDetail/UpdateCreditDetail
     * @secure
     */
    creditDetailUpdateCreditDetailUpdate: (data: CreditDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditDetail/UpdateCreditDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditDetail
     * @name CreditDetailUpdateCreditDetailIndexUpdate
     * @request PUT:/Sale/CreditDetail/UpdateCreditDetailIndex
     * @secure
     */
    creditDetailUpdateCreditDetailIndexUpdate: (data: IndexRequestDto[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditDetail/UpdateCreditDetailIndex`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditDetail
     * @name CreditDetailDeleteDelete
     * @request DELETE:/Sale/CreditDetail/Delete
     * @secure
     */
    creditDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditHistory
     * @name CreditHistoryGetCreditHistoryByCreditIdList
     * @request GET:/Sale/CreditHistory/GetCreditHistoryByCreditId
     * @secure
     */
    creditHistoryGetCreditHistoryByCreditIdList: (
      query?: {
        /** @format int32 */
        creditId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/CreditHistory/GetCreditHistoryByCreditId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditHistory
     * @name CreditHistoryGetCreditHistoryByIdList
     * @request GET:/Sale/CreditHistory/GetCreditHistoryById
     * @secure
     */
    creditHistoryGetCreditHistoryByIdList: (
      query?: {
        /** @format int32 */
        CreditHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/CreditHistory/GetCreditHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditOption
     * @name CreditOptionGetCreditOptionByCreditIdList
     * @request GET:/Sale/CreditOption/GetCreditOptionByCreditId
     * @secure
     */
    creditOptionGetCreditOptionByCreditIdList: (
      query?: {
        /** @format int32 */
        creditId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/CreditOption/GetCreditOptionByCreditId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditOption
     * @name CreditOptionCreateCreditOptionCreate
     * @request POST:/Sale/CreditOption/CreateCreditOption
     * @secure
     */
    creditOptionCreateCreditOptionCreate: (data: CreditOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditOption/CreateCreditOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditOption
     * @name CreditOptionUpdateCreditOptionUpdate
     * @request PUT:/Sale/CreditOption/UpdateCreditOption
     * @secure
     */
    creditOptionUpdateCreditOptionUpdate: (data: CreditOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditOption/UpdateCreditOption`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditOptionDefault
     * @name CreditOptionDefaultGetDefaultList
     * @request GET:/Sale/CreditOptionDefault/GetDefault
     * @secure
     */
    creditOptionDefaultGetDefaultList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditOptionDefault/GetDefault`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditOptionDefault
     * @name CreditOptionDefaultCreateCreditOptionDefaultCreate
     * @request POST:/Sale/CreditOptionDefault/CreateCreditOptionDefault
     * @secure
     */
    creditOptionDefaultCreateCreditOptionDefaultCreate: (data: CreditOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditOptionDefault/CreateCreditOptionDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditOptionDefault
     * @name CreditOptionDefaultUpdateCreditOptionDefaultUpdate
     * @request PUT:/Sale/CreditOptionDefault/UpdateCreditOptionDefault
     * @secure
     */
    creditOptionDefaultUpdateCreditOptionDefaultUpdate: (data: CreditOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/CreditOptionDefault/UpdateCreditOptionDefault`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Deduction
     * @name DeductionGetDeductionByIdList
     * @request GET:/Sale/Deduction/GetDeductionById
     * @secure
     */
    deductionGetDeductionByIdList: (
      query?: {
        /** @format int32 */
        deductionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Deduction/GetDeductionById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Deduction
     * @name DeductionGetDeductionByInvoiceIdList
     * @request GET:/Sale/Deduction/GetDeductionByInvoiceId
     * @secure
     */
    deductionGetDeductionByInvoiceIdList: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Deduction/GetDeductionByInvoiceId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Deduction
     * @name DeductionGetDeductionByQuoteIdList
     * @request GET:/Sale/Deduction/GetDeductionByQuoteId
     * @secure
     */
    deductionGetDeductionByQuoteIdList: (
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Deduction/GetDeductionByQuoteId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Deduction
     * @name DeductionGetDeductionByCreditIdList
     * @request GET:/Sale/Deduction/GetDeductionByCreditId
     * @secure
     */
    deductionGetDeductionByCreditIdList: (
      query?: {
        /** @format int32 */
        creditId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Deduction/GetDeductionByCreditId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Deduction
     * @name DeductionCreateDeductionCreate
     * @request POST:/Sale/Deduction/CreateDeduction
     * @secure
     */
    deductionCreateDeductionCreate: (data: DeductionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Deduction/CreateDeduction`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Deduction
     * @name DeductionUpdateDeductionUpdate
     * @request PUT:/Sale/Deduction/UpdateDeduction
     * @secure
     */
    deductionUpdateDeductionUpdate: (data: DeductionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Deduction/UpdateDeduction`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Deduction
     * @name DeductionDeleteDelete
     * @request DELETE:/Sale/Deduction/Delete
     * @secure
     */
    deductionDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Deduction/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressiveSalesRates
     * @name DegressiveSalesRatesGetDegressiveSalesRatesList
     * @request GET:/Sale/DegressiveSalesRates/GetDegressiveSalesRates
     * @secure
     */
    degressiveSalesRatesGetDegressiveSalesRatesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/DegressiveSalesRates/GetDegressiveSalesRates`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressiveSalesRates
     * @name DegressiveSalesRatesGetDegressiveSalesRateByIdList
     * @request GET:/Sale/DegressiveSalesRates/GetDegressiveSalesRateById
     * @secure
     */
    degressiveSalesRatesGetDegressiveSalesRateByIdList: (
      query?: {
        /** @format int32 */
        degressiveSalesRatesId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DegressiveSalesRates/GetDegressiveSalesRateById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressiveSalesRates
     * @name DegressiveSalesRatesCreateDegressiveSalesRatesCreate
     * @request POST:/Sale/DegressiveSalesRates/CreateDegressiveSalesRates
     * @secure
     */
    degressiveSalesRatesCreateDegressiveSalesRatesCreate: (
      data: DegressiveSalesRatesRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DegressiveSalesRates/CreateDegressiveSalesRates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressiveSalesRates
     * @name DegressiveSalesRatesUpdateDegressiveSalesRatesUpdate
     * @request PUT:/Sale/DegressiveSalesRates/UpdateDegressiveSalesRates
     * @secure
     */
    degressiveSalesRatesUpdateDegressiveSalesRatesUpdate: (
      data: DegressiveSalesRatesRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DegressiveSalesRates/UpdateDegressiveSalesRates`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressiveSalesRates
     * @name DegressiveSalesRatesDeleteDelete
     * @request DELETE:/Sale/DegressiveSalesRates/Delete
     * @secure
     */
    degressiveSalesRatesDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/DegressiveSalesRates/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormOption
     * @name DeliveryFormOptionGetDeliveryFormOptionByDeliveryFormIdList
     * @request GET:/Sale/DeliveryFormOption/GetDeliveryFormOptionByDeliveryFormId
     * @secure
     */
    deliveryFormOptionGetDeliveryFormOptionByDeliveryFormIdList: (
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DeliveryFormOption/GetDeliveryFormOptionByDeliveryFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormOption
     * @name DeliveryFormOptionCreateDeliveryFormOptionCreate
     * @request POST:/Sale/DeliveryFormOption/CreateDeliveryFormOption
     * @secure
     */
    deliveryFormOptionCreateDeliveryFormOptionCreate: (
      data: DeliveryFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DeliveryFormOption/CreateDeliveryFormOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormOption
     * @name DeliveryFormOptionUpdateDeliveryFormOptionUpdate
     * @request PUT:/Sale/DeliveryFormOption/UpdateDeliveryFormOption
     * @secure
     */
    deliveryFormOptionUpdateDeliveryFormOptionUpdate: (
      data: DeliveryFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DeliveryFormOption/UpdateDeliveryFormOption`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormOptionDefault
     * @name DeliveryFormOptionDefaultGetDefaultList
     * @request GET:/Sale/DeliveryFormOptionDefault/GetDefault
     * @secure
     */
    deliveryFormOptionDefaultGetDefaultList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/DeliveryFormOptionDefault/GetDefault`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormOptionDefault
     * @name DeliveryFormOptionDefaultCreateDeliveryFormOptionDefaultCreate
     * @request POST:/Sale/DeliveryFormOptionDefault/CreateDeliveryFormOptionDefault
     * @secure
     */
    deliveryFormOptionDefaultCreateDeliveryFormOptionDefaultCreate: (
      data: DeliveryFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DeliveryFormOptionDefault/CreateDeliveryFormOptionDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormOptionDefault
     * @name DeliveryFormOptionDefaultUpdateDeliveryFormOptionDefaultUpdate
     * @request PUT:/Sale/DeliveryFormOptionDefault/UpdateDeliveryFormOptionDefault
     * @secure
     */
    deliveryFormOptionDefaultUpdateDeliveryFormOptionDefaultUpdate: (
      data: DeliveryFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DeliveryFormOptionDefault/UpdateDeliveryFormOptionDefault`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailGetDocumentDetailsByQuoteIdList
     * @request GET:/Sale/DocumentDetail/GetDocumentDetailsByQuoteId
     * @secure
     */
    documentDetailGetDocumentDetailsByQuoteIdList: (
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/GetDocumentDetailsByQuoteId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailGetDocumentDetailsByInvoiceIdList
     * @request GET:/Sale/DocumentDetail/GetDocumentDetailsByInvoiceId
     * @secure
     */
    documentDetailGetDocumentDetailsByInvoiceIdList: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/GetDocumentDetailsByInvoiceId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailGetDocumentDetailByIdList
     * @request GET:/Sale/DocumentDetail/GetDocumentDetailById
     * @secure
     */
    documentDetailGetDocumentDetailByIdList: (
      query?: {
        /** @format int32 */
        documentDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/GetDocumentDetailById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailCreateDocumentDetailCreate
     * @request POST:/Sale/DocumentDetail/CreateDocumentDetail
     * @secure
     */
    documentDetailCreateDocumentDetailCreate: (data: DocumentDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/CreateDocumentDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailDuplicateDocumentDetailsCreate
     * @request POST:/Sale/DocumentDetail/DuplicateDocumentDetails
     * @secure
     */
    documentDetailDuplicateDocumentDetailsCreate: (
      query?: {
        /** @format int32 */
        documentDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/DuplicateDocumentDetails`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailCreateDocumentDetailsToInvoiceIdFromQuoteIdCreate
     * @request POST:/Sale/DocumentDetail/CreateDocumentDetailsToInvoiceIdFromQuoteId
     * @secure
     */
    documentDetailCreateDocumentDetailsToInvoiceIdFromQuoteIdCreate: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
        /** @format int32 */
        quoteId?: number;
        resetSubTotal?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/CreateDocumentDetailsToInvoiceIdFromQuoteId`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailCreateDocumentDetailsToInvoiceIdFromOrderFormCustomerIdCreate
     * @request POST:/Sale/DocumentDetail/CreateDocumentDetailsToInvoiceIdFromOrderFormCustomerId
     * @secure
     */
    documentDetailCreateDocumentDetailsToInvoiceIdFromOrderFormCustomerIdCreate: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
        /** @format int32 */
        orderFormCustomerId?: number;
        resetSubTotal?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/CreateDocumentDetailsToInvoiceIdFromOrderFormCustomerId`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailCreateDocumentDetailsToSitutationFromPreviousSituationCreate
     * @request POST:/Sale/DocumentDetail/CreateDocumentDetailsToSitutationFromPreviousSituation
     * @secure
     */
    documentDetailCreateDocumentDetailsToSitutationFromPreviousSituationCreate: (
      query?: {
        /** @format int32 */
        toInvoiceId?: number;
        /** @format int32 */
        fromInvoiceId?: number;
        resetSubTotal?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/CreateDocumentDetailsToSitutationFromPreviousSituation`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailUpdateDocumentDetailUpdate
     * @request PUT:/Sale/DocumentDetail/UpdateDocumentDetail
     * @secure
     */
    documentDetailUpdateDocumentDetailUpdate: (data: DocumentDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/UpdateDocumentDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailUpdateTvaDocumentDetailUpdate
     * @request PUT:/Sale/DocumentDetail/UpdateTVADocumentDetail
     * @secure
     */
    documentDetailUpdateTvaDocumentDetailUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        tvaId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/UpdateTVADocumentDetail`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailUpdateDocumentDetailIndexUpdate
     * @request PUT:/Sale/DocumentDetail/UpdateDocumentDetailIndex
     * @secure
     */
    documentDetailUpdateDocumentDetailIndexUpdate: (data: IndexRequestDto[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/UpdateDocumentDetailIndex`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentDetail
     * @name DocumentDetailDeleteDelete
     * @request DELETE:/Sale/DocumentDetail/Delete
     * @secure
     */
    documentDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/DocumentDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Export
     * @name ExportExportPeriodByInstitutionList
     * @request GET:/Sale/Export/ExportPeriodByInstitution
     * @secure
     */
    exportExportPeriodByInstitutionList: (
      query?: {
        /** @format int32 */
        exportTypeId?: number;
        numberingInvoiceCreditFollow?: boolean;
        haveHoldbackGuarantee?: boolean;
        /** @format date-time */
        startTo?: string;
        /** @format date-time */
        endTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Export/ExportPeriodByInstitution`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Export
     * @name ExportExportCsvPeriodByInstitutionList
     * @request GET:/Sale/Export/ExportCsvPeriodByInstitution
     * @secure
     */
    exportExportCsvPeriodByInstitutionList: (
      query?: {
        numberingInvoiceCreditFollow?: boolean;
        haveHoldbackGuarantee?: boolean;
        /** @format date-time */
        startTo?: string;
        /** @format date-time */
        endTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Export/ExportCsvPeriodByInstitution`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Export
     * @name ExportGetInvoicesCreditsOnTimeSpanList
     * @request GET:/Sale/Export/GetInvoicesCreditsOnTimeSpan
     * @secure
     */
    exportGetInvoicesCreditsOnTimeSpanList: (
      query?: {
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Export/GetInvoicesCreditsOnTimeSpan`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Export
     * @name ExportGetExportUrlList
     * @request GET:/Sale/Export/GetExportUrl
     * @secure
     */
    exportGetExportUrlList: (
      query?: {
        /** @format int32 */
        exportTypeId?: number;
        numberingInvoiceCreditFollow?: boolean;
        haveHoldbackGuarantee?: boolean;
        /** @format date-time */
        startTo?: string;
        /** @format date-time */
        endTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Export/GetExportUrl`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportSalesJournal
     * @name ExportSalesJournalGetExportSalesJournalsList
     * @request GET:/Sale/ExportSalesJournal/GetExportSalesJournals
     * @secure
     */
    exportSalesJournalGetExportSalesJournalsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ExportSalesJournal/GetExportSalesJournals`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExportSalesJournal
     * @name ExportSalesJournalGetExportSalesJournalByIdList
     * @request GET:/Sale/ExportSalesJournal/GetExportSalesJournalById
     * @secure
     */
    exportSalesJournalGetExportSalesJournalByIdList: (
      query?: {
        /** @format int32 */
        exportSalesJournalId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/ExportSalesJournal/GetExportSalesJournalById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FiscalYear
     * @name FiscalYearGetFiscalYearByIdList
     * @request GET:/Sale/FiscalYear/GetFiscalYearById
     * @secure
     */
    fiscalYearGetFiscalYearByIdList: (
      query?: {
        /** @format int32 */
        fiscalYearId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/FiscalYear/GetFiscalYearById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FiscalYear
     * @name FiscalYearGetFiscalYearByInstitutionSettingIdList
     * @request GET:/Sale/FiscalYear/GetFiscalYearByInstitutionSettingId
     * @secure
     */
    fiscalYearGetFiscalYearByInstitutionSettingIdList: (
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/FiscalYear/GetFiscalYearByInstitutionSettingId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FiscalYear
     * @name FiscalYearCreateFiscalYearCreate
     * @request POST:/Sale/FiscalYear/CreateFiscalYear
     * @secure
     */
    fiscalYearCreateFiscalYearCreate: (data: FiscalYearRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/FiscalYear/CreateFiscalYear`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FiscalYear
     * @name FiscalYearDeleteDelete
     * @request DELETE:/Sale/FiscalYear/Delete
     * @secure
     */
    fiscalYearDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/FiscalYear/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holdback
     * @name HoldbackGetHoldbacksList
     * @request GET:/Sale/Holdback/GetHoldbacks
     * @secure
     */
    holdbackGetHoldbacksList: (
      query?: {
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Holdback/GetHoldbacks`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holdback
     * @name HoldbackGetHoldbackByIdList
     * @request GET:/Sale/Holdback/GetHoldbackById
     * @secure
     */
    holdbackGetHoldbackByIdList: (
      query?: {
        /** @format int32 */
        HoldbackId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Holdback/GetHoldbackById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Holdback
     * @name HoldbackUpdateHoldbackUpdate
     * @request PUT:/Sale/Holdback/UpdateHoldback
     * @secure
     */
    holdbackUpdateHoldbackUpdate: (data: HoldbackRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Holdback/UpdateHoldback`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HoldbackHistory
     * @name HoldbackHistoryGetHoldbackHistoryByHoldbackIdList
     * @request GET:/Sale/HoldbackHistory/GetHoldbackHistoryByHoldbackId
     * @secure
     */
    holdbackHistoryGetHoldbackHistoryByHoldbackIdList: (
      query?: {
        /** @format int32 */
        holdbackId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/HoldbackHistory/GetHoldbackHistoryByHoldbackId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HoldbackHistory
     * @name HoldbackHistoryGetHoldbackHistoryByIdList
     * @request GET:/Sale/HoldbackHistory/GetHoldbackHistoryById
     * @secure
     */
    holdbackHistoryGetHoldbackHistoryByIdList: (
      query?: {
        /** @format int32 */
        holdbackHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/HoldbackHistory/GetHoldbackHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingGetInstitutionSettingList
     * @request GET:/Sale/InstitutionSetting/GetInstitutionSetting
     * @secure
     */
    institutionSettingGetInstitutionSettingList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/GetInstitutionSetting`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingCreateInstitutionSettingCreate
     * @request POST:/Sale/InstitutionSetting/CreateInstitutionSetting
     * @secure
     */
    institutionSettingCreateInstitutionSettingCreate: (
      data: InstitutionSettingRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/CreateInstitutionSetting`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingUpdateInstitutionSettingUpdate
     * @request PUT:/Sale/InstitutionSetting/UpdateInstitutionSetting
     * @secure
     */
    institutionSettingUpdateInstitutionSettingUpdate: (
      data: InstitutionSettingRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/UpdateInstitutionSetting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingUpdateInstitutionTypeInstitutionSettingUpdate
     * @request PUT:/Sale/InstitutionSetting/UpdateInstitutionTypeInstitutionSetting
     * @secure
     */
    institutionSettingUpdateInstitutionTypeInstitutionSettingUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/UpdateInstitutionTypeInstitutionSetting`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingUpdateExportSalesJournalInstitutionSettingUpdate
     * @request PUT:/Sale/InstitutionSetting/UpdateExportSalesJournalInstitutionSetting
     * @secure
     */
    institutionSettingUpdateExportSalesJournalInstitutionSettingUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/UpdateExportSalesJournalInstitutionSetting`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingUpdateInstitutionSettingPaymentMethodUpdate
     * @request PUT:/Sale/InstitutionSetting/UpdateInstitutionSettingPaymentMethod
     * @secure
     */
    institutionSettingUpdateInstitutionSettingPaymentMethodUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/UpdateInstitutionSettingPaymentMethod`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingUpdateInstitutionSettingReferencielTvasUpdate
     * @request PUT:/Sale/InstitutionSetting/UpdateInstitutionSettingReferencielTvas
     * @secure
     */
    institutionSettingUpdateInstitutionSettingReferencielTvasUpdate: (
      data: InstitutionSettingReferencielTvaRequestDto[],
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/UpdateInstitutionSettingReferencielTvas`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingUpdateInstitutionSettingPaymentConditionUpdate
     * @request PUT:/Sale/InstitutionSetting/UpdateInstitutionSettingPaymentCondition
     * @secure
     */
    institutionSettingUpdateInstitutionSettingPaymentConditionUpdate: (
      data: InstitutionSettingPaymentConditionRequestDto[],
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
        isOrderForm?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/UpdateInstitutionSettingPaymentCondition`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingUpdateInstitutionSettingUnitUpdate
     * @request PUT:/Sale/InstitutionSetting/UpdateInstitutionSettingUnit
     * @secure
     */
    institutionSettingUpdateInstitutionSettingUnitUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/UpdateInstitutionSettingUnit`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionSetting
     * @name InstitutionSettingDeleteDelete
     * @request DELETE:/Sale/InstitutionSetting/Delete
     * @secure
     */
    institutionSettingDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InstitutionSetting/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionType
     * @name InstitutionTypeGetInstitutionTypesList
     * @request GET:/Sale/InstitutionType/GetInstitutionTypes
     * @secure
     */
    institutionTypeGetInstitutionTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InstitutionType/GetInstitutionTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetInvoicesList
     * @request GET:/Sale/Invoice/GetInvoices
     * @secure
     */
    invoiceGetInvoicesList: (
      query?: {
        companyIds?: number[];
        status?: DocumentStatus[];
        /** @format int32 */
        affairId?: number;
        isTemplate?: boolean;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        isRemaining?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/GetInvoices`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetInvoiceByIdList
     * @request GET:/Sale/Invoice/GetInvoiceById
     * @secure
     */
    invoiceGetInvoiceByIdList: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/GetInvoiceById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetLastDocumentReferenceList
     * @request GET:/Sale/Invoice/GetLastDocumentReference
     * @secure
     */
    invoiceGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Invoice/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetInvoiceEmptyList
     * @request GET:/Sale/Invoice/GetInvoiceEmpty
     * @secure
     */
    invoiceGetInvoiceEmptyList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        companyId?: number;
        /** @format int32 */
        userId?: number;
        type?: DocumentType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/GetInvoiceEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetInitialInvoiceByIdList
     * @request GET:/Sale/Invoice/GetInitialInvoiceById
     * @secure
     */
    invoiceGetInitialInvoiceByIdList: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/GetInitialInvoiceById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetRemainingToPayList
     * @request GET:/Sale/Invoice/GetRemainingToPay
     * @secure
     */
    invoiceGetRemainingToPayList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Invoice/GetRemainingToPay`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceGetInvoiceStatsList
     * @request GET:/Sale/Invoice/GetInvoiceStats
     * @secure
     */
    invoiceGetInvoiceStatsList: (
      data: number[],
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/GetInvoiceStats`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceSendInvoiceCreate
     * @request POST:/Sale/Invoice/SendInvoice
     * @secure
     */
    invoiceSendInvoiceCreate: (data: SendInvoiceRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Invoice/SendInvoice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceCreateInvoiceCreate
     * @request POST:/Sale/Invoice/CreateInvoice
     * @secure
     */
    invoiceCreateInvoiceCreate: (data: InvoiceRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Invoice/CreateInvoice`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceDuplicateInvoiceCreate
     * @request POST:/Sale/Invoice/DuplicateInvoice
     * @secure
     */
    invoiceDuplicateInvoiceCreate: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/DuplicateInvoice`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceUpdateInvoiceUpdate
     * @request PUT:/Sale/Invoice/UpdateInvoice
     * @secure
     */
    invoiceUpdateInvoiceUpdate: (data: InvoiceRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Invoice/UpdateInvoice`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceUploadPathDocumentUpdate
     * @request PUT:/Sale/Invoice/UploadPathDocument
     * @secure
     */
    invoiceUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceUploadFileUpdate
     * @request PUT:/Sale/Invoice/UploadFile
     * @secure
     */
    invoiceUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceUpdateCreditInvoiceUpdate
     * @request PUT:/Sale/Invoice/UpdateCreditInvoice
     * @secure
     */
    invoiceUpdateCreditInvoiceUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        invoiceId?: number;
        cancelInvoice?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/UpdateCreditInvoice`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceUpdatePaymentMethodInvoiceUpdate
     * @request PUT:/Sale/Invoice/UpdatePaymentMethodInvoice
     * @secure
     */
    invoiceUpdatePaymentMethodInvoiceUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Invoice/UpdatePaymentMethodInvoice`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceUpdateInvoiceTagInvoiceUpdate
     * @request PUT:/Sale/Invoice/UpdateInvoiceTagInvoice
     * @secure
     */
    invoiceUpdateInvoiceTagInvoiceUpdate: (data: InvoiceTagInvoiceRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Invoice/UpdateInvoiceTagInvoice`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoice
     * @name InvoiceDeleteDelete
     * @request DELETE:/Sale/Invoice/Delete
     * @secure
     */
    invoiceDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Invoice/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceHistory
     * @name InvoiceHistoryGetInvoiceHistoryByInvoiceIdList
     * @request GET:/Sale/InvoiceHistory/GetInvoiceHistoryByInvoiceId
     * @secure
     */
    invoiceHistoryGetInvoiceHistoryByInvoiceIdList: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InvoiceHistory/GetInvoiceHistoryByInvoiceId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceHistory
     * @name InvoiceHistoryGetInvoiceHistoryByIdList
     * @request GET:/Sale/InvoiceHistory/GetInvoiceHistoryById
     * @secure
     */
    invoiceHistoryGetInvoiceHistoryByIdList: (
      query?: {
        /** @format int32 */
        InvoiceHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InvoiceHistory/GetInvoiceHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceOption
     * @name InvoiceOptionGetInvoiceOptionByInvoiceIdList
     * @request GET:/Sale/InvoiceOption/GetInvoiceOptionByInvoiceId
     * @secure
     */
    invoiceOptionGetInvoiceOptionByInvoiceIdList: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/InvoiceOption/GetInvoiceOptionByInvoiceId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceOption
     * @name InvoiceOptionCreateInvoiceOptionCreate
     * @request POST:/Sale/InvoiceOption/CreateInvoiceOption
     * @secure
     */
    invoiceOptionCreateInvoiceOptionCreate: (data: InvoiceOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InvoiceOption/CreateInvoiceOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceOption
     * @name InvoiceOptionUpdateInvoiceOptionUpdate
     * @request PUT:/Sale/InvoiceOption/UpdateInvoiceOption
     * @secure
     */
    invoiceOptionUpdateInvoiceOptionUpdate: (data: InvoiceOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InvoiceOption/UpdateInvoiceOption`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceOptionDefault
     * @name InvoiceOptionDefaultGetDefaultList
     * @request GET:/Sale/InvoiceOptionDefault/GetDefault
     * @secure
     */
    invoiceOptionDefaultGetDefaultList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InvoiceOptionDefault/GetDefault`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceOptionDefault
     * @name InvoiceOptionDefaultCreateInvoiceOptionDefaultCreate
     * @request POST:/Sale/InvoiceOptionDefault/CreateInvoiceOptionDefault
     * @secure
     */
    invoiceOptionDefaultCreateInvoiceOptionDefaultCreate: (data: InvoiceOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InvoiceOptionDefault/CreateInvoiceOptionDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceOptionDefault
     * @name InvoiceOptionDefaultUpdateInvoiceOptionDefaultUpdate
     * @request PUT:/Sale/InvoiceOptionDefault/UpdateInvoiceOptionDefault
     * @secure
     */
    invoiceOptionDefaultUpdateInvoiceOptionDefaultUpdate: (data: InvoiceOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/InvoiceOptionDefault/UpdateInvoiceOptionDefault`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerGetOrderFormCustomersList
     * @request GET:/Sale/OrderFormCustomer/GetOrderFormCustomers
     * @secure
     */
    orderFormCustomerGetOrderFormCustomersList: (
      query?: {
        companyIds?: number[];
        status?: OrderFormCustomerStatus[];
        /** @format int32 */
        affairId?: number;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        /** @default false */
        isTemplate?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/GetOrderFormCustomers`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerGetOrderFormCustomerByIdList
     * @request GET:/Sale/OrderFormCustomer/GetOrderFormCustomerById
     * @secure
     */
    orderFormCustomerGetOrderFormCustomerByIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/GetOrderFormCustomerById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerGetInitialOrderFormCustomerByIdList
     * @request GET:/Sale/OrderFormCustomer/GetInitialOrderFormCustomerById
     * @secure
     */
    orderFormCustomerGetInitialOrderFormCustomerByIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/GetInitialOrderFormCustomerById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerGetLastDocumentReferenceList
     * @request GET:/Sale/OrderFormCustomer/GetLastDocumentReference
     * @secure
     */
    orderFormCustomerGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerGetOrderFormCustomerEmptyList
     * @request GET:/Sale/OrderFormCustomer/GetOrderFormCustomerEmpty
     * @secure
     */
    orderFormCustomerGetOrderFormCustomerEmptyList: (
      query?: {
        /** @format int32 */
        userId?: number;
        type?: DocumentType;
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/GetOrderFormCustomerEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerGetRemainingToBillList
     * @request GET:/Sale/OrderFormCustomer/GetRemainingToBill
     * @secure
     */
    orderFormCustomerGetRemainingToBillList: (
      query?: {
        /** @format date-time */
        endTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/GetRemainingToBill`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerGetOrderFormCustomerStatsList
     * @request GET:/Sale/OrderFormCustomer/GetOrderFormCustomerStats
     * @secure
     */
    orderFormCustomerGetOrderFormCustomerStatsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        companyIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/GetOrderFormCustomerStats`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerCreateOrderFormCustomerCreate
     * @request POST:/Sale/OrderFormCustomer/CreateOrderFormCustomer
     * @secure
     */
    orderFormCustomerCreateOrderFormCustomerCreate: (data: OrderFormCustomerRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/CreateOrderFormCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerDuplicateOrderFormCustomerCreate
     * @request POST:/Sale/OrderFormCustomer/DuplicateOrderFormCustomer
     * @secure
     */
    orderFormCustomerDuplicateOrderFormCustomerCreate: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/DuplicateOrderFormCustomer`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerCreateOrderFormCustomerFromQuoteCreate
     * @request POST:/Sale/OrderFormCustomer/CreateOrderFormCustomerFromQuote
     * @secure
     */
    orderFormCustomerCreateOrderFormCustomerFromQuoteCreate: (
      query?: {
        /** @format int32 */
        quoteId?: number;
        /** @format int32 */
        userId?: number;
        status?: OrderFormCustomerStatus;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/CreateOrderFormCustomerFromQuote`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerUpdateOrderFormCustomerUpdate
     * @request PUT:/Sale/OrderFormCustomer/UpdateOrderFormCustomer
     * @secure
     */
    orderFormCustomerUpdateOrderFormCustomerUpdate: (data: OrderFormCustomerRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/UpdateOrderFormCustomer`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerUpdatePaymentMethodOrderFormCustomerUpdate
     * @request PUT:/Sale/OrderFormCustomer/UpdatePaymentMethodOrderFormCustomer
     * @secure
     */
    orderFormCustomerUpdatePaymentMethodOrderFormCustomerUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/UpdatePaymentMethodOrderFormCustomer`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerUploadPathDocumentUpdate
     * @request PUT:/Sale/OrderFormCustomer/UploadPathDocument
     * @secure
     */
    orderFormCustomerUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerUploadFileUpdate
     * @request PUT:/Sale/OrderFormCustomer/UploadFile
     * @secure
     */
    orderFormCustomerUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
        /** @format int32 */
        institutionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerUpdateOrderFormCustomerTagOrderFormCustomerUpdate
     * @request PUT:/Sale/OrderFormCustomer/UpdateOrderFormCustomerTagOrderFormCustomer
     * @secure
     */
    orderFormCustomerUpdateOrderFormCustomerTagOrderFormCustomerUpdate: (
      data: OrderFormCustomerTagOrderFormCustomerRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/UpdateOrderFormCustomerTagOrderFormCustomer`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomer
     * @name OrderFormCustomerDeleteDelete
     * @request DELETE:/Sale/OrderFormCustomer/Delete
     * @secure
     */
    orderFormCustomerDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomer/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailGetOrderFormCustomerDetailsByOrderFormCustomerIdList
     * @request GET:/Sale/OrderFormCustomerDetail/GetOrderFormCustomerDetailsByOrderFormCustomerId
     * @secure
     */
    orderFormCustomerDetailGetOrderFormCustomerDetailsByOrderFormCustomerIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/GetOrderFormCustomerDetailsByOrderFormCustomerId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailGetOrderFormCustomerDetailByIdList
     * @request GET:/Sale/OrderFormCustomerDetail/GetOrderFormCustomerDetailById
     * @secure
     */
    orderFormCustomerDetailGetOrderFormCustomerDetailByIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/GetOrderFormCustomerDetailById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailCreateOrderFormCustomerDetailCreate
     * @request POST:/Sale/OrderFormCustomerDetail/CreateOrderFormCustomerDetail
     * @secure
     */
    orderFormCustomerDetailCreateOrderFormCustomerDetailCreate: (
      data: OrderFormCustomerDetailRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/CreateOrderFormCustomerDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailUpdateOrderFormCustomerDetailUpdate
     * @request PUT:/Sale/OrderFormCustomerDetail/UpdateOrderFormCustomerDetail
     * @secure
     */
    orderFormCustomerDetailUpdateOrderFormCustomerDetailUpdate: (
      data: OrderFormCustomerDetailRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/UpdateOrderFormCustomerDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailDuplicateOrderFormCustomerDetailsCreate
     * @request POST:/Sale/OrderFormCustomerDetail/DuplicateOrderFormCustomerDetails
     * @secure
     */
    orderFormCustomerDetailDuplicateOrderFormCustomerDetailsCreate: (
      query?: {
        /** @format int32 */
        orderFormCustomerDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/DuplicateOrderFormCustomerDetails`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailUpdateTvaOrderFormCustomerDetailUpdate
     * @request PUT:/Sale/OrderFormCustomerDetail/UpdateTVAOrderFormCustomerDetail
     * @secure
     */
    orderFormCustomerDetailUpdateTvaOrderFormCustomerDetailUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        tvaId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/UpdateTVAOrderFormCustomerDetail`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailUpdateOrderFormCustomerDetailIndexUpdate
     * @request PUT:/Sale/OrderFormCustomerDetail/UpdateOrderFormCustomerDetailIndex
     * @secure
     */
    orderFormCustomerDetailUpdateOrderFormCustomerDetailIndexUpdate: (
      data: IndexRequestDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/UpdateOrderFormCustomerDetailIndex`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDetail
     * @name OrderFormCustomerDetailDeleteDelete
     * @request DELETE:/Sale/OrderFormCustomerDetail/Delete
     * @secure
     */
    orderFormCustomerDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDownPaymentsRequest
     * @name OrderFormCustomerDownPaymentsRequestGetOrderFormCustomerDownPaymentsRequestByIdList
     * @request GET:/Sale/OrderFormCustomerDownPaymentsRequest/GetOrderFormCustomerDownPaymentsRequestById
     * @secure
     */
    orderFormCustomerDownPaymentsRequestGetOrderFormCustomerDownPaymentsRequestByIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerDownPaymentsRequestId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDownPaymentsRequest/GetOrderFormCustomerDownPaymentsRequestById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDownPaymentsRequest
     * @name OrderFormCustomerDownPaymentsRequestGetOrderFormCustomerDownPaymentsRequestByOrderFormCustomerIdList
     * @request GET:/Sale/OrderFormCustomerDownPaymentsRequest/GetOrderFormCustomerDownPaymentsRequestByOrderFormCustomerId
     * @secure
     */
    orderFormCustomerDownPaymentsRequestGetOrderFormCustomerDownPaymentsRequestByOrderFormCustomerIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDownPaymentsRequest/GetOrderFormCustomerDownPaymentsRequestByOrderFormCustomerId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDownPaymentsRequest
     * @name OrderFormCustomerDownPaymentsRequestCreateOrderFormCustomerDownPaymentsRequestCreate
     * @request POST:/Sale/OrderFormCustomerDownPaymentsRequest/CreateOrderFormCustomerDownPaymentsRequest
     * @secure
     */
    orderFormCustomerDownPaymentsRequestCreateOrderFormCustomerDownPaymentsRequestCreate: (
      data: OrderFormCustomerDownPaymentsRequestRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDownPaymentsRequest/CreateOrderFormCustomerDownPaymentsRequest`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDownPaymentsRequest
     * @name OrderFormCustomerDownPaymentsRequestUpdateOrderFormCustomerDownPaymentsRequestUpdate
     * @request PUT:/Sale/OrderFormCustomerDownPaymentsRequest/UpdateOrderFormCustomerDownPaymentsRequest
     * @secure
     */
    orderFormCustomerDownPaymentsRequestUpdateOrderFormCustomerDownPaymentsRequestUpdate: (
      data: OrderFormCustomerDownPaymentsRequestRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDownPaymentsRequest/UpdateOrderFormCustomerDownPaymentsRequest`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerDownPaymentsRequest
     * @name OrderFormCustomerDownPaymentsRequestDeleteDelete
     * @request DELETE:/Sale/OrderFormCustomerDownPaymentsRequest/Delete
     * @secure
     */
    orderFormCustomerDownPaymentsRequestDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerDownPaymentsRequest/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerHistory
     * @name OrderFormCustomerHistoryGetOrderFormCustomerHistoryByOrderFormCustomerIdList
     * @request GET:/Sale/OrderFormCustomerHistory/GetOrderFormCustomerHistoryByOrderFormCustomerId
     * @secure
     */
    orderFormCustomerHistoryGetOrderFormCustomerHistoryByOrderFormCustomerIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerHistory/GetOrderFormCustomerHistoryByOrderFormCustomerId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerHistory
     * @name OrderFormCustomerHistoryGetOrderFormCustomerHistoryByIdList
     * @request GET:/Sale/OrderFormCustomerHistory/GetOrderFormCustomerHistoryById
     * @secure
     */
    orderFormCustomerHistoryGetOrderFormCustomerHistoryByIdList: (
      query?: {
        /** @format int32 */
        OrderFormCustomerHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerHistory/GetOrderFormCustomerHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerOption
     * @name OrderFormCustomerOptionGetOrderFormCustomerOptionByOrderFormCustomerIdList
     * @request GET:/Sale/OrderFormCustomerOption/GetOrderFormCustomerOptionByOrderFormCustomerId
     * @secure
     */
    orderFormCustomerOptionGetOrderFormCustomerOptionByOrderFormCustomerIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerOption/GetOrderFormCustomerOptionByOrderFormCustomerId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerOption
     * @name OrderFormCustomerOptionCreateOrderFormCustomerOptionCreate
     * @request POST:/Sale/OrderFormCustomerOption/CreateOrderFormCustomerOption
     * @secure
     */
    orderFormCustomerOptionCreateOrderFormCustomerOptionCreate: (
      data: OrderFormCustomerOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerOption/CreateOrderFormCustomerOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerOption
     * @name OrderFormCustomerOptionUpdateOrderFormCustomerOptionUpdate
     * @request PUT:/Sale/OrderFormCustomerOption/UpdateOrderFormCustomerOption
     * @secure
     */
    orderFormCustomerOptionUpdateOrderFormCustomerOptionUpdate: (
      data: OrderFormCustomerOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerOption/UpdateOrderFormCustomerOption`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerOptionDefault
     * @name OrderFormCustomerOptionDefaultGetDefaultList
     * @request GET:/Sale/OrderFormCustomerOptionDefault/GetDefault
     * @secure
     */
    orderFormCustomerOptionDefaultGetDefaultList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerOptionDefault/GetDefault`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerOptionDefault
     * @name OrderFormCustomerOptionDefaultCreateOrderFormCustomerOptionDefaultCreate
     * @request POST:/Sale/OrderFormCustomerOptionDefault/CreateOrderFormCustomerOptionDefault
     * @secure
     */
    orderFormCustomerOptionDefaultCreateOrderFormCustomerOptionDefaultCreate: (
      data: OrderFormCustomerOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerOptionDefault/CreateOrderFormCustomerOptionDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerOptionDefault
     * @name OrderFormCustomerOptionDefaultUpdateOrderFormCustomerOptionDefaultUpdate
     * @request PUT:/Sale/OrderFormCustomerOptionDefault/UpdateOrderFormCustomerOptionDefault
     * @secure
     */
    orderFormCustomerOptionDefaultUpdateOrderFormCustomerOptionDefaultUpdate: (
      data: OrderFormCustomerOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormCustomerOptionDefault/UpdateOrderFormCustomerOptionDefault`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormOption
     * @name OrderFormOptionGetOrderFormOptionByOrderFormIdList
     * @request GET:/Sale/OrderFormOption/GetOrderFormOptionByOrderFormId
     * @secure
     */
    orderFormOptionGetOrderFormOptionByOrderFormIdList: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormOption/GetOrderFormOptionByOrderFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormOption
     * @name OrderFormOptionCreateOrderFormOptionCreate
     * @request POST:/Sale/OrderFormOption/CreateOrderFormOption
     * @secure
     */
    orderFormOptionCreateOrderFormOptionCreate: (data: OrderFormOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormOption/CreateOrderFormOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormOption
     * @name OrderFormOptionUpdateOrderFormOptionUpdate
     * @request PUT:/Sale/OrderFormOption/UpdateOrderFormOption
     * @secure
     */
    orderFormOptionUpdateOrderFormOptionUpdate: (data: OrderFormOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormOption/UpdateOrderFormOption`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormOptionDefault
     * @name OrderFormOptionDefaultGetDefaultList
     * @request GET:/Sale/OrderFormOptionDefault/GetDefault
     * @secure
     */
    orderFormOptionDefaultGetDefaultList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/OrderFormOptionDefault/GetDefault`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormOptionDefault
     * @name OrderFormOptionDefaultCreateOrderFormOptionDefaultCreate
     * @request POST:/Sale/OrderFormOptionDefault/CreateOrderFormOptionDefault
     * @secure
     */
    orderFormOptionDefaultCreateOrderFormOptionDefaultCreate: (
      data: OrderFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormOptionDefault/CreateOrderFormOptionDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormOptionDefault
     * @name OrderFormOptionDefaultUpdateOrderFormOptionDefaultUpdate
     * @request PUT:/Sale/OrderFormOptionDefault/UpdateOrderFormOptionDefault
     * @secure
     */
    orderFormOptionDefaultUpdateOrderFormOptionDefaultUpdate: (
      data: OrderFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/OrderFormOptionDefault/UpdateOrderFormOptionDefault`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentGetPaymentByIdList
     * @request GET:/Sale/Payment/GetPaymentById
     * @secure
     */
    paymentGetPaymentByIdList: (
      query?: {
        /** @format int32 */
        paymentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/GetPaymentById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentGetPaymentsList
     * @request GET:/Sale/Payment/GetPayments
     * @secure
     */
    paymentGetPaymentsList: (
      query?: {
        /** @format int32 */
        invoiceId?: number;
        /** @format int32 */
        creditId?: number;
        /** @format int32 */
        bankAccountId?: number;
        /** @format date-time */
        startTo?: string;
        /** @format date-time */
        endTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/GetPayments`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentGetPaymentStatsList
     * @request GET:/Sale/Payment/GetPaymentStats
     * @secure
     */
    paymentGetPaymentStatsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        companyIdsSelected?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/GetPaymentStats`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentCreatePaymentCreate
     * @request POST:/Sale/Payment/CreatePayment
     * @secure
     */
    paymentCreatePaymentCreate: (data: PaymentRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Payment/CreatePayment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentUpdatePaymentUpdate
     * @request PUT:/Sale/Payment/UpdatePayment
     * @secure
     */
    paymentUpdatePaymentUpdate: (data: PaymentRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Payment/UpdatePayment`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentUpdateInvoicePaymentUpdate
     * @request PUT:/Sale/Payment/UpdateInvoicePayment
     * @secure
     */
    paymentUpdateInvoicePaymentUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        paymentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/UpdateInvoicePayment`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentUpdateCreditPaymentUpdate
     * @request PUT:/Sale/Payment/UpdateCreditPayment
     * @secure
     */
    paymentUpdateCreditPaymentUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        paymentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/UpdateCreditPayment`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentUpdateHoldbackPaymentUpdate
     * @request PUT:/Sale/Payment/UpdateHoldbackPayment
     * @secure
     */
    paymentUpdateHoldbackPaymentUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        paymentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/UpdateHoldbackPayment`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentUnlinkCreditPaymentUpdate
     * @request PUT:/Sale/Payment/UnlinkCreditPayment
     * @secure
     */
    paymentUnlinkCreditPaymentUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        paymentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/UnlinkCreditPayment`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentUnlinkInvoicePaymentUpdate
     * @request PUT:/Sale/Payment/UnlinkInvoicePayment
     * @secure
     */
    paymentUnlinkInvoicePaymentUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        paymentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/UnlinkInvoicePayment`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentUnlinkHoldbackPaymentUpdate
     * @request PUT:/Sale/Payment/UnlinkHoldbackPayment
     * @secure
     */
    paymentUnlinkHoldbackPaymentUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        paymentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Payment/UnlinkHoldbackPayment`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentRestoreUpdate
     * @request PUT:/Sale/Payment/Restore
     * @secure
     */
    paymentRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Payment/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentArchiveDelete
     * @request DELETE:/Sale/Payment/Archive
     * @secure
     */
    paymentArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Payment/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentDeleteDelete
     * @request DELETE:/Sale/Payment/Delete
     * @secure
     */
    paymentDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Payment/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentCondition
     * @name PaymentConditionGetPaymentConditionByWorkspaceIdList
     * @request GET:/Sale/PaymentCondition/GetPaymentConditionByWorkspaceId
     * @secure
     */
    paymentConditionGetPaymentConditionByWorkspaceIdList: (
      query?: {
        /** @format int32 */
        workspaceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/PaymentCondition/GetPaymentConditionByWorkspaceId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentCondition
     * @name PaymentConditionGetPaymentConditionByIdList
     * @request GET:/Sale/PaymentCondition/GetPaymentConditionById
     * @secure
     */
    paymentConditionGetPaymentConditionByIdList: (
      query?: {
        /** @format int32 */
        paymentConditionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/PaymentCondition/GetPaymentConditionById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentCondition
     * @name PaymentConditionCreatePaymentConditionCreate
     * @request POST:/Sale/PaymentCondition/CreatePaymentCondition
     * @secure
     */
    paymentConditionCreatePaymentConditionCreate: (data: PaymentConditionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentCondition/CreatePaymentCondition`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentCondition
     * @name PaymentConditionUpdatePaymentConditionUpdate
     * @request PUT:/Sale/PaymentCondition/UpdatePaymentCondition
     * @secure
     */
    paymentConditionUpdatePaymentConditionUpdate: (data: PaymentConditionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentCondition/UpdatePaymentCondition`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentCondition
     * @name PaymentConditionRestoreUpdate
     * @request PUT:/Sale/PaymentCondition/Restore
     * @secure
     */
    paymentConditionRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentCondition/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentCondition
     * @name PaymentConditionArchiveDelete
     * @request DELETE:/Sale/PaymentCondition/Archive
     * @secure
     */
    paymentConditionArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentCondition/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentCondition
     * @name PaymentConditionDeleteDelete
     * @request DELETE:/Sale/PaymentCondition/Delete
     * @secure
     */
    paymentConditionDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentCondition/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethod
     * @name PaymentMethodGetPaymentMethodByWorkspaceIdList
     * @request GET:/Sale/PaymentMethod/GetPaymentMethodByWorkspaceId
     * @secure
     */
    paymentMethodGetPaymentMethodByWorkspaceIdList: (
      query?: {
        /** @format int32 */
        workspaceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/PaymentMethod/GetPaymentMethodByWorkspaceId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethod
     * @name PaymentMethodGetPaymentMethodByIdList
     * @request GET:/Sale/PaymentMethod/GetPaymentMethodById
     * @secure
     */
    paymentMethodGetPaymentMethodByIdList: (
      query?: {
        /** @format int32 */
        paymentMethodId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/PaymentMethod/GetPaymentMethodById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethod
     * @name PaymentMethodCreatePaymentMethodCreate
     * @request POST:/Sale/PaymentMethod/CreatePaymentMethod
     * @secure
     */
    paymentMethodCreatePaymentMethodCreate: (data: PaymentMethodRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentMethod/CreatePaymentMethod`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethod
     * @name PaymentMethodUpdatePaymentMethodUpdate
     * @request PUT:/Sale/PaymentMethod/UpdatePaymentMethod
     * @secure
     */
    paymentMethodUpdatePaymentMethodUpdate: (data: PaymentMethodRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentMethod/UpdatePaymentMethod`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethod
     * @name PaymentMethodRestoreUpdate
     * @request PUT:/Sale/PaymentMethod/Restore
     * @secure
     */
    paymentMethodRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentMethod/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethod
     * @name PaymentMethodArchiveDelete
     * @request DELETE:/Sale/PaymentMethod/Archive
     * @secure
     */
    paymentMethodArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentMethod/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentMethod
     * @name PaymentMethodDeleteDelete
     * @request DELETE:/Sale/PaymentMethod/Delete
     * @secure
     */
    paymentMethodDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/PaymentMethod/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductGetProductsList
     * @request GET:/Sale/Product/GetProducts
     * @secure
     */
    productGetProductsList: (
      query?: {
        productTypes?: ProductType[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Product/GetProducts`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductGetProductByIdList
     * @request GET:/Sale/Product/GetProductById
     * @secure
     */
    productGetProductByIdList: (
      query?: {
        /** @format int32 */
        productId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Product/GetProductById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductGetProductsArchivedList
     * @request GET:/Sale/Product/GetProductsArchived
     * @secure
     */
    productGetProductsArchivedList: (
      query?: {
        productTypes?: ProductType[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Product/GetProductsArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductGetProductArchivedByIdList
     * @request GET:/Sale/Product/GetProductArchivedById
     * @secure
     */
    productGetProductArchivedByIdList: (
      query?: {
        /** @format int32 */
        productId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Product/GetProductArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductGetProductsFormatedForInventoryList
     * @request GET:/Sale/Product/GetProductsFormatedForInventory
     * @secure
     */
    productGetProductsFormatedForInventoryList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Product/GetProductsFormatedForInventory`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductCreateProductCreate
     * @request POST:/Sale/Product/CreateProduct
     * @secure
     */
    productCreateProductCreate: (data: ProductRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Product/CreateProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductUpdateProductUpdate
     * @request PUT:/Sale/Product/UpdateProduct
     * @secure
     */
    productUpdateProductUpdate: (data: ProductRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Product/UpdateProduct`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductUpdateProductProductCategoryUpdate
     * @request PUT:/Sale/Product/UpdateProductProductCategory
     * @secure
     */
    productUpdateProductProductCategoryUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        productId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Product/UpdateProductProductCategory`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductUploadProductImageUpdate
     * @request PUT:/Sale/Product/UploadProductImage
     * @secure
     */
    productUploadProductImageUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        productId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Product/UploadProductImage`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductUploadFileUpdate
     * @request PUT:/Sale/Product/UploadFile
     * @secure
     */
    productUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        productId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Product/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductRestoreUpdate
     * @request PUT:/Sale/Product/Restore
     * @secure
     */
    productRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Product/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductArchiveDelete
     * @request DELETE:/Sale/Product/Archive
     * @secure
     */
    productArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Product/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductDeleteDelete
     * @request DELETE:/Sale/Product/Delete
     * @secure
     */
    productDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Product/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryGetProductCategoriesTreeList
     * @request GET:/Sale/ProductCategory/GetProductCategoriesTree
     * @secure
     */
    productCategoryGetProductCategoriesTreeList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/GetProductCategoriesTree`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryGetProductCategoriesList
     * @request GET:/Sale/ProductCategory/GetProductCategories
     * @secure
     */
    productCategoryGetProductCategoriesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/GetProductCategories`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryGetProductCategoriesArchivedList
     * @request GET:/Sale/ProductCategory/GetProductCategoriesArchived
     * @secure
     */
    productCategoryGetProductCategoriesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/GetProductCategoriesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryGetProductCategoryByIdList
     * @request GET:/Sale/ProductCategory/GetProductCategoryById
     * @secure
     */
    productCategoryGetProductCategoryByIdList: (
      query?: {
        /** @format int32 */
        productCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/GetProductCategoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryGetProductCategoryArchivedByIdList
     * @request GET:/Sale/ProductCategory/GetProductCategoryArchivedById
     * @secure
     */
    productCategoryGetProductCategoryArchivedByIdList: (
      query?: {
        /** @format int32 */
        productCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/GetProductCategoryArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryCreateProductCategoryCreate
     * @request POST:/Sale/ProductCategory/CreateProductCategory
     * @secure
     */
    productCategoryCreateProductCategoryCreate: (data: ProductCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/CreateProductCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryUpdateProductCategoryUpdate
     * @request PUT:/Sale/ProductCategory/UpdateProductCategory
     * @secure
     */
    productCategoryUpdateProductCategoryUpdate: (data: ProductCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/UpdateProductCategory`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryRestoreUpdate
     * @request PUT:/Sale/ProductCategory/Restore
     * @secure
     */
    productCategoryRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryArchiveDelete
     * @request DELETE:/Sale/ProductCategory/Archive
     * @secure
     */
    productCategoryArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCategory
     * @name ProductCategoryDeleteDelete
     * @request DELETE:/Sale/ProductCategory/Delete
     * @secure
     */
    productCategoryDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ProductCategory/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteGetQuotesList
     * @request GET:/Sale/Quote/GetQuotes
     * @secure
     */
    quoteGetQuotesList: (
      query?: {
        isSigned?: boolean;
        companyIds?: number[];
        status?: DocumentStatus[];
        /** @format int32 */
        affairId?: number;
        isTemplate?: boolean;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/GetQuotes`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteGetQuoteByIdList
     * @request GET:/Sale/Quote/GetQuoteById
     * @secure
     */
    quoteGetQuoteByIdList: (
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/GetQuoteById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteGetInitialQuoteByIdList
     * @request GET:/Sale/Quote/GetInitialQuoteById
     * @secure
     */
    quoteGetInitialQuoteByIdList: (
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/GetInitialQuoteById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteGetLastDocumentReferenceList
     * @request GET:/Sale/Quote/GetLastDocumentReference
     * @secure
     */
    quoteGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Quote/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteGetQuoteEmptyList
     * @request GET:/Sale/Quote/GetQuoteEmpty
     * @secure
     */
    quoteGetQuoteEmptyList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        companyId?: number;
        /** @format int32 */
        userId?: number;
        type?: DocumentType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/GetQuoteEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteGetQuoteStatsList
     * @request GET:/Sale/Quote/GetQuoteStats
     * @secure
     */
    quoteGetQuoteStatsList: (
      data: number[],
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        isSigned?: boolean;
        isTemplate?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/GetQuoteStats`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteCreateQuoteCreate
     * @request POST:/Sale/Quote/CreateQuote
     * @secure
     */
    quoteCreateQuoteCreate: (data: QuoteRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Quote/CreateQuote`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteDuplicateQuoteCreate
     * @request POST:/Sale/Quote/DuplicateQuote
     * @secure
     */
    quoteDuplicateQuoteCreate: (
      query?: {
        /** @format int32 */
        quoteId?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/DuplicateQuote`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteUpdateQuoteUpdate
     * @request PUT:/Sale/Quote/UpdateQuote
     * @secure
     */
    quoteUpdateQuoteUpdate: (data: QuoteRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Quote/UpdateQuote`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteUpdatePaymentMethodQuoteUpdate
     * @request PUT:/Sale/Quote/UpdatePaymentMethodQuote
     * @secure
     */
    quoteUpdatePaymentMethodQuoteUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/UpdatePaymentMethodQuote`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteUploadPathDocumentUpdate
     * @request PUT:/Sale/Quote/UploadPathDocument
     * @secure
     */
    quoteUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        quoteId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteUploadFileUpdate
     * @request PUT:/Sale/Quote/UploadFile
     * @secure
     */
    quoteUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Quote/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteUpdateQuoteTagQuoteUpdate
     * @request PUT:/Sale/Quote/UpdateQuoteTagQuote
     * @secure
     */
    quoteUpdateQuoteTagQuoteUpdate: (data: QuoteTagQuoteRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Quote/UpdateQuoteTagQuote`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quote
     * @name QuoteDeleteDelete
     * @request DELETE:/Sale/Quote/Delete
     * @secure
     */
    quoteDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Quote/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteDownPaymentsRequest
     * @name QuoteDownPaymentsRequestGetQuoteDownPaymentsRequestByIdList
     * @request GET:/Sale/QuoteDownPaymentsRequest/GetQuoteDownPaymentsRequestById
     * @secure
     */
    quoteDownPaymentsRequestGetQuoteDownPaymentsRequestByIdList: (
      query?: {
        /** @format int32 */
        quoteDownPaymentsRequestId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/QuoteDownPaymentsRequest/GetQuoteDownPaymentsRequestById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteDownPaymentsRequest
     * @name QuoteDownPaymentsRequestGetQuoteDownPaymentsRequestByQuoteIdList
     * @request GET:/Sale/QuoteDownPaymentsRequest/GetQuoteDownPaymentsRequestByQuoteId
     * @secure
     */
    quoteDownPaymentsRequestGetQuoteDownPaymentsRequestByQuoteIdList: (
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/QuoteDownPaymentsRequest/GetQuoteDownPaymentsRequestByQuoteId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteDownPaymentsRequest
     * @name QuoteDownPaymentsRequestCreateQuoteDownPaymentsRequestCreate
     * @request POST:/Sale/QuoteDownPaymentsRequest/CreateQuoteDownPaymentsRequest
     * @secure
     */
    quoteDownPaymentsRequestCreateQuoteDownPaymentsRequestCreate: (
      data: QuoteDownPaymentsRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/QuoteDownPaymentsRequest/CreateQuoteDownPaymentsRequest`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteDownPaymentsRequest
     * @name QuoteDownPaymentsRequestUpdateQuoteDownPaymentsRequestUpdate
     * @request PUT:/Sale/QuoteDownPaymentsRequest/UpdateQuoteDownPaymentsRequest
     * @secure
     */
    quoteDownPaymentsRequestUpdateQuoteDownPaymentsRequestUpdate: (
      data: QuoteDownPaymentsRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/QuoteDownPaymentsRequest/UpdateQuoteDownPaymentsRequest`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteDownPaymentsRequest
     * @name QuoteDownPaymentsRequestDeleteDelete
     * @request DELETE:/Sale/QuoteDownPaymentsRequest/Delete
     * @secure
     */
    quoteDownPaymentsRequestDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/QuoteDownPaymentsRequest/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteHistory
     * @name QuoteHistoryGetQuoteHistoryByQuoteIdList
     * @request GET:/Sale/QuoteHistory/GetQuoteHistoryByQuoteId
     * @secure
     */
    quoteHistoryGetQuoteHistoryByQuoteIdList: (
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/QuoteHistory/GetQuoteHistoryByQuoteId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteHistory
     * @name QuoteHistoryGetQuoteHistoryByIdList
     * @request GET:/Sale/QuoteHistory/GetQuoteHistoryById
     * @secure
     */
    quoteHistoryGetQuoteHistoryByIdList: (
      query?: {
        /** @format int32 */
        QuoteHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/QuoteHistory/GetQuoteHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteOption
     * @name QuoteOptionGetQuoteOptionByQuoteIdList
     * @request GET:/Sale/QuoteOption/GetQuoteOptionByQuoteId
     * @secure
     */
    quoteOptionGetQuoteOptionByQuoteIdList: (
      query?: {
        /** @format int32 */
        quoteId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/QuoteOption/GetQuoteOptionByQuoteId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteOption
     * @name QuoteOptionCreateQuoteOptionCreate
     * @request POST:/Sale/QuoteOption/CreateQuoteOption
     * @secure
     */
    quoteOptionCreateQuoteOptionCreate: (data: QuoteOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/QuoteOption/CreateQuoteOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteOption
     * @name QuoteOptionUpdateQuoteOptionUpdate
     * @request PUT:/Sale/QuoteOption/UpdateQuoteOption
     * @secure
     */
    quoteOptionUpdateQuoteOptionUpdate: (data: QuoteOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/QuoteOption/UpdateQuoteOption`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteOptionDefault
     * @name QuoteOptionDefaultGetDefaultList
     * @request GET:/Sale/QuoteOptionDefault/GetDefault
     * @secure
     */
    quoteOptionDefaultGetDefaultList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/QuoteOptionDefault/GetDefault`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteOptionDefault
     * @name QuoteOptionDefaultCreateQuoteOptionDefaultCreate
     * @request POST:/Sale/QuoteOptionDefault/CreateQuoteOptionDefault
     * @secure
     */
    quoteOptionDefaultCreateQuoteOptionDefaultCreate: (data: QuoteOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/QuoteOptionDefault/CreateQuoteOptionDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteOptionDefault
     * @name QuoteOptionDefaultUpdateQuoteOptionDefaultUpdate
     * @request PUT:/Sale/QuoteOptionDefault/UpdateQuoteOptionDefault
     * @secure
     */
    quoteOptionDefaultUpdateQuoteOptionDefaultUpdate: (data: QuoteOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/QuoteOptionDefault/UpdateQuoteOptionDefault`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormOption
     * @name ReceiptFormOptionGetReceiptFormOptionByReceiptFormIdList
     * @request GET:/Sale/ReceiptFormOption/GetReceiptFormOptionByReceiptFormId
     * @secure
     */
    receiptFormOptionGetReceiptFormOptionByReceiptFormIdList: (
      query?: {
        /** @format int32 */
        receiptFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/ReceiptFormOption/GetReceiptFormOptionByReceiptFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormOption
     * @name ReceiptFormOptionCreateReceiptFormOptionCreate
     * @request POST:/Sale/ReceiptFormOption/CreateReceiptFormOption
     * @secure
     */
    receiptFormOptionCreateReceiptFormOptionCreate: (data: ReceiptFormOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ReceiptFormOption/CreateReceiptFormOption`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormOption
     * @name ReceiptFormOptionUpdateReceiptFormOptionUpdate
     * @request PUT:/Sale/ReceiptFormOption/UpdateReceiptFormOption
     * @secure
     */
    receiptFormOptionUpdateReceiptFormOptionUpdate: (data: ReceiptFormOptionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ReceiptFormOption/UpdateReceiptFormOption`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormOptionDefault
     * @name ReceiptFormOptionDefaultGetDefaultList
     * @request GET:/Sale/ReceiptFormOptionDefault/GetDefault
     * @secure
     */
    receiptFormOptionDefaultGetDefaultList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ReceiptFormOptionDefault/GetDefault`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormOptionDefault
     * @name ReceiptFormOptionDefaultCreateReceiptFormOptionDefaultCreate
     * @request POST:/Sale/ReceiptFormOptionDefault/CreateReceiptFormOptionDefault
     * @secure
     */
    receiptFormOptionDefaultCreateReceiptFormOptionDefaultCreate: (
      data: ReceiptFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/ReceiptFormOptionDefault/CreateReceiptFormOptionDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormOptionDefault
     * @name ReceiptFormOptionDefaultUpdateReceiptFormOptionDefaultUpdate
     * @request PUT:/Sale/ReceiptFormOptionDefault/UpdateReceiptFormOptionDefault
     * @secure
     */
    receiptFormOptionDefaultUpdateReceiptFormOptionDefaultUpdate: (
      data: ReceiptFormOptionRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/ReceiptFormOptionDefault/UpdateReceiptFormOptionDefault`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferencielTVA
     * @name ReferencielTvaGetReferencielTvAsList
     * @request GET:/Sale/ReferencielTVA/GetReferencielTVAs
     * @secure
     */
    referencielTvaGetReferencielTvAsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/ReferencielTVA/GetReferencielTVAs`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReferencielTVA
     * @name ReferencielTvaGetReferencielTvaByIdList
     * @request GET:/Sale/ReferencielTVA/GetReferencielTVAById
     * @secure
     */
    referencielTvaGetReferencielTvaByIdList: (
      query?: {
        /** @format int32 */
        referencielTVAId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/ReferencielTVA/GetReferencielTVAById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryGetSaleCategoriesList
     * @request GET:/Sale/SaleCategory/GetSaleCategories
     * @secure
     */
    saleCategoryGetSaleCategoriesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/GetSaleCategories`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryGetSaleCategoryByIdList
     * @request GET:/Sale/SaleCategory/GetSaleCategoryById
     * @secure
     */
    saleCategoryGetSaleCategoryByIdList: (
      query?: {
        /** @format int32 */
        saleCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/GetSaleCategoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryGetSaleCategoriesTreeList
     * @request GET:/Sale/SaleCategory/GetSaleCategoriesTree
     * @secure
     */
    saleCategoryGetSaleCategoriesTreeList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/GetSaleCategoriesTree`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryGetSaleCategoriesArchivedList
     * @request GET:/Sale/SaleCategory/GetSaleCategoriesArchived
     * @secure
     */
    saleCategoryGetSaleCategoriesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/GetSaleCategoriesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryGetSaleCategoryArchivedByIdList
     * @request GET:/Sale/SaleCategory/GetSaleCategoryArchivedById
     * @secure
     */
    saleCategoryGetSaleCategoryArchivedByIdList: (
      query?: {
        /** @format int32 */
        saleCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/GetSaleCategoryArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryCreateSaleCategoryCreate
     * @request POST:/Sale/SaleCategory/CreateSaleCategory
     * @secure
     */
    saleCategoryCreateSaleCategoryCreate: (data: SaleCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/CreateSaleCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryUpdateSaleCategoryUpdate
     * @request PUT:/Sale/SaleCategory/UpdateSaleCategory
     * @secure
     */
    saleCategoryUpdateSaleCategoryUpdate: (data: SaleCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/UpdateSaleCategory`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryRestoreUpdate
     * @request PUT:/Sale/SaleCategory/Restore
     * @secure
     */
    saleCategoryRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryArchiveDelete
     * @request DELETE:/Sale/SaleCategory/Archive
     * @secure
     */
    saleCategoryArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SaleCategory
     * @name SaleCategoryDeleteDelete
     * @request DELETE:/Sale/SaleCategory/Delete
     * @secure
     */
    saleCategoryDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/SaleCategory/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TermsAndConditions
     * @name TermsAndConditionsGetTermsAndConditionsByInstitutionSettingIdList
     * @request GET:/Sale/TermsAndConditions/GetTermsAndConditionsByInstitutionSettingId
     * @secure
     */
    termsAndConditionsGetTermsAndConditionsByInstitutionSettingIdList: (
      query?: {
        /** @format int32 */
        institutionSettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/TermsAndConditions/GetTermsAndConditionsByInstitutionSettingId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TermsAndConditions
     * @name TermsAndConditionsGetTermsAndConditionsByIdList
     * @request GET:/Sale/TermsAndConditions/GetTermsAndConditionsById
     * @secure
     */
    termsAndConditionsGetTermsAndConditionsByIdList: (
      query?: {
        /** @format int32 */
        termsAndConditionsId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/TermsAndConditions/GetTermsAndConditionsById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TermsAndConditions
     * @name TermsAndConditionsCreateTermsAndConditionsCreate
     * @request POST:/Sale/TermsAndConditions/CreateTermsAndConditions
     * @secure
     */
    termsAndConditionsCreateTermsAndConditionsCreate: (
      data: TermsAndConditionsRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/TermsAndConditions/CreateTermsAndConditions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TermsAndConditions
     * @name TermsAndConditionsUpdateTermsAndConditionsUpdate
     * @request PUT:/Sale/TermsAndConditions/UpdateTermsAndConditions
     * @secure
     */
    termsAndConditionsUpdateTermsAndConditionsUpdate: (
      data: TermsAndConditionsRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/TermsAndConditions/UpdateTermsAndConditions`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TermsAndConditions
     * @name TermsAndConditionsDeleteDelete
     * @request DELETE:/Sale/TermsAndConditions/Delete
     * @secure
     */
    termsAndConditionsDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/TermsAndConditions/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitGetUnitsList
     * @request GET:/Sale/Unit/GetUnits
     * @secure
     */
    unitGetUnitsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/Unit/GetUnits`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Unit
     * @name UnitGetUnitByIdList
     * @request GET:/Sale/Unit/GetUnitById
     * @secure
     */
    unitGetUnitByIdList: (
      query?: {
        /** @format int32 */
        UnitId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/Unit/GetUnitById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointGetWasteCollectionPointsList
     * @request GET:/Sale/WasteCollectionPoint/GetWasteCollectionPoints
     * @secure
     */
    wasteCollectionPointGetWasteCollectionPointsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/GetWasteCollectionPoints`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointGetWasteCollectionPointByIdList
     * @request GET:/Sale/WasteCollectionPoint/GetWasteCollectionPointById
     * @secure
     */
    wasteCollectionPointGetWasteCollectionPointByIdList: (
      query?: {
        /** @format int32 */
        wasteCollectionPointId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/GetWasteCollectionPointById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointGetWasteCollectionPointsArchivedList
     * @request GET:/Sale/WasteCollectionPoint/GetWasteCollectionPointsArchived
     * @secure
     */
    wasteCollectionPointGetWasteCollectionPointsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/GetWasteCollectionPointsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointGetWasteCollectionPointArchivedByIdList
     * @request GET:/Sale/WasteCollectionPoint/GetWasteCollectionPointArchivedById
     * @secure
     */
    wasteCollectionPointGetWasteCollectionPointArchivedByIdList: (
      query?: {
        /** @format int32 */
        wasteCollectionPointArchivedId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/GetWasteCollectionPointArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointCreateWasteCollectionPointCreate
     * @request POST:/Sale/WasteCollectionPoint/CreateWasteCollectionPoint
     * @secure
     */
    wasteCollectionPointCreateWasteCollectionPointCreate: (
      data: WasteCollectionPointRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/CreateWasteCollectionPoint`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointUpdateWasteCollectionPointUpdate
     * @request PUT:/Sale/WasteCollectionPoint/UpdateWasteCollectionPoint
     * @secure
     */
    wasteCollectionPointUpdateWasteCollectionPointUpdate: (
      data: WasteCollectionPointRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/UpdateWasteCollectionPoint`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointRestoreUpdate
     * @request PUT:/Sale/WasteCollectionPoint/Restore
     * @secure
     */
    wasteCollectionPointRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointArchiveDelete
     * @request DELETE:/Sale/WasteCollectionPoint/Archive
     * @secure
     */
    wasteCollectionPointArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPoint
     * @name WasteCollectionPointDeleteDelete
     * @request DELETE:/Sale/WasteCollectionPoint/Delete
     * @secure
     */
    wasteCollectionPointDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPoint/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPointType
     * @name WasteCollectionPointTypeGetWasteCollectionPointTypesList
     * @request GET:/Sale/WasteCollectionPointType/GetWasteCollectionPointTypes
     * @secure
     */
    wasteCollectionPointTypeGetWasteCollectionPointTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPointType/GetWasteCollectionPointTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteCollectionPointType
     * @name WasteCollectionPointTypeGetWasteCollectionPointTypeByIdList
     * @request GET:/Sale/WasteCollectionPointType/GetWasteCollectionPointTypeById
     * @secure
     */
    wasteCollectionPointTypeGetWasteCollectionPointTypeByIdList: (
      query?: {
        /** @format int32 */
        wasteCollectionPointTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/WasteCollectionPointType/GetWasteCollectionPointTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteType
     * @name WasteTypeGetWasteTypesList
     * @request GET:/Sale/WasteType/GetWasteTypes
     * @secure
     */
    wasteTypeGetWasteTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Sale/WasteType/GetWasteTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WasteType
     * @name WasteTypeGetWasteTypeByIdList
     * @request GET:/Sale/WasteType/GetWasteTypeById
     * @secure
     */
    wasteTypeGetWasteTypeByIdList: (
      query?: {
        /** @format int32 */
        wasteTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Sale/WasteType/GetWasteTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  parameters = {
    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetContractsList
     * @request GET:/Parameters/Contract/GetContracts
     * @secure
     */
    contractGetContractsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Contract/GetContracts`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetContractByIdList
     * @request GET:/Parameters/Contract/GetContractById
     * @secure
     */
    contractGetContractByIdList: (
      query?: {
        /** @format int32 */
        contractId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/Contract/GetContractById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetContractsArchivedList
     * @request GET:/Parameters/Contract/GetContractsArchived
     * @secure
     */
    contractGetContractsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Contract/GetContractsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractGetContractArchivedByIdList
     * @request GET:/Parameters/Contract/GetContractArchivedById
     * @secure
     */
    contractGetContractArchivedByIdList: (
      query?: {
        /** @format int32 */
        contractId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/Contract/GetContractArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractCreateContractCreate
     * @request POST:/Parameters/Contract/CreateContract
     * @secure
     */
    contractCreateContractCreate: (data: ContractRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Contract/CreateContract`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractUpdateContractUpdate
     * @request PUT:/Parameters/Contract/UpdateContract
     * @secure
     */
    contractUpdateContractUpdate: (data: ContractRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Contract/UpdateContract`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractRestoreUpdate
     * @request PUT:/Parameters/Contract/Restore
     * @secure
     */
    contractRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Contract/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractArchiveDelete
     * @request DELETE:/Parameters/Contract/Archive
     * @secure
     */
    contractArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Contract/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contract
     * @name ContractDeleteDelete
     * @request DELETE:/Parameters/Contract/Delete
     * @secure
     */
    contractDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Contract/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailGetContractDetailsList
     * @request GET:/Parameters/ContractDetail/GetContractDetails
     * @secure
     */
    contractDetailGetContractDetailsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/GetContractDetails`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailGetContractDetailByIdList
     * @request GET:/Parameters/ContractDetail/GetContractDetailById
     * @secure
     */
    contractDetailGetContractDetailByIdList: (
      query?: {
        /** @format int32 */
        contractDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/GetContractDetailById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailGetContractDetailsArchivedList
     * @request GET:/Parameters/ContractDetail/GetContractDetailsArchived
     * @secure
     */
    contractDetailGetContractDetailsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/GetContractDetailsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailGetContractDetailArchivedByIdList
     * @request GET:/Parameters/ContractDetail/GetContractDetailArchivedById
     * @secure
     */
    contractDetailGetContractDetailArchivedByIdList: (
      query?: {
        /** @format int32 */
        contractDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/GetContractDetailArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailCreateContractDetailCreate
     * @request POST:/Parameters/ContractDetail/CreateContractDetail
     * @secure
     */
    contractDetailCreateContractDetailCreate: (data: ContractDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/CreateContractDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailUpdateContractDetailUpdate
     * @request PUT:/Parameters/ContractDetail/UpdateContractDetail
     * @secure
     */
    contractDetailUpdateContractDetailUpdate: (data: ContractDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/UpdateContractDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailRestoreUpdate
     * @request PUT:/Parameters/ContractDetail/Restore
     * @secure
     */
    contractDetailRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailArchiveDelete
     * @request DELETE:/Parameters/ContractDetail/Archive
     * @secure
     */
    contractDetailArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContractDetail
     * @name ContractDetailDeleteDelete
     * @request DELETE:/Parameters/ContractDetail/Delete
     * @secure
     */
    contractDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/ContractDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateGetCustomEmailTemplatesList
     * @request GET:/Parameters/CustomEmailTemplate/GetCustomEmailTemplates
     * @secure
     */
    customEmailTemplateGetCustomEmailTemplatesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/GetCustomEmailTemplates`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateGetCustomEmailTemplateByIdList
     * @request GET:/Parameters/CustomEmailTemplate/GetCustomEmailTemplateById
     * @secure
     */
    customEmailTemplateGetCustomEmailTemplateByIdList: (
      query?: {
        /** @format int32 */
        customemailtemplateId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/GetCustomEmailTemplateById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateGetCustomEmailTemplatesArchivedList
     * @request GET:/Parameters/CustomEmailTemplate/GetCustomEmailTemplatesArchived
     * @secure
     */
    customEmailTemplateGetCustomEmailTemplatesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/GetCustomEmailTemplatesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateGetCustomEmailTemplateArchivedByIdList
     * @request GET:/Parameters/CustomEmailTemplate/GetCustomEmailTemplateArchivedById
     * @secure
     */
    customEmailTemplateGetCustomEmailTemplateArchivedByIdList: (
      query?: {
        /** @format int32 */
        customemailtemplateId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/GetCustomEmailTemplateArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateCreateCustomEmailTemplateCreate
     * @request POST:/Parameters/CustomEmailTemplate/CreateCustomEmailTemplate
     * @secure
     */
    customEmailTemplateCreateCustomEmailTemplateCreate: (
      data: CustomEmailTemplateRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/CreateCustomEmailTemplate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateUpdateCustomEmailTemplateUpdate
     * @request PUT:/Parameters/CustomEmailTemplate/UpdateCustomEmailTemplate
     * @secure
     */
    customEmailTemplateUpdateCustomEmailTemplateUpdate: (
      data: CustomEmailTemplateRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/UpdateCustomEmailTemplate`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateRestoreUpdate
     * @request PUT:/Parameters/CustomEmailTemplate/Restore
     * @secure
     */
    customEmailTemplateRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateArchiveDelete
     * @request DELETE:/Parameters/CustomEmailTemplate/Archive
     * @secure
     */
    customEmailTemplateArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomEmailTemplate
     * @name CustomEmailTemplateDeleteDelete
     * @request DELETE:/Parameters/CustomEmailTemplate/Delete
     * @secure
     */
    customEmailTemplateDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomEmailTemplate/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldGetCustomFieldsList
     * @request GET:/Parameters/CustomField/GetCustomFields
     * @secure
     */
    customFieldGetCustomFieldsList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        interventionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/GetCustomFields`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldGetCustomFieldByIdList
     * @request GET:/Parameters/CustomField/GetCustomFieldById
     * @secure
     */
    customFieldGetCustomFieldByIdList: (
      query?: {
        /** @format int32 */
        customFieldId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/GetCustomFieldById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldGetCustomFieldsArchivedList
     * @request GET:/Parameters/CustomField/GetCustomFieldsArchived
     * @secure
     */
    customFieldGetCustomFieldsArchivedList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        interventionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/GetCustomFieldsArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldGetCustomFieldArchivedByIdList
     * @request GET:/Parameters/CustomField/GetCustomFieldArchivedById
     * @secure
     */
    customFieldGetCustomFieldArchivedByIdList: (
      query?: {
        /** @format int32 */
        customFieldId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/GetCustomFieldArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldCreateCustomFieldCreate
     * @request POST:/Parameters/CustomField/CreateCustomField
     * @secure
     */
    customFieldCreateCustomFieldCreate: (data: CustomFieldRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/CreateCustomField`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldUpdateCustomFieldUpdate
     * @request PUT:/Parameters/CustomField/UpdateCustomField
     * @secure
     */
    customFieldUpdateCustomFieldUpdate: (data: CustomFieldRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/UpdateCustomField`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldRestoreUpdate
     * @request PUT:/Parameters/CustomField/Restore
     * @secure
     */
    customFieldRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldArchiveDelete
     * @request DELETE:/Parameters/CustomField/Archive
     * @secure
     */
    customFieldArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomField
     * @name CustomFieldDeleteDelete
     * @request DELETE:/Parameters/CustomField/Delete
     * @secure
     */
    customFieldDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomField/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupGetCustomFieldGroupsList
     * @request GET:/Parameters/CustomFieldGroup/GetCustomFieldGroups
     * @secure
     */
    customFieldGroupGetCustomFieldGroupsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/GetCustomFieldGroups`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupGetCustomFieldGroupByIdList
     * @request GET:/Parameters/CustomFieldGroup/GetCustomFieldGroupById
     * @secure
     */
    customFieldGroupGetCustomFieldGroupByIdList: (
      query?: {
        /** @format int32 */
        customFieldGroupId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/GetCustomFieldGroupById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupGetCustomFieldGroupsArchivedList
     * @request GET:/Parameters/CustomFieldGroup/GetCustomFieldGroupsArchived
     * @secure
     */
    customFieldGroupGetCustomFieldGroupsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/GetCustomFieldGroupsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupGetCustomFieldGroupArchivedByIdList
     * @request GET:/Parameters/CustomFieldGroup/GetCustomFieldGroupArchivedById
     * @secure
     */
    customFieldGroupGetCustomFieldGroupArchivedByIdList: (
      query?: {
        /** @format int32 */
        customFieldGroupId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/GetCustomFieldGroupArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupCreateCustomFieldGroupCreate
     * @request POST:/Parameters/CustomFieldGroup/CreateCustomFieldGroup
     * @secure
     */
    customFieldGroupCreateCustomFieldGroupCreate: (data: CustomFieldGroupRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/CreateCustomFieldGroup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupUpdateCustomFieldGroupUpdate
     * @request PUT:/Parameters/CustomFieldGroup/UpdateCustomFieldGroup
     * @secure
     */
    customFieldGroupUpdateCustomFieldGroupUpdate: (data: CustomFieldGroupRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/UpdateCustomFieldGroup`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupRestoreUpdate
     * @request PUT:/Parameters/CustomFieldGroup/Restore
     * @secure
     */
    customFieldGroupRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupArchiveDelete
     * @request DELETE:/Parameters/CustomFieldGroup/Archive
     * @secure
     */
    customFieldGroupArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldGroup
     * @name CustomFieldGroupDeleteDelete
     * @request DELETE:/Parameters/CustomFieldGroup/Delete
     * @secure
     */
    customFieldGroupDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldGroup/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateGetCustomFieldTemplatesList
     * @request GET:/Parameters/CustomFieldTemplate/GetCustomFieldTemplates
     * @secure
     */
    customFieldTemplateGetCustomFieldTemplatesList: (
      query?: {
        type?: CustomFieldTemplateType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/GetCustomFieldTemplates`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateGetCustomFieldTemplateByIdList
     * @request GET:/Parameters/CustomFieldTemplate/GetCustomFieldTemplateById
     * @secure
     */
    customFieldTemplateGetCustomFieldTemplateByIdList: (
      query?: {
        /** @format int32 */
        customFieldTemplateId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/GetCustomFieldTemplateById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateGetCustomFieldTemplatesArchivedList
     * @request GET:/Parameters/CustomFieldTemplate/GetCustomFieldTemplatesArchived
     * @secure
     */
    customFieldTemplateGetCustomFieldTemplatesArchivedList: (
      query?: {
        type?: CustomFieldTemplateType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/GetCustomFieldTemplatesArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateGetCustomFieldTemplateArchivedByIdList
     * @request GET:/Parameters/CustomFieldTemplate/GetCustomFieldTemplateArchivedById
     * @secure
     */
    customFieldTemplateGetCustomFieldTemplateArchivedByIdList: (
      query?: {
        /** @format int32 */
        customFieldTemplateId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/GetCustomFieldTemplateArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateCreateCustomFieldTemplateCreate
     * @request POST:/Parameters/CustomFieldTemplate/CreateCustomFieldTemplate
     * @secure
     */
    customFieldTemplateCreateCustomFieldTemplateCreate: (
      data: CustomFieldTemplateRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/CreateCustomFieldTemplate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateUpdateCustomFieldTemplateUpdate
     * @request PUT:/Parameters/CustomFieldTemplate/UpdateCustomFieldTemplate
     * @secure
     */
    customFieldTemplateUpdateCustomFieldTemplateUpdate: (
      data: CustomFieldTemplateRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/UpdateCustomFieldTemplate`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateRestoreUpdate
     * @request PUT:/Parameters/CustomFieldTemplate/Restore
     * @secure
     */
    customFieldTemplateRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateArchiveDelete
     * @request DELETE:/Parameters/CustomFieldTemplate/Archive
     * @secure
     */
    customFieldTemplateArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomFieldTemplate
     * @name CustomFieldTemplateDeleteDelete
     * @request DELETE:/Parameters/CustomFieldTemplate/Delete
     * @secure
     */
    customFieldTemplateDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/CustomFieldTemplate/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobGetJobsList
     * @request GET:/Parameters/Job/GetJobs
     * @secure
     */
    jobGetJobsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Job/GetJobs`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobGetJobsArchivedList
     * @request GET:/Parameters/Job/GetJobsArchived
     * @secure
     */
    jobGetJobsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Job/GetJobsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobGetJobByIdList
     * @request GET:/Parameters/Job/GetJobById
     * @secure
     */
    jobGetJobByIdList: (
      query?: {
        /** @format int32 */
        jobId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/Job/GetJobById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobGetJobArchivedByIdList
     * @request GET:/Parameters/Job/GetJobArchivedById
     * @secure
     */
    jobGetJobArchivedByIdList: (
      query?: {
        /** @format int32 */
        jobId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/Job/GetJobArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobCreateJobCreate
     * @request POST:/Parameters/Job/CreateJob
     * @secure
     */
    jobCreateJobCreate: (data: JobRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Job/CreateJob`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobUpdateJobUpdate
     * @request PUT:/Parameters/Job/UpdateJob
     * @secure
     */
    jobUpdateJobUpdate: (data: JobRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Job/UpdateJob`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobRestoreUpdate
     * @request PUT:/Parameters/Job/Restore
     * @secure
     */
    jobRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Job/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobArchiveDelete
     * @request DELETE:/Parameters/Job/Archive
     * @secure
     */
    jobArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Job/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Job
     * @name JobDeleteDelete
     * @request DELETE:/Parameters/Job/Delete
     * @secure
     */
    jobDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/Job/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingGetSmtpSettingsList
     * @request GET:/Parameters/SmtpSetting/GetSmtpSettings
     * @secure
     */
    smtpSettingGetSmtpSettingsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/GetSmtpSettings`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingGetSmtpSettingByIdList
     * @request GET:/Parameters/SmtpSetting/GetSmtpSettingById
     * @secure
     */
    smtpSettingGetSmtpSettingByIdList: (
      query?: {
        /** @format int32 */
        smtpsettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/GetSmtpSettingById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingGetSmtpSettingsArchivedList
     * @request GET:/Parameters/SmtpSetting/GetSmtpSettingsArchived
     * @secure
     */
    smtpSettingGetSmtpSettingsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/GetSmtpSettingsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingGetSmtpSettingArchivedByIdList
     * @request GET:/Parameters/SmtpSetting/GetSmtpSettingArchivedById
     * @secure
     */
    smtpSettingGetSmtpSettingArchivedByIdList: (
      query?: {
        /** @format int32 */
        smtpsettingId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/GetSmtpSettingArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingCreateSmtpSettingCreate
     * @request POST:/Parameters/SmtpSetting/CreateSmtpSetting
     * @secure
     */
    smtpSettingCreateSmtpSettingCreate: (data: SmtpSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/CreateSmtpSetting`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingUpdateSmtpSettingUpdate
     * @request PUT:/Parameters/SmtpSetting/UpdateSmtpSetting
     * @secure
     */
    smtpSettingUpdateSmtpSettingUpdate: (data: SmtpSettingRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/UpdateSmtpSetting`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingRestoreUpdate
     * @request PUT:/Parameters/SmtpSetting/Restore
     * @secure
     */
    smtpSettingRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingArchiveDelete
     * @request DELETE:/Parameters/SmtpSetting/Archive
     * @secure
     */
    smtpSettingArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SmtpSetting
     * @name SmtpSettingDeleteDelete
     * @request DELETE:/Parameters/SmtpSetting/Delete
     * @secure
     */
    smtpSettingDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Parameters/SmtpSetting/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  purchase = {
    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderGetCreditsProvidersList
     * @request GET:/Purchase/CreditProvider/GetCreditsProviders
     * @secure
     */
    creditProviderGetCreditsProvidersList: (
      query?: {
        providerIds?: number[];
        status?: CreditProviderStatus[];
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        invoiceProviderId?: number;
        /** @format date-time */
        startTo?: string;
        /** @format date-time */
        endTo?: string;
        /** @format date-time */
        startValueDate?: string;
        /** @format date-time */
        endValueDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/GetCreditsProviders`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderGetCreditsProviderByIdList
     * @request GET:/Purchase/CreditProvider/GetCreditsProviderById
     * @secure
     */
    creditProviderGetCreditsProviderByIdList: (
      query?: {
        /** @format int32 */
        creditProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/GetCreditsProviderById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderCreateCreditsProviderCreate
     * @request POST:/Purchase/CreditProvider/CreateCreditsProvider
     * @secure
     */
    creditProviderCreateCreditsProviderCreate: (data: CreditProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/CreateCreditsProvider`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderUpdateCreditsProviderUpdate
     * @request PUT:/Purchase/CreditProvider/UpdateCreditsProvider
     * @secure
     */
    creditProviderUpdateCreditsProviderUpdate: (data: CreditProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/UpdateCreditsProvider`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderUpdateCreditProviderRepartitionsUpdate
     * @request PUT:/Purchase/CreditProvider/UpdateCreditProviderRepartitions
     * @secure
     */
    creditProviderUpdateCreditProviderRepartitionsUpdate: (
      data: CreditProviderRepartitionRequestDto[],
      query?: {
        /** @format int32 */
        creditProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/UpdateCreditProviderRepartitions`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderUpdateCreditProviderTvaUpdate
     * @request PUT:/Purchase/CreditProvider/UpdateCreditProviderTva
     * @secure
     */
    creditProviderUpdateCreditProviderTvaUpdate: (
      data: CreditProviderTvaRequestDto[],
      query?: {
        /** @format int32 */
        creditProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/UpdateCreditProviderTva`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderUpdateCreditProviderTagCreditProviderUpdate
     * @request PUT:/Purchase/CreditProvider/UpdateCreditProviderTagCreditProvider
     * @secure
     */
    creditProviderUpdateCreditProviderTagCreditProviderUpdate: (
      data: CreditProviderTagCreditProviderRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/UpdateCreditProviderTagCreditProvider`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderUpdateCreditProviderInvoiceProviderUpdate
     * @request PUT:/Purchase/CreditProvider/UpdateCreditProviderInvoiceProvider
     * @secure
     */
    creditProviderUpdateCreditProviderInvoiceProviderUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        creditProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/UpdateCreditProviderInvoiceProvider`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderUploadCreditProviderFileUpdate
     * @request PUT:/Purchase/CreditProvider/UploadCreditProviderFile
     * @secure
     */
    creditProviderUploadCreditProviderFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/UploadCreditProviderFile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProvider
     * @name CreditProviderDeleteDelete
     * @request DELETE:/Purchase/CreditProvider/Delete
     * @secure
     */
    creditProviderDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProvider/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderHistory
     * @name CreditProviderHistoryGetCreditProviderHistoryByCreditProviderIdList
     * @request GET:/Purchase/CreditProviderHistory/GetCreditProviderHistoryByCreditProviderId
     * @secure
     */
    creditProviderHistoryGetCreditProviderHistoryByCreditProviderIdList: (
      query?: {
        /** @format int32 */
        creditProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderHistory/GetCreditProviderHistoryByCreditProviderId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderHistory
     * @name CreditProviderHistoryGetCreditProviderHistoryByIdList
     * @request GET:/Purchase/CreditProviderHistory/GetCreditProviderHistoryById
     * @secure
     */
    creditProviderHistoryGetCreditProviderHistoryByIdList: (
      query?: {
        /** @format int32 */
        CreditProviderHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderHistory/GetCreditProviderHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagGetCreditProviderTagsList
     * @request GET:/Purchase/CreditProviderTag/GetCreditProviderTags
     * @secure
     */
    creditProviderTagGetCreditProviderTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/GetCreditProviderTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagGetCreditProviderTagByIdList
     * @request GET:/Purchase/CreditProviderTag/GetCreditProviderTagById
     * @secure
     */
    creditProviderTagGetCreditProviderTagByIdList: (
      query?: {
        /** @format int32 */
        creditProviderTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/GetCreditProviderTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagGetCreditProviderTagsArchivedList
     * @request GET:/Purchase/CreditProviderTag/GetCreditProviderTagsArchived
     * @secure
     */
    creditProviderTagGetCreditProviderTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/GetCreditProviderTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagGetCreditProviderTagArchivedByIdList
     * @request GET:/Purchase/CreditProviderTag/GetCreditProviderTagArchivedById
     * @secure
     */
    creditProviderTagGetCreditProviderTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        creditProviderTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/GetCreditProviderTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagCreateCreditProviderTagCreate
     * @request POST:/Purchase/CreditProviderTag/CreateCreditProviderTag
     * @secure
     */
    creditProviderTagCreateCreditProviderTagCreate: (data: CreditProviderTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/CreateCreditProviderTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagUpdateCreditProviderTagUpdate
     * @request PUT:/Purchase/CreditProviderTag/UpdateCreditProviderTag
     * @secure
     */
    creditProviderTagUpdateCreditProviderTagUpdate: (data: CreditProviderTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/UpdateCreditProviderTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagRestoreUpdate
     * @request PUT:/Purchase/CreditProviderTag/Restore
     * @secure
     */
    creditProviderTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagArchiveDelete
     * @request DELETE:/Purchase/CreditProviderTag/Archive
     * @secure
     */
    creditProviderTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditProviderTag
     * @name CreditProviderTagDeleteDelete
     * @request DELETE:/Purchase/CreditProviderTag/Delete
     * @secure
     */
    creditProviderTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditProviderTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagGetCreditTagsList
     * @request GET:/Purchase/CreditTag/GetCreditTags
     * @secure
     */
    creditTagGetCreditTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/GetCreditTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagGetCreditTagByIdList
     * @request GET:/Purchase/CreditTag/GetCreditTagById
     * @secure
     */
    creditTagGetCreditTagByIdList: (
      query?: {
        /** @format int32 */
        creditTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/GetCreditTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagGetCreditTagsArchivedList
     * @request GET:/Purchase/CreditTag/GetCreditTagsArchived
     * @secure
     */
    creditTagGetCreditTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/GetCreditTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagGetCreditTagArchivedByIdList
     * @request GET:/Purchase/CreditTag/GetCreditTagArchivedById
     * @secure
     */
    creditTagGetCreditTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        creditTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/GetCreditTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagCreateCreditTagCreate
     * @request POST:/Purchase/CreditTag/CreateCreditTag
     * @secure
     */
    creditTagCreateCreditTagCreate: (data: CreditTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/CreateCreditTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagUpdateCreditTagUpdate
     * @request PUT:/Purchase/CreditTag/UpdateCreditTag
     * @secure
     */
    creditTagUpdateCreditTagUpdate: (data: CreditTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/UpdateCreditTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagRestoreUpdate
     * @request PUT:/Purchase/CreditTag/Restore
     * @secure
     */
    creditTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagArchiveDelete
     * @request DELETE:/Purchase/CreditTag/Archive
     * @secure
     */
    creditTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CreditTag
     * @name CreditTagDeleteDelete
     * @request DELETE:/Purchase/CreditTag/Delete
     * @secure
     */
    creditTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/CreditTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressivePurchaseRates
     * @name DegressivePurchaseRatesGetDegressivePurchaseRatesList
     * @request GET:/Purchase/DegressivePurchaseRates/GetDegressivePurchaseRates
     * @secure
     */
    degressivePurchaseRatesGetDegressivePurchaseRatesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DegressivePurchaseRates/GetDegressivePurchaseRates`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressivePurchaseRates
     * @name DegressivePurchaseRatesGetDegressivePurchaseRateByIdList
     * @request GET:/Purchase/DegressivePurchaseRates/GetDegressivePurchaseRateById
     * @secure
     */
    degressivePurchaseRatesGetDegressivePurchaseRateByIdList: (
      query?: {
        /** @format int32 */
        degressivePurchaseRatesId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DegressivePurchaseRates/GetDegressivePurchaseRateById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressivePurchaseRates
     * @name DegressivePurchaseRatesCreateDegressivePurchaseRatesCreate
     * @request POST:/Purchase/DegressivePurchaseRates/CreateDegressivePurchaseRates
     * @secure
     */
    degressivePurchaseRatesCreateDegressivePurchaseRatesCreate: (
      data: DegressivePurchaseRatesRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DegressivePurchaseRates/CreateDegressivePurchaseRates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressivePurchaseRates
     * @name DegressivePurchaseRatesUpdateDegressivePurchaseRatesUpdate
     * @request PUT:/Purchase/DegressivePurchaseRates/UpdateDegressivePurchaseRates
     * @secure
     */
    degressivePurchaseRatesUpdateDegressivePurchaseRatesUpdate: (
      data: DegressivePurchaseRatesRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DegressivePurchaseRates/UpdateDegressivePurchaseRates`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DegressivePurchaseRates
     * @name DegressivePurchaseRatesDeleteDelete
     * @request DELETE:/Purchase/DegressivePurchaseRates/Delete
     * @secure
     */
    degressivePurchaseRatesDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DegressivePurchaseRates/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormGetDeliveryFormsList
     * @request GET:/Purchase/DeliveryForm/GetDeliveryForms
     * @secure
     */
    deliveryFormGetDeliveryFormsList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        companyId?: number;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/GetDeliveryForms`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormGetDeliveryFormByIdList
     * @request GET:/Purchase/DeliveryForm/GetDeliveryFormById
     * @secure
     */
    deliveryFormGetDeliveryFormByIdList: (
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/GetDeliveryFormById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormGetDeliveryFormsArchivedList
     * @request GET:/Purchase/DeliveryForm/GetDeliveryFormsArchived
     * @secure
     */
    deliveryFormGetDeliveryFormsArchivedList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        companyId?: number;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/GetDeliveryFormsArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormGetDeliveryFormArchivedByIdList
     * @request GET:/Purchase/DeliveryForm/GetDeliveryFormArchivedById
     * @secure
     */
    deliveryFormGetDeliveryFormArchivedByIdList: (
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/GetDeliveryFormArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormGetLastDocumentReferenceList
     * @request GET:/Purchase/DeliveryForm/GetLastDocumentReference
     * @secure
     */
    deliveryFormGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormGetDeliveryFormEmptyList
     * @request GET:/Purchase/DeliveryForm/GetDeliveryFormEmpty
     * @secure
     */
    deliveryFormGetDeliveryFormEmptyList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        userId?: number;
        /** @format int32 */
        customerId?: number;
        /** @format int32 */
        collaboratorId?: number;
        /** @format int32 */
        storageLocationId?: number;
        /** @format int32 */
        destockingLocationId?: number;
        /** @format int32 */
        handDeliveryContactId?: number;
        /** @format int32 */
        subContractorId?: number;
        deliveryType?: DeliveryType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/GetDeliveryFormEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormCreateDeliveryFormCreate
     * @request POST:/Purchase/DeliveryForm/CreateDeliveryForm
     * @secure
     */
    deliveryFormCreateDeliveryFormCreate: (data: DeliveryFormRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/CreateDeliveryForm`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormDuplicateDeliveryFormCreate
     * @request POST:/Purchase/DeliveryForm/DuplicateDeliveryForm
     * @secure
     */
    deliveryFormDuplicateDeliveryFormCreate: (
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/DuplicateDeliveryForm`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormUpdateDeliveryFormUpdate
     * @request PUT:/Purchase/DeliveryForm/UpdateDeliveryForm
     * @secure
     */
    deliveryFormUpdateDeliveryFormUpdate: (data: DeliveryFormRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/UpdateDeliveryForm`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormUploadFileUpdate
     * @request PUT:/Purchase/DeliveryForm/UploadFile
     * @secure
     */
    deliveryFormUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormUploadPathDocumentUpdate
     * @request PUT:/Purchase/DeliveryForm/UploadPathDocument
     * @secure
     */
    deliveryFormUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormUpdateDeliveryFormTagDeliveryFormUpdate
     * @request PUT:/Purchase/DeliveryForm/UpdateDeliveryFormTagDeliveryForm
     * @secure
     */
    deliveryFormUpdateDeliveryFormTagDeliveryFormUpdate: (
      data: DeliveryFormTagDeliveryFormRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/UpdateDeliveryFormTagDeliveryForm`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormRestoreUpdate
     * @request PUT:/Purchase/DeliveryForm/Restore
     * @secure
     */
    deliveryFormRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormArchiveDelete
     * @request DELETE:/Purchase/DeliveryForm/Archive
     * @secure
     */
    deliveryFormArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryForm
     * @name DeliveryFormDeleteDelete
     * @request DELETE:/Purchase/DeliveryForm/Delete
     * @secure
     */
    deliveryFormDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryForm/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormDetail
     * @name DeliveryFormDetailGetDeliveryFormDetailsByDeliveryFormIdList
     * @request GET:/Purchase/DeliveryFormDetail/GetDeliveryFormDetailsByDeliveryFormId
     * @secure
     */
    deliveryFormDetailGetDeliveryFormDetailsByDeliveryFormIdList: (
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormDetail/GetDeliveryFormDetailsByDeliveryFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormDetail
     * @name DeliveryFormDetailCreateDeliveryFormDetailCreate
     * @request POST:/Purchase/DeliveryFormDetail/CreateDeliveryFormDetail
     * @secure
     */
    deliveryFormDetailCreateDeliveryFormDetailCreate: (
      data: DeliveryFormDetailRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormDetail/CreateDeliveryFormDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormDetail
     * @name DeliveryFormDetailUpdateDeliveryFormDetailUpdate
     * @request PUT:/Purchase/DeliveryFormDetail/UpdateDeliveryFormDetail
     * @secure
     */
    deliveryFormDetailUpdateDeliveryFormDetailUpdate: (
      data: DeliveryFormDetailRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormDetail/UpdateDeliveryFormDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormDetail
     * @name DeliveryFormDetailUpdateDeliveryFormDetailIndexUpdate
     * @request PUT:/Purchase/DeliveryFormDetail/UpdateDeliveryFormDetailIndex
     * @secure
     */
    deliveryFormDetailUpdateDeliveryFormDetailIndexUpdate: (data: IndexRequestDto[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormDetail/UpdateDeliveryFormDetailIndex`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormDetail
     * @name DeliveryFormDetailUpdateTvaDeliveryFormDetailUpdate
     * @request PUT:/Purchase/DeliveryFormDetail/UpdateTVADeliveryFormDetail
     * @secure
     */
    deliveryFormDetailUpdateTvaDeliveryFormDetailUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        tvaId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormDetail/UpdateTVADeliveryFormDetail`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormDetail
     * @name DeliveryFormDetailDeleteDelete
     * @request DELETE:/Purchase/DeliveryFormDetail/Delete
     * @secure
     */
    deliveryFormDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormHistory
     * @name DeliveryFormHistoryGetDeliveryFormHistoryByIdList
     * @request GET:/Purchase/DeliveryFormHistory/GetDeliveryFormHistoryById
     * @secure
     */
    deliveryFormHistoryGetDeliveryFormHistoryByIdList: (
      query?: {
        /** @format int32 */
        deliveryFormHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormHistory/GetDeliveryFormHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormHistory
     * @name DeliveryFormHistoryGetDeliveryFormHistoriesByDeliveryFormIdList
     * @request GET:/Purchase/DeliveryFormHistory/GetDeliveryFormHistoriesByDeliveryFormId
     * @secure
     */
    deliveryFormHistoryGetDeliveryFormHistoriesByDeliveryFormIdList: (
      query?: {
        /** @format int32 */
        deliveryFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormHistory/GetDeliveryFormHistoriesByDeliveryFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagGetDeliveryFormTagsList
     * @request GET:/Purchase/DeliveryFormTag/GetDeliveryFormTags
     * @secure
     */
    deliveryFormTagGetDeliveryFormTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/GetDeliveryFormTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagGetDeliveryFormTagByIdList
     * @request GET:/Purchase/DeliveryFormTag/GetDeliveryFormTagById
     * @secure
     */
    deliveryFormTagGetDeliveryFormTagByIdList: (
      query?: {
        /** @format int32 */
        deliveryFormTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/GetDeliveryFormTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagGetDeliveryFormTagsArchivedList
     * @request GET:/Purchase/DeliveryFormTag/GetDeliveryFormTagsArchived
     * @secure
     */
    deliveryFormTagGetDeliveryFormTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/GetDeliveryFormTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagGetDeliveryFormTagArchivedByIdList
     * @request GET:/Purchase/DeliveryFormTag/GetDeliveryFormTagArchivedById
     * @secure
     */
    deliveryFormTagGetDeliveryFormTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        deliveryFormTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/GetDeliveryFormTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagCreateDeliveryFormTagCreate
     * @request POST:/Purchase/DeliveryFormTag/CreateDeliveryFormTag
     * @secure
     */
    deliveryFormTagCreateDeliveryFormTagCreate: (data: DeliveryFormTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/CreateDeliveryFormTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagUpdateDeliveryFormTagUpdate
     * @request PUT:/Purchase/DeliveryFormTag/UpdateDeliveryFormTag
     * @secure
     */
    deliveryFormTagUpdateDeliveryFormTagUpdate: (data: DeliveryFormTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/UpdateDeliveryFormTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagRestoreUpdate
     * @request PUT:/Purchase/DeliveryFormTag/Restore
     * @secure
     */
    deliveryFormTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagArchiveDelete
     * @request DELETE:/Purchase/DeliveryFormTag/Archive
     * @secure
     */
    deliveryFormTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTag
     * @name DeliveryFormTagDeleteDelete
     * @request DELETE:/Purchase/DeliveryFormTag/Delete
     * @secure
     */
    deliveryFormTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTva
     * @name DeliveryFormTvaGetDeliveryFormTvasList
     * @request GET:/Purchase/DeliveryFormTva/GetDeliveryFormTvas
     * @secure
     */
    deliveryFormTvaGetDeliveryFormTvasList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTva/GetDeliveryFormTvas`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTva
     * @name DeliveryFormTvaGetDeliveryFormTvaByIdList
     * @request GET:/Purchase/DeliveryFormTva/GetDeliveryFormTvaById
     * @secure
     */
    deliveryFormTvaGetDeliveryFormTvaByIdList: (
      query?: {
        /** @format int32 */
        deliveryFormTvaId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTva/GetDeliveryFormTvaById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTva
     * @name DeliveryFormTvaCreateDeliveryFormTvaCreate
     * @request POST:/Purchase/DeliveryFormTva/CreateDeliveryFormTva
     * @secure
     */
    deliveryFormTvaCreateDeliveryFormTvaCreate: (data: DeliveryFormTvaRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTva/CreateDeliveryFormTva`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTva
     * @name DeliveryFormTvaUpdateDeliveryFormTvaUpdate
     * @request PUT:/Purchase/DeliveryFormTva/UpdateDeliveryFormTva
     * @secure
     */
    deliveryFormTvaUpdateDeliveryFormTvaUpdate: (data: DeliveryFormTvaRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTva/UpdateDeliveryFormTva`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryFormTva
     * @name DeliveryFormTvaDeleteDelete
     * @request DELETE:/Purchase/DeliveryFormTva/Delete
     * @secure
     */
    deliveryFormTvaDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/DeliveryFormTva/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryGetInventoriesList
     * @request GET:/Purchase/Inventory/GetInventories
     * @secure
     */
    inventoryGetInventoriesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/GetInventories`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryGetInventoryByIdList
     * @request GET:/Purchase/Inventory/GetInventoryById
     * @secure
     */
    inventoryGetInventoryByIdList: (
      query?: {
        /** @format int32 */
        inventoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/GetInventoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryGetInventoriesArchivedList
     * @request GET:/Purchase/Inventory/GetInventoriesArchived
     * @secure
     */
    inventoryGetInventoriesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/GetInventoriesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryGetInventoryArchivedByIdList
     * @request GET:/Purchase/Inventory/GetInventoryArchivedById
     * @secure
     */
    inventoryGetInventoryArchivedByIdList: (
      query?: {
        /** @format int32 */
        inventoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/GetInventoryArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryGetLastDocumentReferenceList
     * @request GET:/Purchase/Inventory/GetLastDocumentReference
     * @secure
     */
    inventoryGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryGetInventoryEmptyList
     * @request GET:/Purchase/Inventory/GetInventoryEmpty
     * @secure
     */
    inventoryGetInventoryEmptyList: (
      query?: {
        /** @format int32 */
        userId?: number;
        /** @format int32 */
        storageLocationId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/GetInventoryEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryCreateInventoryCreate
     * @request POST:/Purchase/Inventory/CreateInventory
     * @secure
     */
    inventoryCreateInventoryCreate: (data: InventoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/CreateInventory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryUpdateInventoryUpdate
     * @request PUT:/Purchase/Inventory/UpdateInventory
     * @secure
     */
    inventoryUpdateInventoryUpdate: (data: InventoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/UpdateInventory`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryUploadPathDocumentUpdate
     * @request PUT:/Purchase/Inventory/UploadPathDocument
     * @secure
     */
    inventoryUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        inventoryId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryUploadFileUpdate
     * @request PUT:/Purchase/Inventory/UploadFile
     * @secure
     */
    inventoryUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        inventoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryRestoreUpdate
     * @request PUT:/Purchase/Inventory/Restore
     * @secure
     */
    inventoryRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryArchiveDelete
     * @request DELETE:/Purchase/Inventory/Archive
     * @secure
     */
    inventoryArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Inventory
     * @name InventoryDeleteDelete
     * @request DELETE:/Purchase/Inventory/Delete
     * @secure
     */
    inventoryDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Inventory/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryDetail
     * @name InventoryDetailGetInventoryDetailByIdList
     * @request GET:/Purchase/InventoryDetail/GetInventoryDetailById
     * @secure
     */
    inventoryDetailGetInventoryDetailByIdList: (
      query?: {
        /** @format int32 */
        inventoryDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InventoryDetail/GetInventoryDetailById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryDetail
     * @name InventoryDetailGetInventoryDetailsByInventoryIdList
     * @request GET:/Purchase/InventoryDetail/GetInventoryDetailsByInventoryId
     * @secure
     */
    inventoryDetailGetInventoryDetailsByInventoryIdList: (
      query?: {
        /** @format int32 */
        inventoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InventoryDetail/GetInventoryDetailsByInventoryId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryDetail
     * @name InventoryDetailGetInventoryDetailsArchivedList
     * @request GET:/Purchase/InventoryDetail/GetInventoryDetailsArchived
     * @secure
     */
    inventoryDetailGetInventoryDetailsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InventoryDetail/GetInventoryDetailsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryDetail
     * @name InventoryDetailGetInventoryDetailArchivedByIdList
     * @request GET:/Purchase/InventoryDetail/GetInventoryDetailArchivedById
     * @secure
     */
    inventoryDetailGetInventoryDetailArchivedByIdList: (
      query?: {
        /** @format int32 */
        inventoryDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InventoryDetail/GetInventoryDetailArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryDetail
     * @name InventoryDetailCreateInventoryDetailCreate
     * @request POST:/Purchase/InventoryDetail/CreateInventoryDetail
     * @secure
     */
    inventoryDetailCreateInventoryDetailCreate: (data: InventoryDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InventoryDetail/CreateInventoryDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryDetail
     * @name InventoryDetailUpdateInventoryDetailUpdate
     * @request PUT:/Purchase/InventoryDetail/UpdateInventoryDetail
     * @secure
     */
    inventoryDetailUpdateInventoryDetailUpdate: (data: InventoryDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InventoryDetail/UpdateInventoryDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryDetail
     * @name InventoryDetailDeleteDelete
     * @request DELETE:/Purchase/InventoryDetail/Delete
     * @secure
     */
    inventoryDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InventoryDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryHistory
     * @name InventoryHistoryGetInventoryHistoryByIdList
     * @request GET:/Purchase/InventoryHistory/GetInventoryHistoryById
     * @secure
     */
    inventoryHistoryGetInventoryHistoryByIdList: (
      query?: {
        /** @format int32 */
        inventoryHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InventoryHistory/GetInventoryHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryHistory
     * @name InventoryHistoryGetInventoryHistoryByInventoryIdList
     * @request GET:/Purchase/InventoryHistory/GetInventoryHistoryByInventoryId
     * @secure
     */
    inventoryHistoryGetInventoryHistoryByInventoryIdList: (
      query?: {
        /** @format int32 */
        inventoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InventoryHistory/GetInventoryHistoryByInventoryId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderGetInvoicesProvidersList
     * @request GET:/Purchase/InvoiceProvider/GetInvoicesProviders
     * @secure
     */
    invoiceProviderGetInvoicesProvidersList: (
      query?: {
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        /** @format int32 */
        affairId?: number;
        providerIds?: number[];
        status?: InvoiceProviderStatus[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/GetInvoicesProviders`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderGetInvoicesProviderByIdList
     * @request GET:/Purchase/InvoiceProvider/GetInvoicesProviderById
     * @secure
     */
    invoiceProviderGetInvoicesProviderByIdList: (
      query?: {
        /** @format int32 */
        invoiceProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/GetInvoicesProviderById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderCreateInvoicesProviderCreate
     * @request POST:/Purchase/InvoiceProvider/CreateInvoicesProvider
     * @secure
     */
    invoiceProviderCreateInvoicesProviderCreate: (data: InvoiceProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/CreateInvoicesProvider`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderUpdateInvoicesProviderUpdate
     * @request PUT:/Purchase/InvoiceProvider/UpdateInvoicesProvider
     * @secure
     */
    invoiceProviderUpdateInvoicesProviderUpdate: (data: InvoiceProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/UpdateInvoicesProvider`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderUpdateInvoiceProviderRepartitionsUpdate
     * @request PUT:/Purchase/InvoiceProvider/UpdateInvoiceProviderRepartitions
     * @secure
     */
    invoiceProviderUpdateInvoiceProviderRepartitionsUpdate: (
      data: InvoiceProviderRepartitionRequestDto[],
      query?: {
        /** @format int32 */
        invoiceProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/UpdateInvoiceProviderRepartitions`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderUpdateInvoiceProviderTvaUpdate
     * @request PUT:/Purchase/InvoiceProvider/UpdateInvoiceProviderTva
     * @secure
     */
    invoiceProviderUpdateInvoiceProviderTvaUpdate: (
      data: InvoiceProviderTvaRequestDto[],
      query?: {
        /** @format int32 */
        invoiceProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/UpdateInvoiceProviderTva`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderUpdateInvoiceProviderCreditProviderUpdate
     * @request PUT:/Purchase/InvoiceProvider/UpdateInvoiceProviderCreditProvider
     * @secure
     */
    invoiceProviderUpdateInvoiceProviderCreditProviderUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        invoiceProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/UpdateInvoiceProviderCreditProvider`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderUpdateInvoiceProviderTagInvoiceProviderUpdate
     * @request PUT:/Purchase/InvoiceProvider/UpdateInvoiceProviderTagInvoiceProvider
     * @secure
     */
    invoiceProviderUpdateInvoiceProviderTagInvoiceProviderUpdate: (
      data: InvoiceProviderTagInvoiceProviderRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/UpdateInvoiceProviderTagInvoiceProvider`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderUploadInvoiceProviderFileUpdate
     * @request PUT:/Purchase/InvoiceProvider/UploadInvoiceProviderFile
     * @secure
     */
    invoiceProviderUploadInvoiceProviderFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/UploadInvoiceProviderFile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProvider
     * @name InvoiceProviderDeleteDelete
     * @request DELETE:/Purchase/InvoiceProvider/Delete
     * @secure
     */
    invoiceProviderDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProvider/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderHistory
     * @name InvoiceProviderHistoryGetInvoiceProviderHistoryByInvoiceProviderIdList
     * @request GET:/Purchase/InvoiceProviderHistory/GetInvoiceProviderHistoryByInvoiceProviderId
     * @secure
     */
    invoiceProviderHistoryGetInvoiceProviderHistoryByInvoiceProviderIdList: (
      query?: {
        /** @format int32 */
        invoiceProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderHistory/GetInvoiceProviderHistoryByInvoiceProviderId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderHistory
     * @name InvoiceProviderHistoryGetInvoiceProviderHistoryByIdList
     * @request GET:/Purchase/InvoiceProviderHistory/GetInvoiceProviderHistoryById
     * @secure
     */
    invoiceProviderHistoryGetInvoiceProviderHistoryByIdList: (
      query?: {
        /** @format int32 */
        InvoiceProviderHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderHistory/GetInvoiceProviderHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagGetInvoiceProviderTagsList
     * @request GET:/Purchase/InvoiceProviderTag/GetInvoiceProviderTags
     * @secure
     */
    invoiceProviderTagGetInvoiceProviderTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/GetInvoiceProviderTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagGetInvoiceProviderTagByIdList
     * @request GET:/Purchase/InvoiceProviderTag/GetInvoiceProviderTagById
     * @secure
     */
    invoiceProviderTagGetInvoiceProviderTagByIdList: (
      query?: {
        /** @format int32 */
        invoiceProviderTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/GetInvoiceProviderTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagGetInvoiceProviderTagsArchivedList
     * @request GET:/Purchase/InvoiceProviderTag/GetInvoiceProviderTagsArchived
     * @secure
     */
    invoiceProviderTagGetInvoiceProviderTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/GetInvoiceProviderTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagGetInvoiceProviderTagArchivedByIdList
     * @request GET:/Purchase/InvoiceProviderTag/GetInvoiceProviderTagArchivedById
     * @secure
     */
    invoiceProviderTagGetInvoiceProviderTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        invoiceProviderTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/GetInvoiceProviderTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagCreateInvoiceProviderTagCreate
     * @request POST:/Purchase/InvoiceProviderTag/CreateInvoiceProviderTag
     * @secure
     */
    invoiceProviderTagCreateInvoiceProviderTagCreate: (
      data: InvoiceProviderTagRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/CreateInvoiceProviderTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagUpdateInvoiceProviderTagUpdate
     * @request PUT:/Purchase/InvoiceProviderTag/UpdateInvoiceProviderTag
     * @secure
     */
    invoiceProviderTagUpdateInvoiceProviderTagUpdate: (
      data: InvoiceProviderTagRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/UpdateInvoiceProviderTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagRestoreUpdate
     * @request PUT:/Purchase/InvoiceProviderTag/Restore
     * @secure
     */
    invoiceProviderTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagArchiveDelete
     * @request DELETE:/Purchase/InvoiceProviderTag/Archive
     * @secure
     */
    invoiceProviderTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceProviderTag
     * @name InvoiceProviderTagDeleteDelete
     * @request DELETE:/Purchase/InvoiceProviderTag/Delete
     * @secure
     */
    invoiceProviderTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceProviderTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagGetInvoiceTagsList
     * @request GET:/Purchase/InvoiceTag/GetInvoiceTags
     * @secure
     */
    invoiceTagGetInvoiceTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/GetInvoiceTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagGetInvoiceTagByIdList
     * @request GET:/Purchase/InvoiceTag/GetInvoiceTagById
     * @secure
     */
    invoiceTagGetInvoiceTagByIdList: (
      query?: {
        /** @format int32 */
        invoiceTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/GetInvoiceTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagGetInvoiceTagsArchivedList
     * @request GET:/Purchase/InvoiceTag/GetInvoiceTagsArchived
     * @secure
     */
    invoiceTagGetInvoiceTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/GetInvoiceTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagGetInvoiceTagArchivedByIdList
     * @request GET:/Purchase/InvoiceTag/GetInvoiceTagArchivedById
     * @secure
     */
    invoiceTagGetInvoiceTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        invoiceTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/GetInvoiceTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagCreateInvoiceTagCreate
     * @request POST:/Purchase/InvoiceTag/CreateInvoiceTag
     * @secure
     */
    invoiceTagCreateInvoiceTagCreate: (data: InvoiceTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/CreateInvoiceTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagUpdateInvoiceTagUpdate
     * @request PUT:/Purchase/InvoiceTag/UpdateInvoiceTag
     * @secure
     */
    invoiceTagUpdateInvoiceTagUpdate: (data: InvoiceTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/UpdateInvoiceTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagRestoreUpdate
     * @request PUT:/Purchase/InvoiceTag/Restore
     * @secure
     */
    invoiceTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagArchiveDelete
     * @request DELETE:/Purchase/InvoiceTag/Archive
     * @secure
     */
    invoiceTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvoiceTag
     * @name InvoiceTagDeleteDelete
     * @request DELETE:/Purchase/InvoiceTag/Delete
     * @secure
     */
    invoiceTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/InvoiceTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormGetOrderFormsList
     * @request GET:/Purchase/OrderForm/GetOrderForms
     * @secure
     */
    orderFormGetOrderFormsList: (
      query?: {
        isTemplate?: boolean;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        companyId?: number;
        isProvider?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/GetOrderForms`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormGetOrderFormByIdList
     * @request GET:/Purchase/OrderForm/GetOrderFormById
     * @secure
     */
    orderFormGetOrderFormByIdList: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/GetOrderFormById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormGetOrderFormTvasByOrderFormIdList
     * @request GET:/Purchase/OrderForm/GetOrderFormTvasByOrderFormId
     * @secure
     */
    orderFormGetOrderFormTvasByOrderFormIdList: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/GetOrderFormTvasByOrderFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormGetLastDocumentReferenceList
     * @request GET:/Purchase/OrderForm/GetLastDocumentReference
     * @secure
     */
    orderFormGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormGetOrderFormEmptyList
     * @request GET:/Purchase/OrderForm/GetOrderFormEmpty
     * @secure
     */
    orderFormGetOrderFormEmptyList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        providerId?: number;
        /** @format int32 */
        userId?: number;
        /** @format int32 */
        customerId?: number;
        /** @format int32 */
        storageLocationId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/GetOrderFormEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormGetInitialOrderFormByIdList
     * @request GET:/Purchase/OrderForm/GetInitialOrderFormById
     * @secure
     */
    orderFormGetInitialOrderFormByIdList: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/GetInitialOrderFormById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormCreateOrderFormCreate
     * @request POST:/Purchase/OrderForm/CreateOrderForm
     * @secure
     */
    orderFormCreateOrderFormCreate: (data: OrderFormRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/CreateOrderForm`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormDuplicateOrderFormCreate
     * @request POST:/Purchase/OrderForm/DuplicateOrderForm
     * @secure
     */
    orderFormDuplicateOrderFormCreate: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/DuplicateOrderForm`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormUpdateOrderFormUpdate
     * @request PUT:/Purchase/OrderForm/UpdateOrderForm
     * @secure
     */
    orderFormUpdateOrderFormUpdate: (data: OrderFormRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/UpdateOrderForm`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormUploadPathDocumentUpdate
     * @request PUT:/Purchase/OrderForm/UploadPathDocument
     * @secure
     */
    orderFormUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormUploadFileUpdate
     * @request PUT:/Purchase/OrderForm/UploadFile
     * @secure
     */
    orderFormUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormUpdatePaymentMethodOrderFormUpdate
     * @request PUT:/Purchase/OrderForm/UpdatePaymentMethodOrderForm
     * @secure
     */
    orderFormUpdatePaymentMethodOrderFormUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/UpdatePaymentMethodOrderForm`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormUpdateOrderFormTagOrderFormUpdate
     * @request PUT:/Purchase/OrderForm/UpdateOrderFormTagOrderForm
     * @secure
     */
    orderFormUpdateOrderFormTagOrderFormUpdate: (data: OrderFormTagOrderFormRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/UpdateOrderFormTagOrderForm`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderForm
     * @name OrderFormDeleteDelete
     * @request DELETE:/Purchase/OrderForm/Delete
     * @secure
     */
    orderFormDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderForm/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagGetOrderFormCustomerTagsList
     * @request GET:/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTags
     * @secure
     */
    orderFormCustomerTagGetOrderFormCustomerTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagGetOrderFormCustomerTagByIdList
     * @request GET:/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagById
     * @secure
     */
    orderFormCustomerTagGetOrderFormCustomerTagByIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagGetOrderFormCustomerTagsArchivedList
     * @request GET:/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagsArchived
     * @secure
     */
    orderFormCustomerTagGetOrderFormCustomerTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagGetOrderFormCustomerTagArchivedByIdList
     * @request GET:/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagArchivedById
     * @secure
     */
    orderFormCustomerTagGetOrderFormCustomerTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        orderFormCustomerTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagCreateOrderFormCustomerTagCreate
     * @request POST:/Purchase/OrderFormCustomerTag/CreateOrderFormCustomerTag
     * @secure
     */
    orderFormCustomerTagCreateOrderFormCustomerTagCreate: (
      data: OrderFormCustomerTagRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/CreateOrderFormCustomerTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagUpdateOrderFormCustomerTagUpdate
     * @request PUT:/Purchase/OrderFormCustomerTag/UpdateOrderFormCustomerTag
     * @secure
     */
    orderFormCustomerTagUpdateOrderFormCustomerTagUpdate: (
      data: OrderFormCustomerTagRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/UpdateOrderFormCustomerTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagRestoreUpdate
     * @request PUT:/Purchase/OrderFormCustomerTag/Restore
     * @secure
     */
    orderFormCustomerTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagArchiveDelete
     * @request DELETE:/Purchase/OrderFormCustomerTag/Archive
     * @secure
     */
    orderFormCustomerTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormCustomerTag
     * @name OrderFormCustomerTagDeleteDelete
     * @request DELETE:/Purchase/OrderFormCustomerTag/Delete
     * @secure
     */
    orderFormCustomerTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormCustomerTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormDetail
     * @name OrderFormDetailGetOrderFormDetailByIdList
     * @request GET:/Purchase/OrderFormDetail/GetOrderFormDetailById
     * @secure
     */
    orderFormDetailGetOrderFormDetailByIdList: (
      query?: {
        /** @format int32 */
        orderFormDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormDetail/GetOrderFormDetailById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormDetail
     * @name OrderFormDetailGetOrderFormDetailByOrderFormIdList
     * @request GET:/Purchase/OrderFormDetail/GetOrderFormDetailByOrderFormId
     * @secure
     */
    orderFormDetailGetOrderFormDetailByOrderFormIdList: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormDetail/GetOrderFormDetailByOrderFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormDetail
     * @name OrderFormDetailCreateOrderFormDetailCreate
     * @request POST:/Purchase/OrderFormDetail/CreateOrderFormDetail
     * @secure
     */
    orderFormDetailCreateOrderFormDetailCreate: (data: OrderFormDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormDetail/CreateOrderFormDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormDetail
     * @name OrderFormDetailUpdateOrderFormDetailUpdate
     * @request PUT:/Purchase/OrderFormDetail/UpdateOrderFormDetail
     * @secure
     */
    orderFormDetailUpdateOrderFormDetailUpdate: (data: OrderFormDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormDetail/UpdateOrderFormDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormDetail
     * @name OrderFormDetailUpdateTvaOrderFormDetailUpdate
     * @request PUT:/Purchase/OrderFormDetail/UpdateTVAOrderFormDetail
     * @secure
     */
    orderFormDetailUpdateTvaOrderFormDetailUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        tvaId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormDetail/UpdateTVAOrderFormDetail`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormDetail
     * @name OrderFormDetailUpdateOrderFormDetailIndexUpdate
     * @request PUT:/Purchase/OrderFormDetail/UpdateOrderFormDetailIndex
     * @secure
     */
    orderFormDetailUpdateOrderFormDetailIndexUpdate: (data: IndexRequestDto[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormDetail/UpdateOrderFormDetailIndex`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormDetail
     * @name OrderFormDetailDeleteDelete
     * @request DELETE:/Purchase/OrderFormDetail/Delete
     * @secure
     */
    orderFormDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormHistory
     * @name OrderFormHistoryGetOrderFormHistoryByIdList
     * @request GET:/Purchase/OrderFormHistory/GetOrderFormHistoryById
     * @secure
     */
    orderFormHistoryGetOrderFormHistoryByIdList: (
      query?: {
        /** @format int32 */
        orderFormHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormHistory/GetOrderFormHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormHistory
     * @name OrderFormHistoryGetOrderFormHistoriesByOrderFormIdList
     * @request GET:/Purchase/OrderFormHistory/GetOrderFormHistoriesByOrderFormId
     * @secure
     */
    orderFormHistoryGetOrderFormHistoriesByOrderFormIdList: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormHistory/GetOrderFormHistoriesByOrderFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagGetOrderFormTagsList
     * @request GET:/Purchase/OrderFormTag/GetOrderFormTags
     * @secure
     */
    orderFormTagGetOrderFormTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/GetOrderFormTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagGetOrderFormTagByIdList
     * @request GET:/Purchase/OrderFormTag/GetOrderFormTagById
     * @secure
     */
    orderFormTagGetOrderFormTagByIdList: (
      query?: {
        /** @format int32 */
        orderFormTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/GetOrderFormTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagGetOrderFormTagsArchivedList
     * @request GET:/Purchase/OrderFormTag/GetOrderFormTagsArchived
     * @secure
     */
    orderFormTagGetOrderFormTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/GetOrderFormTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagGetOrderFormTagArchivedByIdList
     * @request GET:/Purchase/OrderFormTag/GetOrderFormTagArchivedById
     * @secure
     */
    orderFormTagGetOrderFormTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        orderFormTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/GetOrderFormTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagCreateOrderFormTagCreate
     * @request POST:/Purchase/OrderFormTag/CreateOrderFormTag
     * @secure
     */
    orderFormTagCreateOrderFormTagCreate: (data: OrderFormTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/CreateOrderFormTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagUpdateOrderFormTagUpdate
     * @request PUT:/Purchase/OrderFormTag/UpdateOrderFormTag
     * @secure
     */
    orderFormTagUpdateOrderFormTagUpdate: (data: OrderFormTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/UpdateOrderFormTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagRestoreUpdate
     * @request PUT:/Purchase/OrderFormTag/Restore
     * @secure
     */
    orderFormTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagArchiveDelete
     * @request DELETE:/Purchase/OrderFormTag/Archive
     * @secure
     */
    orderFormTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTag
     * @name OrderFormTagDeleteDelete
     * @request DELETE:/Purchase/OrderFormTag/Delete
     * @secure
     */
    orderFormTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTva
     * @name OrderFormTvaCreateOrderFormTvaCreate
     * @request POST:/Purchase/OrderFormTva/CreateOrderFormTva
     * @secure
     */
    orderFormTvaCreateOrderFormTvaCreate: (data: OrderFormTvaRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTva/CreateOrderFormTva`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTva
     * @name OrderFormTvaUpdateOrderFormTvaUpdate
     * @request PUT:/Purchase/OrderFormTva/UpdateOrderFormTva
     * @secure
     */
    orderFormTvaUpdateOrderFormTvaUpdate: (data: OrderFormTvaRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTva/UpdateOrderFormTva`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderFormTva
     * @name OrderFormTvaDeleteDelete
     * @request DELETE:/Purchase/OrderFormTva/Delete
     * @secure
     */
    orderFormTvaDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/OrderFormTva/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderGetPaymentProviderByIdList
     * @request GET:/Purchase/PaymentProvider/GetPaymentProviderById
     * @secure
     */
    paymentProviderGetPaymentProviderByIdList: (
      query?: {
        /** @format int32 */
        paymentProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/GetPaymentProviderById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderGetPaymentProvidersList
     * @request GET:/Purchase/PaymentProvider/GetPaymentProviders
     * @secure
     */
    paymentProviderGetPaymentProvidersList: (
      query?: {
        /** @format int32 */
        invoiceProviderId?: number;
        /** @format int32 */
        creditProviderId?: number;
        /** @format int32 */
        bankAccountId?: number;
        /** @format date-time */
        startTo?: string;
        /** @format date-time */
        endTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/GetPaymentProviders`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderCreatePaymentProviderCreate
     * @request POST:/Purchase/PaymentProvider/CreatePaymentProvider
     * @secure
     */
    paymentProviderCreatePaymentProviderCreate: (data: PaymentProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/CreatePaymentProvider`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderUpdatePaymentProviderUpdate
     * @request PUT:/Purchase/PaymentProvider/UpdatePaymentProvider
     * @secure
     */
    paymentProviderUpdatePaymentProviderUpdate: (data: PaymentProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/UpdatePaymentProvider`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderUpdateInvoicePaymentProviderUpdate
     * @request PUT:/Purchase/PaymentProvider/UpdateInvoicePaymentProvider
     * @secure
     */
    paymentProviderUpdateInvoicePaymentProviderUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        paymentProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/UpdateInvoicePaymentProvider`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderUpdateCreditPaymentProviderUpdate
     * @request PUT:/Purchase/PaymentProvider/UpdateCreditPaymentProvider
     * @secure
     */
    paymentProviderUpdateCreditPaymentProviderUpdate: (
      data: Record<string, number>,
      query?: {
        /** @format int32 */
        paymentProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/UpdateCreditPaymentProvider`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderUnlinkCreditProviderPaymentProviderUpdate
     * @request PUT:/Purchase/PaymentProvider/UnlinkCreditProviderPaymentProvider
     * @secure
     */
    paymentProviderUnlinkCreditProviderPaymentProviderUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        paymentProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/UnlinkCreditProviderPaymentProvider`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderUnlinkInvoiceProviderPaymentProviderUpdate
     * @request PUT:/Purchase/PaymentProvider/UnlinkInvoiceProviderPaymentProvider
     * @secure
     */
    paymentProviderUnlinkInvoiceProviderPaymentProviderUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        paymentProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/UnlinkInvoiceProviderPaymentProvider`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PaymentProvider
     * @name PaymentProviderDeleteDelete
     * @request DELETE:/Purchase/PaymentProvider/Delete
     * @secure
     */
    paymentProviderDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PaymentProvider/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderGetProductProvidersList
     * @request GET:/Purchase/ProductProvider/GetProductProviders
     * @secure
     */
    productProviderGetProductProvidersList: (
      query?: {
        /** @format int32 */
        companyId?: number;
        /** @format int32 */
        productId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/GetProductProviders`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderGetProductProviderByIdList
     * @request GET:/Purchase/ProductProvider/GetProductProviderById
     * @secure
     */
    productProviderGetProductProviderByIdList: (
      query?: {
        /** @format int32 */
        productProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/GetProductProviderById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderGetProductProvidersArchivedList
     * @request GET:/Purchase/ProductProvider/GetProductProvidersArchived
     * @secure
     */
    productProviderGetProductProvidersArchivedList: (
      query?: {
        /** @format int32 */
        companyId?: number;
        /** @format int32 */
        productId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/GetProductProvidersArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderGetProductProviderArchivedByIdList
     * @request GET:/Purchase/ProductProvider/GetProductProviderArchivedById
     * @secure
     */
    productProviderGetProductProviderArchivedByIdList: (
      query?: {
        /** @format int32 */
        productProviderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/GetProductProviderArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderCreateProductProviderCreate
     * @request POST:/Purchase/ProductProvider/CreateProductProvider
     * @secure
     */
    productProviderCreateProductProviderCreate: (data: ProductProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/CreateProductProvider`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderUpdateProductProviderUpdate
     * @request PUT:/Purchase/ProductProvider/UpdateProductProvider
     * @secure
     */
    productProviderUpdateProductProviderUpdate: (data: ProductProviderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/UpdateProductProvider`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderRestoreUpdate
     * @request PUT:/Purchase/ProductProvider/Restore
     * @secure
     */
    productProviderRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderArchiveDelete
     * @request DELETE:/Purchase/ProductProvider/Archive
     * @secure
     */
    productProviderArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductProvider
     * @name ProductProviderDeleteDelete
     * @request DELETE:/Purchase/ProductProvider/Delete
     * @secure
     */
    productProviderDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ProductProvider/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryGetPurchaseCategoriesList
     * @request GET:/Purchase/PurchaseCategory/GetPurchaseCategories
     * @secure
     */
    purchaseCategoryGetPurchaseCategoriesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/GetPurchaseCategories`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryGetPurchaseCategoryByIdList
     * @request GET:/Purchase/PurchaseCategory/GetPurchaseCategoryById
     * @secure
     */
    purchaseCategoryGetPurchaseCategoryByIdList: (
      query?: {
        /** @format int32 */
        purchaseCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/GetPurchaseCategoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryGetPurchaseCategoriesTreeList
     * @request GET:/Purchase/PurchaseCategory/GetPurchaseCategoriesTree
     * @secure
     */
    purchaseCategoryGetPurchaseCategoriesTreeList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/GetPurchaseCategoriesTree`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryGetPurchaseCategoriesArchivedList
     * @request GET:/Purchase/PurchaseCategory/GetPurchaseCategoriesArchived
     * @secure
     */
    purchaseCategoryGetPurchaseCategoriesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/GetPurchaseCategoriesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryGetPurchaseCategoryArchivedByIdList
     * @request GET:/Purchase/PurchaseCategory/GetPurchaseCategoryArchivedById
     * @secure
     */
    purchaseCategoryGetPurchaseCategoryArchivedByIdList: (
      query?: {
        /** @format int32 */
        purchaseCategoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/GetPurchaseCategoryArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryCreatePurchaseCategoryCreate
     * @request POST:/Purchase/PurchaseCategory/CreatePurchaseCategory
     * @secure
     */
    purchaseCategoryCreatePurchaseCategoryCreate: (data: PurchaseCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/CreatePurchaseCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryUpdatePurchaseCategoryUpdate
     * @request PUT:/Purchase/PurchaseCategory/UpdatePurchaseCategory
     * @secure
     */
    purchaseCategoryUpdatePurchaseCategoryUpdate: (data: PurchaseCategoryRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/UpdatePurchaseCategory`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryRestoreUpdate
     * @request PUT:/Purchase/PurchaseCategory/Restore
     * @secure
     */
    purchaseCategoryRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryArchiveDelete
     * @request DELETE:/Purchase/PurchaseCategory/Archive
     * @secure
     */
    purchaseCategoryArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PurchaseCategory
     * @name PurchaseCategoryDeleteDelete
     * @request DELETE:/Purchase/PurchaseCategory/Delete
     * @secure
     */
    purchaseCategoryDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/PurchaseCategory/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagGetQuoteTagsList
     * @request GET:/Purchase/QuoteTag/GetQuoteTags
     * @secure
     */
    quoteTagGetQuoteTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/GetQuoteTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagGetQuoteTagByIdList
     * @request GET:/Purchase/QuoteTag/GetQuoteTagById
     * @secure
     */
    quoteTagGetQuoteTagByIdList: (
      query?: {
        /** @format int32 */
        quoteTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/GetQuoteTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagGetQuoteTagsArchivedList
     * @request GET:/Purchase/QuoteTag/GetQuoteTagsArchived
     * @secure
     */
    quoteTagGetQuoteTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/GetQuoteTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagGetQuoteTagArchivedByIdList
     * @request GET:/Purchase/QuoteTag/GetQuoteTagArchivedById
     * @secure
     */
    quoteTagGetQuoteTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        quoteTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/GetQuoteTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagCreateQuoteTagCreate
     * @request POST:/Purchase/QuoteTag/CreateQuoteTag
     * @secure
     */
    quoteTagCreateQuoteTagCreate: (data: QuoteTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/CreateQuoteTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagUpdateQuoteTagUpdate
     * @request PUT:/Purchase/QuoteTag/UpdateQuoteTag
     * @secure
     */
    quoteTagUpdateQuoteTagUpdate: (data: QuoteTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/UpdateQuoteTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagRestoreUpdate
     * @request PUT:/Purchase/QuoteTag/Restore
     * @secure
     */
    quoteTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagArchiveDelete
     * @request DELETE:/Purchase/QuoteTag/Archive
     * @secure
     */
    quoteTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QuoteTag
     * @name QuoteTagDeleteDelete
     * @request DELETE:/Purchase/QuoteTag/Delete
     * @secure
     */
    quoteTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/QuoteTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormGetReceiptFormsList
     * @request GET:/Purchase/ReceiptForm/GetReceiptForms
     * @secure
     */
    receiptFormGetReceiptFormsList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
        /** @format int32 */
        companyId?: number;
        isProvider?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/GetReceiptForms`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormGetReceiptFormByIdList
     * @request GET:/Purchase/ReceiptForm/GetReceiptFormById
     * @secure
     */
    receiptFormGetReceiptFormByIdList: (
      query?: {
        /** @format int32 */
        receiptFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/GetReceiptFormById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormGetLastDocumentReferenceList
     * @request GET:/Purchase/ReceiptForm/GetLastDocumentReference
     * @secure
     */
    receiptFormGetLastDocumentReferenceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/GetLastDocumentReference`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormGetReceiptFormEmptyList
     * @request GET:/Purchase/ReceiptForm/GetReceiptFormEmpty
     * @secure
     */
    receiptFormGetReceiptFormEmptyList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format int32 */
        providerId?: number;
        /** @format int32 */
        userId?: number;
        /** @format int32 */
        customerId?: number;
        /** @format int32 */
        storageLocationId?: number;
        /** @format int32 */
        collaboratorId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/GetReceiptFormEmpty`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormGetReceiptFormOrderFormIdList
     * @request GET:/Purchase/ReceiptForm/GetReceiptFormOrderFormId
     * @secure
     */
    receiptFormGetReceiptFormOrderFormIdList: (
      query?: {
        /** @format int32 */
        orderFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/GetReceiptFormOrderFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormCreateReceiptFormCreate
     * @request POST:/Purchase/ReceiptForm/CreateReceiptForm
     * @secure
     */
    receiptFormCreateReceiptFormCreate: (data: ReceiptFormRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/CreateReceiptForm`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormCreateReceiptFormFromPreviousReceiptFormCreate
     * @request POST:/Purchase/ReceiptForm/CreateReceiptFormFromPreviousReceiptForm
     * @secure
     */
    receiptFormCreateReceiptFormFromPreviousReceiptFormCreate: (
      query?: {
        /** @format int32 */
        receiptFormId?: number;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/CreateReceiptFormFromPreviousReceiptForm`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormUpdateReceiptFormUpdate
     * @request PUT:/Purchase/ReceiptForm/UpdateReceiptForm
     * @secure
     */
    receiptFormUpdateReceiptFormUpdate: (data: ReceiptFormRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/UpdateReceiptForm`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormUploadPathDocumentUpdate
     * @request PUT:/Purchase/ReceiptForm/UploadPathDocument
     * @secure
     */
    receiptFormUploadPathDocumentUpdate: (
      query?: {
        /** @format int32 */
        receiptFormId?: number;
        path?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/UploadPathDocument`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormUploadFileUpdate
     * @request PUT:/Purchase/ReceiptForm/UploadFile
     * @secure
     */
    receiptFormUploadFileUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      query?: {
        /** @format int32 */
        receiptFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/UploadFile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormUpdateReceiptFormTagReceiptFormUpdate
     * @request PUT:/Purchase/ReceiptForm/UpdateReceiptFormTagReceiptForm
     * @secure
     */
    receiptFormUpdateReceiptFormTagReceiptFormUpdate: (
      data: ReceiptFormTagReceiptFormRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/UpdateReceiptFormTagReceiptForm`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptForm
     * @name ReceiptFormDeleteDelete
     * @request DELETE:/Purchase/ReceiptForm/Delete
     * @secure
     */
    receiptFormDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptForm/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormDetail
     * @name ReceiptFormDetailGetReceiptFormDetailsByReceiptFormIdList
     * @request GET:/Purchase/ReceiptFormDetail/GetReceiptFormDetailsByReceiptFormId
     * @secure
     */
    receiptFormDetailGetReceiptFormDetailsByReceiptFormIdList: (
      query?: {
        /** @format int32 */
        receiptFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormDetail/GetReceiptFormDetailsByReceiptFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormDetail
     * @name ReceiptFormDetailCreateReceiptFormDetailCreate
     * @request POST:/Purchase/ReceiptFormDetail/CreateReceiptFormDetail
     * @secure
     */
    receiptFormDetailCreateReceiptFormDetailCreate: (data: ReceiptFormDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormDetail/CreateReceiptFormDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormDetail
     * @name ReceiptFormDetailUpdateReceiptFormDetailUpdate
     * @request PUT:/Purchase/ReceiptFormDetail/UpdateReceiptFormDetail
     * @secure
     */
    receiptFormDetailUpdateReceiptFormDetailUpdate: (data: ReceiptFormDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormDetail/UpdateReceiptFormDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormDetail
     * @name ReceiptFormDetailUpdateReceiptDetailIndexUpdate
     * @request PUT:/Purchase/ReceiptFormDetail/UpdateReceiptDetailIndex
     * @secure
     */
    receiptFormDetailUpdateReceiptDetailIndexUpdate: (data: IndexRequestDto[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormDetail/UpdateReceiptDetailIndex`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormDetail
     * @name ReceiptFormDetailDeleteDelete
     * @request DELETE:/Purchase/ReceiptFormDetail/Delete
     * @secure
     */
    receiptFormDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormHistory
     * @name ReceiptFormHistoryGetReceiptFormHistoryByIdList
     * @request GET:/Purchase/ReceiptFormHistory/GetReceiptFormHistoryById
     * @secure
     */
    receiptFormHistoryGetReceiptFormHistoryByIdList: (
      query?: {
        /** @format int32 */
        receiptFormHistoryId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormHistory/GetReceiptFormHistoryById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormHistory
     * @name ReceiptFormHistoryGetReceiptFormHistoriesByReceiptFormIdList
     * @request GET:/Purchase/ReceiptFormHistory/GetReceiptFormHistoriesByReceiptFormId
     * @secure
     */
    receiptFormHistoryGetReceiptFormHistoriesByReceiptFormIdList: (
      query?: {
        /** @format int32 */
        receiptFormId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormHistory/GetReceiptFormHistoriesByReceiptFormId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagGetReceiptFormTagsList
     * @request GET:/Purchase/ReceiptFormTag/GetReceiptFormTags
     * @secure
     */
    receiptFormTagGetReceiptFormTagsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/GetReceiptFormTags`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagGetReceiptFormTagByIdList
     * @request GET:/Purchase/ReceiptFormTag/GetReceiptFormTagById
     * @secure
     */
    receiptFormTagGetReceiptFormTagByIdList: (
      query?: {
        /** @format int32 */
        receiptFormTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/GetReceiptFormTagById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagGetReceiptFormTagsArchivedList
     * @request GET:/Purchase/ReceiptFormTag/GetReceiptFormTagsArchived
     * @secure
     */
    receiptFormTagGetReceiptFormTagsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/GetReceiptFormTagsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagGetReceiptFormTagArchivedByIdList
     * @request GET:/Purchase/ReceiptFormTag/GetReceiptFormTagArchivedById
     * @secure
     */
    receiptFormTagGetReceiptFormTagArchivedByIdList: (
      query?: {
        /** @format int32 */
        receiptFormTagId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/GetReceiptFormTagArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagCreateReceiptFormTagCreate
     * @request POST:/Purchase/ReceiptFormTag/CreateReceiptFormTag
     * @secure
     */
    receiptFormTagCreateReceiptFormTagCreate: (data: ReceiptFormTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/CreateReceiptFormTag`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagUpdateReceiptFormTagUpdate
     * @request PUT:/Purchase/ReceiptFormTag/UpdateReceiptFormTag
     * @secure
     */
    receiptFormTagUpdateReceiptFormTagUpdate: (data: ReceiptFormTagRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/UpdateReceiptFormTag`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagRestoreUpdate
     * @request PUT:/Purchase/ReceiptFormTag/Restore
     * @secure
     */
    receiptFormTagRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagArchiveDelete
     * @request DELETE:/Purchase/ReceiptFormTag/Archive
     * @secure
     */
    receiptFormTagArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReceiptFormTag
     * @name ReceiptFormTagDeleteDelete
     * @request DELETE:/Purchase/ReceiptFormTag/Delete
     * @secure
     */
    receiptFormTagDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/ReceiptFormTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock
     * @name StockGetStocksList
     * @request GET:/Purchase/Stock/GetStocks
     * @secure
     */
    stockGetStocksList: (
      query?: {
        /** @format int32 */
        productId?: number;
        /** @format int32 */
        storageLocationId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Stock/GetStocks`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock
     * @name StockGetStocksOfProductByStorageLocationIdList
     * @request GET:/Purchase/Stock/GetStocksOfProductByStorageLocationId
     * @secure
     */
    stockGetStocksOfProductByStorageLocationIdList: (
      query?: {
        /** @format int32 */
        productId?: number;
        /** @format int32 */
        storageLocationId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Stock/GetStocksOfProductByStorageLocationId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock
     * @name StockGetStockByIdList
     * @request GET:/Purchase/Stock/GetStockById
     * @secure
     */
    stockGetStockByIdList: (
      query?: {
        /** @format int32 */
        stockId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/Stock/GetStockById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock
     * @name StockCreateStockCreate
     * @request POST:/Purchase/Stock/CreateStock
     * @secure
     */
    stockCreateStockCreate: (data: StockRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Stock/CreateStock`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock
     * @name StockUpdateStockUpdate
     * @request PUT:/Purchase/Stock/UpdateStock
     * @secure
     */
    stockUpdateStockUpdate: (data: StockRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Stock/UpdateStock`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stock
     * @name StockDeleteDelete
     * @request DELETE:/Purchase/Stock/Delete
     * @secure
     */
    stockDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/Stock/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockMovement
     * @name StockMovementGetStockMovementsList
     * @request GET:/Purchase/StockMovement/GetStockMovements
     * @secure
     */
    stockMovementGetStockMovementsList: (
      query?: {
        /** @format date-time */
        startTo?: string;
        /** @format date-time */
        endTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/StockMovement/GetStockMovements`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockMovement
     * @name StockMovementGetStockMovementByIdList
     * @request GET:/Purchase/StockMovement/GetStockMovementById
     * @secure
     */
    stockMovementGetStockMovementByIdList: (
      query?: {
        /** @format int32 */
        stockMovementId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/StockMovement/GetStockMovementById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationGetStorageLocationsList
     * @request GET:/Purchase/StorageLocation/GetStorageLocations
     * @secure
     */
    storageLocationGetStorageLocationsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/GetStorageLocations`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationGetStorageLocationByIdList
     * @request GET:/Purchase/StorageLocation/GetStorageLocationById
     * @secure
     */
    storageLocationGetStorageLocationByIdList: (
      query?: {
        /** @format int32 */
        storageLocationId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/GetStorageLocationById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationGetStorageLocationsArchivedList
     * @request GET:/Purchase/StorageLocation/GetStorageLocationsArchived
     * @secure
     */
    storageLocationGetStorageLocationsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/GetStorageLocationsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationGetStorageLocationArchivedByIdList
     * @request GET:/Purchase/StorageLocation/GetStorageLocationArchivedById
     * @secure
     */
    storageLocationGetStorageLocationArchivedByIdList: (
      query?: {
        /** @format int32 */
        storageLocationId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/GetStorageLocationArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationCreateStorageLocationCreate
     * @request POST:/Purchase/StorageLocation/CreateStorageLocation
     * @secure
     */
    storageLocationCreateStorageLocationCreate: (data: StorageLocationRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/CreateStorageLocation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationUpdateStorageLocationUpdate
     * @request PUT:/Purchase/StorageLocation/UpdateStorageLocation
     * @secure
     */
    storageLocationUpdateStorageLocationUpdate: (data: StorageLocationRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/UpdateStorageLocation`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationRestoreUpdate
     * @request PUT:/Purchase/StorageLocation/Restore
     * @secure
     */
    storageLocationRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationArchiveDelete
     * @request DELETE:/Purchase/StorageLocation/Archive
     * @secure
     */
    storageLocationArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags StorageLocation
     * @name StorageLocationDeleteDelete
     * @request DELETE:/Purchase/StorageLocation/Delete
     * @secure
     */
    storageLocationDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Purchase/StorageLocation/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  account = {
    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterGetCustomPerimetersList
     * @request GET:/Account/CustomPerimeter/GetCustomPerimeters
     * @secure
     */
    customPerimeterGetCustomPerimetersList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/GetCustomPerimeters`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterGetCustomPerimeterByIdList
     * @request GET:/Account/CustomPerimeter/GetCustomPerimeterById
     * @secure
     */
    customPerimeterGetCustomPerimeterByIdList: (
      query?: {
        /** @format int32 */
        customPerimeterId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/GetCustomPerimeterById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterGetCustomPerimetersArchivedList
     * @request GET:/Account/CustomPerimeter/GetCustomPerimetersArchived
     * @secure
     */
    customPerimeterGetCustomPerimetersArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/GetCustomPerimetersArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterGetCustomPerimeterArchivedByIdList
     * @request GET:/Account/CustomPerimeter/GetCustomPerimeterArchivedById
     * @secure
     */
    customPerimeterGetCustomPerimeterArchivedByIdList: (
      query?: {
        /** @format int32 */
        customPerimeterId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/GetCustomPerimeterArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterCreateCustomPerimeterCreate
     * @request POST:/Account/CustomPerimeter/CreateCustomPerimeter
     * @secure
     */
    customPerimeterCreateCustomPerimeterCreate: (data: CustomPerimeterRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/CreateCustomPerimeter`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterUpdateCustomPerimeterUpdate
     * @request PUT:/Account/CustomPerimeter/UpdateCustomPerimeter
     * @secure
     */
    customPerimeterUpdateCustomPerimeterUpdate: (data: CustomPerimeterRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/UpdateCustomPerimeter`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterRestoreUpdate
     * @request PUT:/Account/CustomPerimeter/Restore
     * @secure
     */
    customPerimeterRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterArchiveDelete
     * @request DELETE:/Account/CustomPerimeter/Archive
     * @secure
     */
    customPerimeterArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomPerimeter
     * @name CustomPerimeterDeleteDelete
     * @request DELETE:/Account/CustomPerimeter/Delete
     * @secure
     */
    customPerimeterDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/CustomPerimeter/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Function
     * @name FunctionGetFunctionsList
     * @request GET:/Account/Function/GetFunctions
     * @secure
     */
    functionGetFunctionsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Function/GetFunctions`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Function
     * @name FunctionGetFunctionByIdList
     * @request GET:/Account/Function/GetFunctionById
     * @secure
     */
    functionGetFunctionByIdList: (
      query?: {
        /** @format int32 */
        functionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Function/GetFunctionById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FunctionGroup
     * @name FunctionGroupGetFunctionGroupsList
     * @request GET:/Account/FunctionGroup/GetFunctionGroups
     * @secure
     */
    functionGroupGetFunctionGroupsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/FunctionGroup/GetFunctionGroups`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FunctionGroup
     * @name FunctionGroupGetFunctionGroupByIdList
     * @request GET:/Account/FunctionGroup/GetFunctionGroupById
     * @secure
     */
    functionGroupGetFunctionGroupByIdList: (
      query?: {
        /** @format int32 */
        functionGroupId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/FunctionGroup/GetFunctionGroupById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionGetInstitutionList
     * @request GET:/Account/Institution/GetInstitution
     * @secure
     */
    institutionGetInstitutionList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/GetInstitution`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionGetInstitutionMinifiedList
     * @request GET:/Account/Institution/GetInstitutionMinified
     * @secure
     */
    institutionGetInstitutionMinifiedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/GetInstitutionMinified`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionGetInstitutionsArchivedList
     * @request GET:/Account/Institution/GetInstitutionsArchived
     * @secure
     */
    institutionGetInstitutionsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/GetInstitutionsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionCreateInstitutionCreate
     * @request POST:/Account/Institution/CreateInstitution
     * @secure
     */
    institutionCreateInstitutionCreate: (data: InstitutionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/CreateInstitution`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionUpdateInstitutionUpdate
     * @request PUT:/Account/Institution/UpdateInstitution
     * @secure
     */
    institutionUpdateInstitutionUpdate: (data: InstitutionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/UpdateInstitution`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionUploadLogoUpdate
     * @request PUT:/Account/Institution/UploadLogo
     * @secure
     */
    institutionUploadLogoUpdate: (
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Institution/UploadLogo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionUpdateInstitutionInstitutionPerimeterUpdate
     * @request PUT:/Account/Institution/UpdateInstitutionInstitutionPerimeter
     * @secure
     */
    institutionUpdateInstitutionInstitutionPerimeterUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/UpdateInstitutionInstitutionPerimeter`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionRestoreUpdate
     * @request PUT:/Account/Institution/Restore
     * @secure
     */
    institutionRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionArchiveDelete
     * @request DELETE:/Account/Institution/Archive
     * @secure
     */
    institutionArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Institution
     * @name InstitutionDeleteDelete
     * @request DELETE:/Account/Institution/Delete
     * @secure
     */
    institutionDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Institution/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterGetInstitutionPerimetersList
     * @request GET:/Account/InstitutionPerimeter/GetInstitutionPerimeters
     * @secure
     */
    institutionPerimeterGetInstitutionPerimetersList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/GetInstitutionPerimeters`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterGetInstitutionPerimeterByIdList
     * @request GET:/Account/InstitutionPerimeter/GetInstitutionPerimeterById
     * @secure
     */
    institutionPerimeterGetInstitutionPerimeterByIdList: (
      query?: {
        /** @format int32 */
        InstitutionPerimeterId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/GetInstitutionPerimeterById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterGetInstitutionPerimetersArchivedList
     * @request GET:/Account/InstitutionPerimeter/GetInstitutionPerimetersArchived
     * @secure
     */
    institutionPerimeterGetInstitutionPerimetersArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/GetInstitutionPerimetersArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterGetInstitutionPerimeterArchivedByIdList
     * @request GET:/Account/InstitutionPerimeter/GetInstitutionPerimeterArchivedById
     * @secure
     */
    institutionPerimeterGetInstitutionPerimeterArchivedByIdList: (
      query?: {
        /** @format int32 */
        InstitutionPerimeterId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/GetInstitutionPerimeterArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterCreateInstitutionPerimeterCreate
     * @request POST:/Account/InstitutionPerimeter/CreateInstitutionPerimeter
     * @secure
     */
    institutionPerimeterCreateInstitutionPerimeterCreate: (
      data: InstitutionPerimeterRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/CreateInstitutionPerimeter`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterUpdateInstitutionPerimeterUpdate
     * @request PUT:/Account/InstitutionPerimeter/UpdateInstitutionPerimeter
     * @secure
     */
    institutionPerimeterUpdateInstitutionPerimeterUpdate: (
      data: InstitutionPerimeterRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/UpdateInstitutionPerimeter`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterRestoreUpdate
     * @request PUT:/Account/InstitutionPerimeter/Restore
     * @secure
     */
    institutionPerimeterRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterArchiveDelete
     * @request DELETE:/Account/InstitutionPerimeter/Archive
     * @secure
     */
    institutionPerimeterArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InstitutionPerimeter
     * @name InstitutionPerimeterDeleteDelete
     * @request DELETE:/Account/InstitutionPerimeter/Delete
     * @secure
     */
    institutionPerimeterDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/InstitutionPerimeter/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Module
     * @name ModuleGetModulesList
     * @request GET:/Account/Module/GetModules
     * @secure
     */
    moduleGetModulesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Module/GetModules`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Module
     * @name ModuleGetModuleByIdList
     * @request GET:/Account/Module/GetModuleById
     * @secure
     */
    moduleGetModuleByIdList: (
      query?: {
        /** @format int32 */
        moduleId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Module/GetModuleById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Module
     * @name ModuleCreateModuleCreate
     * @request POST:/Account/Module/CreateModule
     * @secure
     */
    moduleCreateModuleCreate: (data: ModuleRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Module/CreateModule`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Module
     * @name ModuleUpdateModuleUpdate
     * @request PUT:/Account/Module/UpdateModule
     * @secure
     */
    moduleUpdateModuleUpdate: (data: ModuleRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Module/UpdateModule`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Module
     * @name ModuleDeleteDelete
     * @request DELETE:/Account/Module/Delete
     * @secure
     */
    moduleDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Module/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ModuleParameter
     * @name ModuleParameterGetModuleParameterByIdList
     * @request GET:/Account/ModuleParameter/GetModuleParameterById
     * @secure
     */
    moduleParameterGetModuleParameterByIdList: (
      query?: {
        /** @format int32 */
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/ModuleParameter/GetModuleParameterById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ModuleParameter
     * @name ModuleParameterGetModuleParametersList
     * @request GET:/Account/ModuleParameter/GetModuleParameters
     * @secure
     */
    moduleParameterGetModuleParametersList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/ModuleParameter/GetModuleParameters`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ModuleParameter
     * @name ModuleParameterGetModuleParameterByModuleIdList
     * @request GET:/Account/ModuleParameter/GetModuleParameterByModuleId
     * @secure
     */
    moduleParameterGetModuleParameterByModuleIdList: (
      query?: {
        /** @format int32 */
        moduleId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/ModuleParameter/GetModuleParameterByModuleId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ModuleParameter
     * @name ModuleParameterCreateModuleParameterCreate
     * @request POST:/Account/ModuleParameter/CreateModuleParameter
     * @secure
     */
    moduleParameterCreateModuleParameterCreate: (data: ModuleParameterRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/ModuleParameter/CreateModuleParameter`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ModuleParameter
     * @name ModuleParameterUpdateModuleParameterUpdate
     * @request PUT:/Account/ModuleParameter/UpdateModuleParameter
     * @secure
     */
    moduleParameterUpdateModuleParameterUpdate: (data: ModuleParameterRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/ModuleParameter/UpdateModuleParameter`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ModuleParameter
     * @name ModuleParameterDeleteDelete
     * @request DELETE:/Account/ModuleParameter/Delete
     * @secure
     */
    moduleParameterDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/ModuleParameter/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileGetProfilesList
     * @request GET:/Account/Profile/GetProfiles
     * @secure
     */
    profileGetProfilesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Profile/GetProfiles`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileGetProfileByIdList
     * @request GET:/Account/Profile/GetProfileById
     * @secure
     */
    profileGetProfileByIdList: (
      query?: {
        /** @format int32 */
        profileId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Profile/GetProfileById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileGetProfilesArchivedList
     * @request GET:/Account/Profile/GetProfilesArchived
     * @secure
     */
    profileGetProfilesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Profile/GetProfilesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileGetProfileArchivedByIdList
     * @request GET:/Account/Profile/GetProfileArchivedById
     * @secure
     */
    profileGetProfileArchivedByIdList: (
      query?: {
        /** @format int32 */
        profileId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Profile/GetProfileArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileCreateProfileCreate
     * @request POST:/Account/Profile/CreateProfile
     * @secure
     */
    profileCreateProfileCreate: (data: ProfileRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Profile/CreateProfile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileUpdateProfileUpdate
     * @request PUT:/Account/Profile/UpdateProfile
     * @secure
     */
    profileUpdateProfileUpdate: (data: ProfileRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Profile/UpdateProfile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileUpdateProfileUserUpdate
     * @request PUT:/Account/Profile/UpdateProfileUser
     * @secure
     */
    profileUpdateProfileUserUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        profileId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Profile/UpdateProfileUser`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileUpdateProfileFunctionUpdate
     * @request PUT:/Account/Profile/UpdateProfileFunction
     * @secure
     */
    profileUpdateProfileFunctionUpdate: (
      data: ProfileFunctionRequestDto[],
      query?: {
        /** @format int32 */
        profileId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Profile/UpdateProfileFunction`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileRestoreUpdate
     * @request PUT:/Account/Profile/Restore
     * @secure
     */
    profileRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Profile/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileArchiveDelete
     * @request DELETE:/Account/Profile/Archive
     * @secure
     */
    profileArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Profile/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name ProfileDeleteDelete
     * @request DELETE:/Account/Profile/Delete
     * @secure
     */
    profileDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Profile/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUsersList
     * @request GET:/Account/User/GetUsers
     * @secure
     */
    userGetUsersList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/GetUsers`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserByWorkspaceList
     * @request GET:/Account/User/GetUserByWorkspace
     * @secure
     */
    userGetUserByWorkspaceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/GetUserByWorkspace`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserByIdList
     * @request GET:/Account/User/GetUserById
     * @secure
     */
    userGetUserByIdList: (
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/User/GetUserById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserInfoConnectedList
     * @request GET:/Account/User/GetUserInfoConnected
     * @secure
     */
    userGetUserInfoConnectedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/GetUserInfoConnected`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserCreateUserCreate
     * @request POST:/Account/User/CreateUser
     * @secure
     */
    userCreateUserCreate: (data: RegisterRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/CreateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserUpdateUserUpdate
     * @request PUT:/Account/User/UpdateUser
     * @secure
     */
    userUpdateUserUpdate: (data: UserRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/UpdateUser`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserActiveUserAccountsUpdate
     * @request PUT:/Account/User/ActiveUserAccounts
     * @secure
     */
    userActiveUserAccountsUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/ActiveUserAccounts`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserDisableUserAccountsUpdate
     * @request PUT:/Account/User/DisableUserAccounts
     * @secure
     */
    userDisableUserAccountsUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/DisableUserAccounts`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserUpdateUserProfileUpdate
     * @request PUT:/Account/User/UpdateUserProfile
     * @secure
     */
    userUpdateUserProfileUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/User/UpdateUserProfile`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserUpdateUserWorkspaceUpdate
     * @request PUT:/Account/User/UpdateUserWorkspace
     * @secure
     */
    userUpdateUserWorkspaceUpdate: (
      data: Record<string, boolean>,
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/User/UpdateUserWorkspace`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserUpdateUserRoleUpdate
     * @request PUT:/Account/User/UpdateUserRole
     * @secure
     */
    userUpdateUserRoleUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/User/UpdateUserRole`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserUpdateUserCustomPerimeterUpdate
     * @request PUT:/Account/User/UpdateUserCustomPerimeter
     * @secure
     */
    userUpdateUserCustomPerimeterUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/User/UpdateUserCustomPerimeter`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserUpdateUserInstitutionPerimeterUpdate
     * @request PUT:/Account/User/UpdateUserInstitutionPerimeter
     * @secure
     */
    userUpdateUserInstitutionPerimeterUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/User/UpdateUserInstitutionPerimeter`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUserArchivedByIdList
     * @request GET:/Account/User/GetUserArchivedById
     * @secure
     */
    userGetUserArchivedByIdList: (
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/User/GetUserArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserGetUsersArchivedList
     * @request GET:/Account/User/GetUsersArchived
     * @secure
     */
    userGetUsersArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/GetUsersArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserRestoreUpdate
     * @request PUT:/Account/User/Restore
     * @secure
     */
    userRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserArchiveDelete
     * @request DELETE:/Account/User/Archive
     * @secure
     */
    userArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserDeleteDelete
     * @request DELETE:/Account/User/Delete
     * @secure
     */
    userDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/User/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserParameter
     * @name UserParameterGetUserParametersByUserIdList
     * @request GET:/Account/UserParameter/GetUserParametersByUserId
     * @secure
     */
    userParameterGetUserParametersByUserIdList: (
      query?: {
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/UserParameter/GetUserParametersByUserId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserParameter
     * @name UserParameterGetUserParameterByKeyList
     * @request GET:/Account/UserParameter/GetUserParameterByKey
     * @secure
     */
    userParameterGetUserParameterByKeyList: (
      query?: {
        key?: string;
        /** @format int32 */
        userId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/UserParameter/GetUserParameterByKey`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserParameter
     * @name UserParameterCreateOrUpdateUserParameterUpdate
     * @request PUT:/Account/UserParameter/CreateOrUpdateUserParameter
     * @secure
     */
    userParameterCreateOrUpdateUserParameterUpdate: (data: UserParameterRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/UserParameter/CreateOrUpdateUserParameter`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserParameter
     * @name UserParameterDeleteDelete
     * @request DELETE:/Account/UserParameter/Delete
     * @secure
     */
    userParameterDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/UserParameter/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceGetWorkspacesList
     * @request GET:/Account/Workspace/GetWorkspaces
     * @secure
     */
    workspaceGetWorkspacesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Workspace/GetWorkspaces`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceGetWorkspaceList
     * @request GET:/Account/Workspace/GetWorkspace
     * @secure
     */
    workspaceGetWorkspaceList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Workspace/GetWorkspace`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceCreateWorkspaceCreate
     * @request POST:/Account/Workspace/CreateWorkspace
     * @secure
     */
    workspaceCreateWorkspaceCreate: (data: WorkspaceRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Workspace/CreateWorkspace`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceUpdateWorkspaceUpdate
     * @request PUT:/Account/Workspace/UpdateWorkspace
     * @secure
     */
    workspaceUpdateWorkspaceUpdate: (data: WorkspaceRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Workspace/UpdateWorkspace`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceUpdateWorkspaceModuleUpdate
     * @request PUT:/Account/Workspace/UpdateWorkspaceModule
     * @secure
     */
    workspaceUpdateWorkspaceModuleUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        workspaceId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Account/Workspace/UpdateWorkspaceModule`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceDeleteDelete
     * @request DELETE:/Account/Workspace/Delete
     * @secure
     */
    workspaceDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Account/Workspace/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  management = {
    /**
     * No description
     *
     * @tags Equipment
     * @name EquipmentGetEquipmentsList
     * @request GET:/Management/Equipment/GetEquipments
     * @secure
     */
    equipmentGetEquipmentsList: (
      query?: {
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Equipment/GetEquipments`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipment
     * @name EquipmentGetEquipmentByIdList
     * @request GET:/Management/Equipment/GetEquipmentById
     * @secure
     */
    equipmentGetEquipmentByIdList: (
      query?: {
        /** @format int32 */
        equipmentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Equipment/GetEquipmentById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipment
     * @name EquipmentCreateEquipmentCreate
     * @request POST:/Management/Equipment/CreateEquipment
     * @secure
     */
    equipmentCreateEquipmentCreate: (data: EquipmentRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Equipment/CreateEquipment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipment
     * @name EquipmentUpdateEquipmentUpdate
     * @request PUT:/Management/Equipment/UpdateEquipment
     * @secure
     */
    equipmentUpdateEquipmentUpdate: (data: EquipmentRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Equipment/UpdateEquipment`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipment
     * @name EquipmentDeleteDelete
     * @request DELETE:/Management/Equipment/Delete
     * @secure
     */
    equipmentDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Equipment/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseGetLeasesList
     * @request GET:/Management/Lease/GetLeases
     * @secure
     */
    leaseGetLeasesList: (
      query?: {
        /** @format int32 */
        affairId?: number;
        /** @format date-time */
        dateFrom?: string;
        /** @format date-time */
        dateTo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Lease/GetLeases`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseGetLeaseByIdList
     * @request GET:/Management/Lease/GetLeaseById
     * @secure
     */
    leaseGetLeaseByIdList: (
      query?: {
        /** @format int32 */
        leaseId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Lease/GetLeaseById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseGetLeaseByAffairIdList
     * @request GET:/Management/Lease/GetLeaseByAffairId
     * @secure
     */
    leaseGetLeaseByAffairIdList: (
      query?: {
        /** @format int32 */
        affairId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Lease/GetLeaseByAffairId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseGetLeaseEmptyList
     * @request GET:/Management/Lease/GetLeaseEmpty
     * @secure
     */
    leaseGetLeaseEmptyList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Lease/GetLeaseEmpty`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseGetNextLeaseByLeaseIdList
     * @request GET:/Management/Lease/GetNextLeaseByLeaseId
     * @secure
     */
    leaseGetNextLeaseByLeaseIdList: (
      query?: {
        /** @format int32 */
        leaseId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Lease/GetNextLeaseByLeaseId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseCreateLeaseCreate
     * @request POST:/Management/Lease/CreateLease
     * @secure
     */
    leaseCreateLeaseCreate: (data: LeaseRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Lease/CreateLease`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseDuplicateLeaseCreate
     * @request POST:/Management/Lease/DuplicateLease
     * @secure
     */
    leaseDuplicateLeaseCreate: (
      query?: {
        /** @format int32 */
        leaseId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Lease/DuplicateLease`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseUpdateLeaseUpdate
     * @request PUT:/Management/Lease/UpdateLease
     * @secure
     */
    leaseUpdateLeaseUpdate: (data: LeaseRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Lease/UpdateLease`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseGetLeaseArchivedByIdList
     * @request GET:/Management/Lease/GetLeaseArchivedById
     * @secure
     */
    leaseGetLeaseArchivedByIdList: (
      query?: {
        /** @format int32 */
        leaseId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Lease/GetLeaseArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseGetAffairsArchivedList
     * @request GET:/Management/Lease/GetAffairsArchived
     * @secure
     */
    leaseGetAffairsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Lease/GetAffairsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseRestoreUpdate
     * @request PUT:/Management/Lease/Restore
     * @secure
     */
    leaseRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Lease/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseArchiveDelete
     * @request DELETE:/Management/Lease/Archive
     * @secure
     */
    leaseArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Lease/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lease
     * @name LeaseDeleteDelete
     * @request DELETE:/Management/Lease/Delete
     * @secure
     */
    leaseDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Lease/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeGetLeaseTypesList
     * @request GET:/Management/LeaseType/GetLeaseTypes
     * @secure
     */
    leaseTypeGetLeaseTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/LeaseType/GetLeaseTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeGetLeaseTypeByIdList
     * @request GET:/Management/LeaseType/GetLeaseTypeById
     * @secure
     */
    leaseTypeGetLeaseTypeByIdList: (
      query?: {
        /** @format int32 */
        LeaseTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/LeaseType/GetLeaseTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeGetLeaseTypeArchivedByIdList
     * @request GET:/Management/LeaseType/GetLeaseTypeArchivedById
     * @secure
     */
    leaseTypeGetLeaseTypeArchivedByIdList: (
      query?: {
        /** @format int32 */
        LeaseTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/LeaseType/GetLeaseTypeArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeGetLeaseTypesArchivedList
     * @request GET:/Management/LeaseType/GetLeaseTypesArchived
     * @secure
     */
    leaseTypeGetLeaseTypesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/LeaseType/GetLeaseTypesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeCreateLeaseTypeCreate
     * @request POST:/Management/LeaseType/CreateLeaseType
     * @secure
     */
    leaseTypeCreateLeaseTypeCreate: (data: LeaseTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/LeaseType/CreateLeaseType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeUpdateLeaseTypeUpdate
     * @request PUT:/Management/LeaseType/UpdateLeaseType
     * @secure
     */
    leaseTypeUpdateLeaseTypeUpdate: (data: LeaseTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/LeaseType/UpdateLeaseType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeRestoreUpdate
     * @request PUT:/Management/LeaseType/Restore
     * @secure
     */
    leaseTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/LeaseType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeArchiveDelete
     * @request DELETE:/Management/LeaseType/Archive
     * @secure
     */
    leaseTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/LeaseType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LeaseType
     * @name LeaseTypeDeleteDelete
     * @request DELETE:/Management/LeaseType/Delete
     * @secure
     */
    leaseTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/LeaseType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityGetPeriodicitiesList
     * @request GET:/Management/Periodicity/GetPeriodicities
     * @secure
     */
    periodicityGetPeriodicitiesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Periodicity/GetPeriodicities`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityGetPeriodicityByIdList
     * @request GET:/Management/Periodicity/GetPeriodicityById
     * @secure
     */
    periodicityGetPeriodicityByIdList: (
      query?: {
        /** @format int32 */
        periodicityId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Periodicity/GetPeriodicityById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityGetPeriodicityArchivedByIdList
     * @request GET:/Management/Periodicity/GetPeriodicityArchivedById
     * @secure
     */
    periodicityGetPeriodicityArchivedByIdList: (
      query?: {
        /** @format int32 */
        Id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Periodicity/GetPeriodicityArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityGetPeriodicitiesArchivedList
     * @request GET:/Management/Periodicity/GetPeriodicitiesArchived
     * @secure
     */
    periodicityGetPeriodicitiesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Periodicity/GetPeriodicitiesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityCreatePeriodicityCreate
     * @request POST:/Management/Periodicity/CreatePeriodicity
     * @secure
     */
    periodicityCreatePeriodicityCreate: (data: PeriodicityRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Periodicity/CreatePeriodicity`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityUpdatePeriodicityUpdate
     * @request PUT:/Management/Periodicity/UpdatePeriodicity
     * @secure
     */
    periodicityUpdatePeriodicityUpdate: (data: PeriodicityRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Periodicity/UpdatePeriodicity`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityRestoreUpdate
     * @request PUT:/Management/Periodicity/Restore
     * @secure
     */
    periodicityRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Periodicity/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityArchiveDelete
     * @request DELETE:/Management/Periodicity/Archive
     * @secure
     */
    periodicityArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Periodicity/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Periodicity
     * @name PeriodicityDeleteDelete
     * @request DELETE:/Management/Periodicity/Delete
     * @secure
     */
    periodicityDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Periodicity/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyGetPropertiesList
     * @request GET:/Management/Property/GetProperties
     * @secure
     */
    propertyGetPropertiesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Property/GetProperties`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyGetPropertyByIdList
     * @request GET:/Management/Property/GetPropertyById
     * @secure
     */
    propertyGetPropertyByIdList: (
      query?: {
        /** @format int32 */
        propertyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Property/GetPropertyById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyGetPropertyArchivedByIdList
     * @request GET:/Management/Property/GetPropertyArchivedById
     * @secure
     */
    propertyGetPropertyArchivedByIdList: (
      query?: {
        /** @format int32 */
        Id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/Property/GetPropertyArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyGetPropertiesArchivedList
     * @request GET:/Management/Property/GetPropertiesArchived
     * @secure
     */
    propertyGetPropertiesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Property/GetPropertiesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyCreatePropertyCreate
     * @request POST:/Management/Property/CreateProperty
     * @secure
     */
    propertyCreatePropertyCreate: (data: PropertyRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Property/CreateProperty`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyUpdatePropertyUpdate
     * @request PUT:/Management/Property/UpdateProperty
     * @secure
     */
    propertyUpdatePropertyUpdate: (data: PropertyRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Property/UpdateProperty`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyRestoreUpdate
     * @request PUT:/Management/Property/Restore
     * @secure
     */
    propertyRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Property/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyArchiveDelete
     * @request DELETE:/Management/Property/Archive
     * @secure
     */
    propertyArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Property/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Property
     * @name PropertyDeleteDelete
     * @request DELETE:/Management/Property/Delete
     * @secure
     */
    propertyDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/Property/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeGetPropertyTypesList
     * @request GET:/Management/PropertyType/GetPropertyTypes
     * @secure
     */
    propertyTypeGetPropertyTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/PropertyType/GetPropertyTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeGetPropertyTypeByIdList
     * @request GET:/Management/PropertyType/GetPropertyTypeById
     * @secure
     */
    propertyTypeGetPropertyTypeByIdList: (
      query?: {
        /** @format int32 */
        propertyTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/PropertyType/GetPropertyTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeGetPropertyTypeArchivedByIdList
     * @request GET:/Management/PropertyType/GetPropertyTypeArchivedById
     * @secure
     */
    propertyTypeGetPropertyTypeArchivedByIdList: (
      query?: {
        /** @format int32 */
        Id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Management/PropertyType/GetPropertyTypeArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeGetPropertyTypesArchivedList
     * @request GET:/Management/PropertyType/GetPropertyTypesArchived
     * @secure
     */
    propertyTypeGetPropertyTypesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/PropertyType/GetPropertyTypesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeCreatePropertyTypeCreate
     * @request POST:/Management/PropertyType/CreatePropertyType
     * @secure
     */
    propertyTypeCreatePropertyTypeCreate: (data: PropertyTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/PropertyType/CreatePropertyType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeUpdatePropertyTypeUpdate
     * @request PUT:/Management/PropertyType/UpdatePropertyType
     * @secure
     */
    propertyTypeUpdatePropertyTypeUpdate: (data: PropertyTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/PropertyType/UpdatePropertyType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeRestoreUpdate
     * @request PUT:/Management/PropertyType/Restore
     * @secure
     */
    propertyTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/PropertyType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeArchiveDelete
     * @request DELETE:/Management/PropertyType/Archive
     * @secure
     */
    propertyTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/PropertyType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PropertyType
     * @name PropertyTypeDeleteDelete
     * @request DELETE:/Management/PropertyType/Delete
     * @secure
     */
    propertyTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Management/PropertyType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  plannings = {
    /**
     * No description
     *
     * @tags Event
     * @name EventGetEventsList
     * @request GET:/Plannings/Event/GetEvents
     * @secure
     */
    eventGetEventsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        companyId?: number;
        collaboratorIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/Event/GetEvents`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventGetEventByIdList
     * @request GET:/Plannings/Event/GetEventById
     * @secure
     */
    eventGetEventByIdList: (
      query?: {
        /** @format int32 */
        eventId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/Event/GetEventById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventGetEventsArchivedList
     * @request GET:/Plannings/Event/GetEventsArchived
     * @secure
     */
    eventGetEventsArchivedList: (
      query?: {
        collaboratorIds?: number[];
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        companyId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/Event/GetEventsArchived`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventGetEventsArchivedByIdList
     * @request GET:/Plannings/Event/GetEventsArchivedById
     * @secure
     */
    eventGetEventsArchivedByIdList: (
      query?: {
        /** @format int32 */
        eventId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/Event/GetEventsArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventCreateEventCreate
     * @request POST:/Plannings/Event/CreateEvent
     * @secure
     */
    eventCreateEventCreate: (data: EventRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/Event/CreateEvent`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventUpdateEventUpdate
     * @request PUT:/Plannings/Event/UpdateEvent
     * @secure
     */
    eventUpdateEventUpdate: (data: EventRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/Event/UpdateEvent`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name EventDeleteDelete
     * @request DELETE:/Plannings/Event/Delete
     * @secure
     */
    eventDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/Event/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailGetEventDetailByIdList
     * @request GET:/Plannings/EventDetail/GetEventDetailById
     * @secure
     */
    eventDetailGetEventDetailByIdList: (
      query?: {
        /** @format int32 */
        eventDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/GetEventDetailById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailGetEventDetailArchivedByIdList
     * @request GET:/Plannings/EventDetail/GetEventDetailArchivedById
     * @secure
     */
    eventDetailGetEventDetailArchivedByIdList: (
      query?: {
        /** @format int32 */
        eventDetailId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/GetEventDetailArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailGetEventDetailsList
     * @request GET:/Plannings/EventDetail/GetEventDetails
     * @secure
     */
    eventDetailGetEventDetailsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/GetEventDetails`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailGetEventDetailsArchivedList
     * @request GET:/Plannings/EventDetail/GetEventDetailsArchived
     * @secure
     */
    eventDetailGetEventDetailsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/GetEventDetailsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailCreateEventDetailCreate
     * @request POST:/Plannings/EventDetail/CreateEventDetail
     * @secure
     */
    eventDetailCreateEventDetailCreate: (data: EventDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/CreateEventDetail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailUpdateEventDetailUpdate
     * @request PUT:/Plannings/EventDetail/UpdateEventDetail
     * @secure
     */
    eventDetailUpdateEventDetailUpdate: (data: EventDetailRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/UpdateEventDetail`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailRestoreUpdate
     * @request PUT:/Plannings/EventDetail/Restore
     * @secure
     */
    eventDetailRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailArchiveDelete
     * @request DELETE:/Plannings/EventDetail/Archive
     * @secure
     */
    eventDetailArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventDetail
     * @name EventDetailDeleteDelete
     * @request DELETE:/Plannings/EventDetail/Delete
     * @secure
     */
    eventDetailDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventDetail/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeGetEventTypeByIdList
     * @request GET:/Plannings/EventType/GetEventTypeById
     * @secure
     */
    eventTypeGetEventTypeByIdList: (
      query?: {
        /** @format int32 */
        eventTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/EventType/GetEventTypeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeGetEventTypeArchivedByIdList
     * @request GET:/Plannings/EventType/GetEventTypeArchivedById
     * @secure
     */
    eventTypeGetEventTypeArchivedByIdList: (
      query?: {
        /** @format int32 */
        eventTypeId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/EventType/GetEventTypeArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeGetEventTypesList
     * @request GET:/Plannings/EventType/GetEventTypes
     * @secure
     */
    eventTypeGetEventTypesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventType/GetEventTypes`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeGetEventTypesArchivedList
     * @request GET:/Plannings/EventType/GetEventTypesArchived
     * @secure
     */
    eventTypeGetEventTypesArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventType/GetEventTypesArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeCreateEventTypeCreate
     * @request POST:/Plannings/EventType/CreateEventType
     * @secure
     */
    eventTypeCreateEventTypeCreate: (data: EventTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventType/CreateEventType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeUpdateEventTypeUpdate
     * @request PUT:/Plannings/EventType/UpdateEventType
     * @secure
     */
    eventTypeUpdateEventTypeUpdate: (data: EventTypeRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventType/UpdateEventType`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeRestoreUpdate
     * @request PUT:/Plannings/EventType/Restore
     * @secure
     */
    eventTypeRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventType/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeArchiveDelete
     * @request DELETE:/Plannings/EventType/Archive
     * @secure
     */
    eventTypeArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventType/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventType
     * @name EventTypeDeleteDelete
     * @request DELETE:/Plannings/EventType/Delete
     * @secure
     */
    eventTypeDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/EventType/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinePosition
     * @name LinePositionGetLinePositionByIdList
     * @request GET:/Plannings/LinePosition/GetLinePositionById
     * @secure
     */
    linePositionGetLinePositionByIdList: (
      query?: {
        /** @format int32 */
        linePositionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/LinePosition/GetLinePositionById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinePosition
     * @name LinePositionUpdateListLinePositionUpdate
     * @request PUT:/Plannings/LinePosition/UpdateListLinePosition
     * @secure
     */
    linePositionUpdateListLinePositionUpdate: (data: LinePositionRequestDto[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/LinePosition/UpdateListLinePosition`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningLine
     * @name PlanningLineGetPlanningLineCollaboratorsList
     * @request GET:/Plannings/PlanningLine/GetPlanningLineCollaborators
     * @secure
     */
    planningLineGetPlanningLineCollaboratorsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningLine/GetPlanningLineCollaborators`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningLine
     * @name PlanningLineGetPlanningLineAffairsList
     * @request GET:/Plannings/PlanningLine/GetPlanningLineAffairs
     * @secure
     */
    planningLineGetPlanningLineAffairsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningLine/GetPlanningLineAffairs`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningLine
     * @name PlanningLineGetPlanningLineFreeLinesList
     * @request GET:/Plannings/PlanningLine/GetPlanningLineFreeLines
     * @secure
     */
    planningLineGetPlanningLineFreeLinesList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningLine/GetPlanningLineFreeLines`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningLine
     * @name PlanningLineGetPlanningLineByIdList
     * @request GET:/Plannings/PlanningLine/GetPlanningLineById
     * @secure
     */
    planningLineGetPlanningLineByIdList: (
      query?: {
        /** @format int32 */
        planningLineId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/PlanningLine/GetPlanningLineById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningLine
     * @name PlanningLineCreateFreeLineCreate
     * @request POST:/Plannings/PlanningLine/CreateFreeLine
     * @secure
     */
    planningLineCreateFreeLineCreate: (data: FreeLinePlanningLineRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningLine/CreateFreeLine`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningLine
     * @name PlanningLineUpdateFreeLineUpdate
     * @request PUT:/Plannings/PlanningLine/UpdateFreeLine
     * @secure
     */
    planningLineUpdateFreeLineUpdate: (data: FreeLinePlanningLineRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningLine/UpdateFreeLine`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewGetPlanningViewByIdList
     * @request GET:/Plannings/PlanningView/GetPlanningViewById
     * @secure
     */
    planningViewGetPlanningViewByIdList: (
      query?: {
        /** @format int32 */
        planningViewId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/GetPlanningViewById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewGetPlanningViewsList
     * @request GET:/Plannings/PlanningView/GetPlanningViews
     * @secure
     */
    planningViewGetPlanningViewsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/GetPlanningViews`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewGetPlanningViewArchivedByIdList
     * @request GET:/Plannings/PlanningView/GetPlanningViewArchivedById
     * @secure
     */
    planningViewGetPlanningViewArchivedByIdList: (
      query?: {
        /** @format int32 */
        planningViewId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/GetPlanningViewArchivedById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewGetPlanningViewsArchivedList
     * @request GET:/Plannings/PlanningView/GetPlanningViewsArchived
     * @secure
     */
    planningViewGetPlanningViewsArchivedList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/GetPlanningViewsArchived`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewCreatePlanningViewCreate
     * @request POST:/Plannings/PlanningView/CreatePlanningView
     * @secure
     */
    planningViewCreatePlanningViewCreate: (data: PlanningViewRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/CreatePlanningView`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewUpdatePlanningViewUpdate
     * @request PUT:/Plannings/PlanningView/UpdatePlanningView
     * @secure
     */
    planningViewUpdatePlanningViewUpdate: (data: PlanningViewRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/UpdatePlanningView`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewSharePlanningViewsUpdate
     * @request PUT:/Plannings/PlanningView/SharePlanningViews
     * @secure
     */
    planningViewSharePlanningViewsUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        planningViewId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/SharePlanningViews`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewRestoreUpdate
     * @request PUT:/Plannings/PlanningView/Restore
     * @secure
     */
    planningViewRestoreUpdate: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/Restore`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewArchiveDelete
     * @request DELETE:/Plannings/PlanningView/Archive
     * @secure
     */
    planningViewArchiveDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/Archive`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlanningView
     * @name PlanningViewDeleteDelete
     * @request DELETE:/Plannings/PlanningView/Delete
     * @secure
     */
    planningViewDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/PlanningView/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SchedulerCrud
     * @name SchedulerCrudLoadList
     * @request GET:/Plannings/SchedulerCrud/Load
     * @secure
     */
    schedulerCrudLoadList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/SchedulerCrud/Load`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SchedulerCrud
     * @name SchedulerCrudSyncCreate
     * @request POST:/Plannings/SchedulerCrud/Sync
     * @secure
     */
    schedulerCrudSyncCreate: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/SchedulerCrud/Sync`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sms
     * @name SmsGetHistorySmsList
     * @request GET:/Plannings/Sms/GetHistorySms
     * @secure
     */
    smsGetHistorySmsList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/Sms/GetHistorySms`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sms
     * @name SmsGetHistorySmsByEventIdList
     * @request GET:/Plannings/Sms/GetHistorySmsByEventId
     * @secure
     */
    smsGetHistorySmsByEventIdList: (
      query?: {
        /** @format int32 */
        eventId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Plannings/Sms/GetHistorySmsByEventId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sms
     * @name SmsProgramSmsCreate
     * @request POST:/Plannings/Sms/ProgramSms
     * @secure
     */
    smsProgramSmsCreate: (data: ProgrammedSmsRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Plannings/Sms/ProgramSms`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  ged = {
    /**
     * No description
     *
     * @tags File
     * @name FileGetFilesList
     * @request GET:/Ged/File/GetFiles
     * @secure
     */
    fileGetFilesList: (
      query?: {
        fileApplication?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/File/GetFiles`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileGetFileByIdList
     * @request GET:/Ged/File/GetFileById
     * @secure
     */
    fileGetFileByIdList: (
      query?: {
        /** @format int32 */
        fileId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/File/GetFileById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileCreateFileCreate
     * @request POST:/Ged/File/CreateFile
     * @secure
     */
    fileCreateFileCreate: (
      data: {
        /** @format int32 */
        Id?: number;
        FileApplication?: boolean;
        Name?: string;
        /** @format binary */
        File?: File;
        FolderIds?: number[];
        /** @format int32 */
        InstitutionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/File/CreateFile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileUpdateFileUpdate
     * @request PUT:/Ged/File/UpdateFile
     * @secure
     */
    fileUpdateFileUpdate: (data: FileRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Ged/File/UpdateFile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileUpdateFileFolderUpdate
     * @request PUT:/Ged/File/UpdateFileFolder
     * @secure
     */
    fileUpdateFileFolderUpdate: (
      data: number[],
      query?: {
        /** @format int32 */
        fileId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/File/UpdateFileFolder`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File
     * @name FileDeleteDelete
     * @request DELETE:/Ged/File/Delete
     * @secure
     */
    fileDeleteDelete: (
      data: number[],
      query?: {
        /** @format int32 */
        folderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/File/Delete`,
        method: "DELETE",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderGetFoldersList
     * @request GET:/Ged/Folder/GetFolders
     * @secure
     */
    folderGetFoldersList: (
      query?: {
        folderApplication?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/Folder/GetFolders`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderGetFoldersTreeByIdList
     * @request GET:/Ged/Folder/GetFoldersTreeById
     * @secure
     */
    folderGetFoldersTreeByIdList: (
      query?: {
        /** @format int32 */
        folderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/Folder/GetFoldersTreeById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderGetFolderByIdList
     * @request GET:/Ged/Folder/GetFolderById
     * @secure
     */
    folderGetFolderByIdList: (
      query?: {
        /** @format int32 */
        folderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/Folder/GetFolderById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderGetFolderByNameList
     * @request GET:/Ged/Folder/GetFolderByName
     * @secure
     */
    folderGetFolderByNameList: (
      query?: {
        searchTerm?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/Folder/GetFolderByName`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderGetFolderSizeList
     * @request GET:/Ged/Folder/GetFolderSize
     * @secure
     */
    folderGetFolderSizeList: (
      query?: {
        /** @format int32 */
        folderId?: number;
        withFileApplication?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/Folder/GetFolderSize`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderCreateFolderCreate
     * @request POST:/Ged/Folder/CreateFolder
     * @secure
     */
    folderCreateFolderCreate: (data: FolderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Ged/Folder/CreateFolder`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderUpdateFolderUpdate
     * @request PUT:/Ged/Folder/UpdateFolder
     * @secure
     */
    folderUpdateFolderUpdate: (data: FolderRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Ged/Folder/UpdateFolder`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folder
     * @name FolderDeleteDelete
     * @request DELETE:/Ged/Folder/Delete
     * @secure
     */
    folderDeleteDelete: (
      data: number[],
      query?: {
        recursiveDelete?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Ged/Folder/Delete`,
        method: "DELETE",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  common = {
    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetCivilityEnumList
     * @request GET:/Common/Helper/GetCivilityEnum
     * @secure
     */
    helperGetCivilityEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetCivilityEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetTypeLineEnumList
     * @request GET:/Common/Helper/GetTypeLineEnum
     * @secure
     */
    helperGetTypeLineEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetTypeLineEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetAffairStatusEnumList
     * @request GET:/Common/Helper/GetAffairStatusEnum
     * @secure
     */
    helperGetAffairStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetAffairStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetSmsStatusEnumList
     * @request GET:/Common/Helper/GetSmsStatusEnum
     * @secure
     */
    helperGetSmsStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetSmsStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetAmountTypeEnumList
     * @request GET:/Common/Helper/GetAmountTypeEnum
     * @secure
     */
    helperGetAmountTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetAmountTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetPaymentRequestDueEnumList
     * @request GET:/Common/Helper/GetPaymentRequestDueEnum
     * @secure
     */
    helperGetPaymentRequestDueEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetPaymentRequestDueEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDocumentTypeEnumList
     * @request GET:/Common/Helper/GetDocumentTypeEnum
     * @secure
     */
    helperGetDocumentTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDocumentTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDocumentStatusEnumList
     * @request GET:/Common/Helper/GetDocumentStatusEnum
     * @secure
     */
    helperGetDocumentStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDocumentStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDocumentLineTypeEnumList
     * @request GET:/Common/Helper/GetDocumentLineTypeEnum
     * @secure
     */
    helperGetDocumentLineTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDocumentLineTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetMeasureUnitEnumList
     * @request GET:/Common/Helper/GetMeasureUnitEnum
     * @secure
     */
    helperGetMeasureUnitEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetMeasureUnitEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetFormsDetailsTypeEnumList
     * @request GET:/Common/Helper/GetFormsDetailsTypeEnum
     * @secure
     */
    helperGetFormsDetailsTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetFormsDetailsTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetProductTypeEnumList
     * @request GET:/Common/Helper/GetProductTypeEnum
     * @secure
     */
    helperGetProductTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetProductTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetLegalFormEnumList
     * @request GET:/Common/Helper/GetLegalFormEnum
     * @secure
     */
    helperGetLegalFormEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetLegalFormEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetGuaranteeTypeEnumList
     * @request GET:/Common/Helper/GetGuaranteeTypeEnum
     * @secure
     */
    helperGetGuaranteeTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetGuaranteeTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetPaymentTypeEnumList
     * @request GET:/Common/Helper/GetPaymentTypeEnum
     * @secure
     */
    helperGetPaymentTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetPaymentTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetPaymentNatureEnumList
     * @request GET:/Common/Helper/GetPaymentNatureEnum
     * @secure
     */
    helperGetPaymentNatureEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetPaymentNatureEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetModuleSaleParameterEnumList
     * @request GET:/Common/Helper/GetModuleSaleParameterEnum
     * @secure
     */
    helperGetModuleSaleParameterEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetModuleSaleParameterEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetNatureEnumList
     * @request GET:/Common/Helper/GetNatureEnum
     * @secure
     */
    helperGetNatureEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetNatureEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetFooterTypeEnumList
     * @request GET:/Common/Helper/GetFooterTypeEnum
     * @secure
     */
    helperGetFooterTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetFooterTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDocumentValidityEnumList
     * @request GET:/Common/Helper/GetDocumentValidityEnum
     * @secure
     */
    helperGetDocumentValidityEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDocumentValidityEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDurationEnumList
     * @request GET:/Common/Helper/GetDurationEnum
     * @secure
     */
    helperGetDurationEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDurationEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetHoldbackStatusEnumList
     * @request GET:/Common/Helper/GetHoldbackStatusEnum
     * @secure
     */
    helperGetHoldbackStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetHoldbackStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetStorageLocationTypeEnumList
     * @request GET:/Common/Helper/GetStorageLocationTypeEnum
     * @secure
     */
    helperGetStorageLocationTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetStorageLocationTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDeliveryFormStatusEnumList
     * @request GET:/Common/Helper/GetDeliveryFormStatusEnum
     * @secure
     */
    helperGetDeliveryFormStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDeliveryFormStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetOrderFormStatusEnumList
     * @request GET:/Common/Helper/GetOrderFormStatusEnum
     * @secure
     */
    helperGetOrderFormStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetOrderFormStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperFormsDetailsTypeEnumList
     * @request GET:/Common/Helper/FormsDetailsTypeEnum
     * @secure
     */
    helperFormsDetailsTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/FormsDetailsTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetReceiptFormStatusEnumList
     * @request GET:/Common/Helper/GetReceiptFormStatusEnum
     * @secure
     */
    helperGetReceiptFormStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetReceiptFormStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetInventoryStatusEnumList
     * @request GET:/Common/Helper/GetInventoryStatusEnum
     * @secure
     */
    helperGetInventoryStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetInventoryStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDirectionMouvementStatusEnumList
     * @request GET:/Common/Helper/GetDirectionMouvementStatusEnum
     * @secure
     */
    helperGetDirectionMouvementStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDirectionMouvementStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDestockingTypeEnumList
     * @request GET:/Common/Helper/GetDestockingTypeEnum
     * @secure
     */
    helperGetDestockingTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDestockingTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetRestockingTypeEnumList
     * @request GET:/Common/Helper/GetRestockingTypeEnum
     * @secure
     */
    helperGetRestockingTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetRestockingTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDeliveryTypeEnumList
     * @request GET:/Common/Helper/GetDeliveryTypeEnum
     * @secure
     */
    helperGetDeliveryTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDeliveryTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperFunctionAvailableEnumList
     * @request GET:/Common/Helper/FunctionAvailableEnum
     * @secure
     */
    helperFunctionAvailableEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/FunctionAvailableEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperInvoiceProviderStatusEnumList
     * @request GET:/Common/Helper/InvoiceProviderStatusEnum
     * @secure
     */
    helperInvoiceProviderStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/InvoiceProviderStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperOperationEnumList
     * @request GET:/Common/Helper/OperationEnum
     * @secure
     */
    helperOperationEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/OperationEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperCreditProviderStatusEnumList
     * @request GET:/Common/Helper/CreditProviderStatusEnum
     * @secure
     */
    helperCreditProviderStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/CreditProviderStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetLeaseDurationTypeEnumList
     * @request GET:/Common/Helper/GetLeaseDurationTypeEnum
     * @secure
     */
    helperGetLeaseDurationTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetLeaseDurationTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetReportingStatusEnumList
     * @request GET:/Common/Helper/GetReportingStatusEnum
     * @secure
     */
    helperGetReportingStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetReportingStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetQuantificationTypeEnumList
     * @request GET:/Common/Helper/GetQuantificationTypeEnum
     * @secure
     */
    helperGetQuantificationTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetQuantificationTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetTypeFieldEnumList
     * @request GET:/Common/Helper/GetTypeFieldEnum
     * @secure
     */
    helperGetTypeFieldEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetTypeFieldEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetDocumentApplicationTypeEnumList
     * @request GET:/Common/Helper/GetDocumentApplicationTypeEnum
     * @secure
     */
    helperGetDocumentApplicationTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetDocumentApplicationTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetTargetDocumentTypeEnumList
     * @request GET:/Common/Helper/GetTargetDocumentTypeEnum
     * @secure
     */
    helperGetTargetDocumentTypeEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetTargetDocumentTypeEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetContractNatureEnumList
     * @request GET:/Common/Helper/GetContractNatureEnum
     * @secure
     */
    helperGetContractNatureEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetContractNatureEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Helper
     * @name HelperGetContractStatusEnumList
     * @request GET:/Common/Helper/GetContractStatusEnum
     * @secure
     */
    helperGetContractStatusEnumList: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Common/Helper/GetContractStatusEnum`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  interventions = {
    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionGetInterventionsList
     * @request GET:/Interventions/Intervention/GetInterventions
     * @secure
     */
    interventionGetInterventionsList: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @format int32 */
        collaboratorId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Interventions/Intervention/GetInterventions`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionGetInterventionByIdList
     * @request GET:/Interventions/Intervention/GetInterventionById
     * @secure
     */
    interventionGetInterventionByIdList: (
      query?: {
        /** @format int32 */
        interventionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Interventions/Intervention/GetInterventionById`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionGetInterventionCustomFieldsByInterventionIdList
     * @request GET:/Interventions/Intervention/GetInterventionCustomFieldsByInterventionId
     * @secure
     */
    interventionGetInterventionCustomFieldsByInterventionIdList: (
      query?: {
        /** @format int32 */
        interventionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Interventions/Intervention/GetInterventionCustomFieldsByInterventionId`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionCreateInterventionCreate
     * @request POST:/Interventions/Intervention/CreateIntervention
     * @secure
     */
    interventionCreateInterventionCreate: (data: InterventionListRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Interventions/Intervention/CreateIntervention`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionUpdateInterventionUpdate
     * @request PUT:/Interventions/Intervention/UpdateIntervention
     * @secure
     */
    interventionUpdateInterventionUpdate: (data: InterventionRequestDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Interventions/Intervention/UpdateIntervention`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionUpdateInterventionCustomFieldUpdate
     * @request PUT:/Interventions/Intervention/UpdateInterventionCustomField
     * @secure
     */
    interventionUpdateInterventionCustomFieldUpdate: (
      data: Record<string, string>,
      query?: {
        /** @format int32 */
        interventionId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/Interventions/Intervention/UpdateInterventionCustomField`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionDeleteDelete
     * @request DELETE:/Interventions/Intervention/Delete
     * @secure
     */
    interventionDeleteDelete: (data: number[], params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/Interventions/Intervention/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
