<template>
  <div>
    <b-row class="m-0 justify-content-center">
      <div
        class="
          d-flex
          align-items-center
          justify-content-between
          col-md-9 col-12
          p-1
          pl-0
        "
      >
        <div class="d-flex align-items-center">
          <h2
            class="content-header-title float-left m-0 pr-1 mr-1"
            style="border-right: 1px solid #e2e2e2"
          >
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="content-header-search mr-1">
            <h3 class="m-0">{{ storageLocationForm.label }}</h3>
          </div>
        </div>
        <div class="content-header-actions d-flex">
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="24"
            @click="cancel()"
          />
        </div>
      </div>
    </b-row>
    <div
      v-if="
        isLoadingStorageLocation ||
        isCreatingStorageLocation ||
        isUpdatingStorageLocation ||
        isArchivingStorageLocation
      "
      class="content-loader-center m-0 h-100"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du point de stockage...
        </div>
      </div>
    </div>
    <b-row
      v-else
      class="content-scrollable-sticky m-0 justify-content-center"
      style="height: calc(100% - 110px)"
    >
      <b-col cols="12" md="9" class="content-scrollable-sticky-main h-100">
        <validation-observer ref="formCreateStorageLocation">
          <b-form autocomplete="off" class="h-100" style="overflow-x: hidden">
            <div id="general" class="my-0">
              <b-row class="my-0 col-mobile">
                <b-col cols="12" md="6" class="py-0 mb-1">
                  <b-form-group
                    label="Nom du point de stockage *"
                    label-for="label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Label"
                      rules="required"
                    >
                      <b-form-input
                        id="label"
                        v-model="storageLocationForm.label"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Renseigner un nom"
                        class="not-autocomplete"
                        autocomplete="nope"
                        aria-autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="py-0 mb-1">
                  <b-form-group label="Type" label-for="storageLocationType">
                    <validation-provider #default="{ errors }" name="Type">
                      <v-select
                        id="storageLocationType"
                        :state="errors.length &gt; 0 ? false : null"
                        :reduce="type =&gt; type.value"
                        v-model="storageLocationForm.storageLocationType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="optionStorageLocationType"
                      ></v-select
                      ><small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="my-0 col-mobile">
                <b-col cols="12" md="6" class="py-0 mb-1">
                  <b-form-group label="Téléphone" label-for="phoneNumber">
                    <b-form-input
                      id="phoneNumber"
                      v-model="storageLocationForm.phoneNumber"
                      autocomplete="nope"
                      placeholder="Numéro de téléphone"
                      aria-autocomplete="nope"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="py-0 mb-1">
                  <b-form-group label="Email" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="storageLocationForm.email"
                        :state="errors.length &gt; 0 ? false : null"
                        placeholder="Adresse email"
                        autocomplete="nope"
                        aria-autocomplete="nope"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <vs-divider color="#cccccc" class="mt-0 mb-1"></vs-divider>
              <b-row class="my-0 col-mobile">
                <b-col cols="12" md="6" class="py-0 mb-1">
                  <b-form-group label="Adresse" label-for="address">
                    <validation-provider #default="{ errors }" name="Address">
                      <vue-typeahead-bootstrap
                        type="text"
                        :state="errors.length &gt; 0 ? false : null"
                        class="not-autocomplete"
                        id="address"
                        v-model="storageLocationForm.address"
                        :ieCloseFix="false"
                        :data="autocompleteAddressData"
                        :serializer="(item) => item.properties.label"
                        :showAllResults="true"
                        placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                        @input="autocompleteAddressSource()"
                        @hit="autocompleteAddressSelected"
                        autocomplete="nope"
                        aria-autocomplete="nope"
                      >
                        <template
                          slot="suggestion"
                          slot-scope="{ data, htmlText }"
                        >
                          <div class="item">
                            <small>{{ data.properties.name }}</small>
                            <span>{{
                              data.properties.postcode +
                              " " +
                              data.properties.city
                            }}</span>
                          </div>
                        </template>
                      </vue-typeahead-bootstrap>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="py-0 mb-1 align-items-end">
                  <validation-provider
                    #default="{ errors }"
                    name="AddressComplement"
                  >
                    <b-form-input
                      id="addressComplement"
                      v-model="storageLocationForm.addressComplement"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Batiment, Appartement.."
                      class="not-autocomplete"
                      autocomplete="nope"
                      aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="my-0 col-mobile">
                <b-col cols="6" md="3" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="ZipCode">
                    <b-form-input
                      id="zipCode"
                      v-model="storageLocationForm.zipCode"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Code postal"
                      class="not-autocomplete"
                      autocomplete="nope"
                      aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
                <b-col cols="12" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="City">
                    <b-form-input
                      id="city"
                      v-model="storageLocationForm.city"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ville"
                      class="not-autocomplete"
                      autocomplete="nope"
                      aria-autocomplete="nope"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <vs-divider color="#cccccc" class="mt-0 mb-1"></vs-divider>
              <b-row class="my-0">
                <b-col cols="12" class="py-0">
                  <label for="address'">Coordonnées GPS</label>
                </b-col>
                <b-col cols="6" md="3" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="Latitude">
                    <b-form-input
                      class="mb-0"
                      id="latitude"
                      v-model="storageLocationForm.latitude"
                      placeholder="Latitude"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6" md="3" class="py-0 mb-1">
                  <validation-provider #default="{ errors }" name="Longitude">
                    <b-form-input
                      class="mb-0"
                      id="longitude"
                      v-model="storageLocationForm.longitude"
                      placeholder="Longitude"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>

        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem"
            >
              <div>
                <b-button variant="outline-primary" @click="cancel()">
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="storageLocationForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="
                    archiveStorageLocationLocal(
                      storageLocationForm.id,
                      storageLocationForm.name
                    )
                  "
                >
                  <feather-icon icon="ArchiveIcon" />
                </b-button>
              </div>

              <b-button variant="primary" @click="create">
                <feather-icon icon="SaveIcon" v-if="storageLocationForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ storageLocationForm.id ? "Modifier" : "Ajouter" }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { debounce } from "lodash";
import Autocomplete from "vuejs-auto-complete";
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox } from "bootstrap-vue";
import { mask } from "vue-the-mask";
import { StorageLocationType } from "@/../Api.ts";

export default {
  props: {
    id: {
      default: null,
    },
  },
  data() {
    return {
      optionStorageLocationType: [
        {
          label: "Bâtiment",
          value: StorageLocationType.Value0,
        },
        {
          label: "Véhicule",
          value: StorageLocationType.Value1,
        },
      ],
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      required,
      url,
      email,
      dataOrigine: {},
      storageLocationForm: {},
    };
  },
  async created() {
    this.popupStorageLocationEvent(this.id);
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    this.deleteAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  methods: {
    ...mapActions([
      "createStorageLocation",
      "getStorageLocationById",
      "updateStorageLocation",
      "archiveStorageLocations",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deleteAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });

      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupStorageLocationEvent(id) {
      if (id !== "" && id !== null && id !== 0) {
        this.getStorageLocationById({ storageLocationId: id }).then((res) => {
          this.storageLocationForm = res;
          this.dataOrigine = JSON.stringify(this.storageLocationForm);
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.storageLocationForm = {
        id: 0,
        label: "",
        storageLocationType: 0,
        address: "",
        addressComplement: "",
        zipCode: "",
        city: "",
        latitude: 0,
        longitude: 0,
        phoneNumber: "",
        email: "",
      };
      this.dataOrigine = JSON.stringify(this.storageLocationForm);
    },
    create(e) {
      e.preventDefault();
      this.$refs.formCreateStorageLocation
        .validate()
        .then((success1) => {
          if (success1) {
            if (this.storageLocationForm.id)
              this.updateStorageLocation({
                storageLocation: this.storageLocationForm,
              });
            else
              this.createStorageLocation({
                storageLocation: this.storageLocationForm,
              });

            this.$nextTick(() => {
              if (
                this.$route.params.routeOrigine &&
                this.$route.params.routeOrigine !== ""
              ) {
                this.$tabs.close({ to: this.$route.params.routeOrigine });
              } else {
                this.$tabs.close({ to: "/storage-locations" });
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      let _this = this;
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.storageLocationForm.address}&autocomplete=0&limit=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          _this.autocompleteAddressData = data.features;
          _this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.storageLocationForm.address = item.properties.name;
      this.storageLocationForm.city = item.properties.city;
      this.storageLocationForm.zipCode = item.properties.postcode;
      this.storageLocationForm.latitude = item.geometry.coordinates[1];
      this.storageLocationForm.longitude = item.geometry.coordinates[0];
    },

    // Controls from
    archiveStorageLocationLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveStorageLocations({ storageLocationIds: [id] });
            this.$tabs.close();
          }
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.storageLocationForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.$tabs.close();
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingStorageLocation",
      "isCreatingStorageLocation",
      "isUpdatingStorageLocation",
      "isArchivingStorageLocation",
    ]),
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    Autocomplete,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 768px) {
  .col-mobile {
    flex-direction: column !important;
  }
}
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
