var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0 justify-content-center"},[_c('div',{staticClass:"\n        d-flex\n        align-items-center\n        justify-content-between\n        col-md-9 col-12\n        p-1\n        pl-0\n      "},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h2',{staticClass:"content-header-title float-left m-0 pr-1 mr-1",staticStyle:{"border-right":"1px solid #e2e2e2"}},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),_c('div',{staticClass:"content-header-search mr-1"},[_c('h3',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.storageLocationForm.label))])])]),_c('div',{staticClass:"content-header-actions d-flex"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.cancel()}}})],1)])]),(
      _vm.isLoadingStorageLocation ||
      _vm.isCreatingStorageLocation ||
      _vm.isUpdatingStorageLocation ||
      _vm.isArchivingStorageLocation
    )?_c('div',{staticClass:"content-loader-center m-0 h-100"},[_vm._m(0)]):_c('b-row',{staticClass:"content-scrollable-sticky m-0 justify-content-center",staticStyle:{"height":"calc(100% - 110px)"}},[_c('b-col',{staticClass:"content-scrollable-sticky-main h-100",attrs:{"cols":"12","md":"9"}},[_c('validation-observer',{ref:"formCreateStorageLocation"},[_c('b-form',{staticClass:"h-100",staticStyle:{"overflow-x":"hidden"},attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"my-0",attrs:{"id":"general"}},[_c('b-row',{staticClass:"my-0 col-mobile"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom du point de stockage *","label-for":"label"}},[_c('validation-provider',{attrs:{"name":"Label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"id":"label","state":errors.length > 0 ? false : null,"placeholder":"Renseigner un nom","autocomplete":"nope","aria-autocomplete":"nope"},model:{value:(_vm.storageLocationForm.label),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "label", $$v)},expression:"storageLocationForm.label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"storageLocationType"}},[_c('validation-provider',{attrs:{"name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"storageLocationType","state":errors.length > 0 ? false : null,"reduce":function (type) { return type.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.optionStorageLocationType},model:{value:(_vm.storageLocationForm.storageLocationType),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "storageLocationType", $$v)},expression:"storageLocationForm.storageLocationType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0 col-mobile"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"phoneNumber"}},[_c('b-form-input',{attrs:{"id":"phoneNumber","autocomplete":"nope","placeholder":"Numéro de téléphone","aria-autocomplete":"nope"},model:{value:(_vm.storageLocationForm.phoneNumber),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "phoneNumber", $$v)},expression:"storageLocationForm.phoneNumber"}})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"placeholder":"Adresse email","autocomplete":"nope","aria-autocomplete":"nope"},model:{value:(_vm.storageLocationForm.email),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "email", $$v)},expression:"storageLocationForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('vs-divider',{staticClass:"mt-0 mb-1",attrs:{"color":"#cccccc"}}),_c('b-row',{staticClass:"my-0 col-mobile"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('vue-typeahead-bootstrap',{staticClass:"not-autocomplete",attrs:{"type":"text","state":errors.length > 0 ? false : null,"id":"address","ieCloseFix":false,"data":_vm.autocompleteAddressData,"serializer":function (item) { return item.properties.label; },"showAllResults":true,"placeholder":"Rechercher une adresse ou saisissez : Rue et n° de rue","autocomplete":"nope","aria-autocomplete":"nope"},on:{"input":function($event){return _vm.autocompleteAddressSource()},"hit":_vm.autocompleteAddressSelected},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
    var data = ref.data;
    var htmlText = ref.htmlText;
return [_c('div',{staticClass:"item"},[_c('small',[_vm._v(_vm._s(data.properties.name))]),_c('span',[_vm._v(_vm._s(data.properties.postcode + " " + data.properties.city))])])]}}],null,true),model:{value:(_vm.storageLocationForm.address),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "address", $$v)},expression:"storageLocationForm.address"}})]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1 align-items-end",attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"AddressComplement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"id":"addressComplement","state":errors.length > 0 ? false : null,"placeholder":"Batiment, Appartement..","autocomplete":"nope","aria-autocomplete":"nope"},model:{value:(_vm.storageLocationForm.addressComplement),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "addressComplement", $$v)},expression:"storageLocationForm.addressComplement"}})]}}])})],1)],1),_c('b-row',{staticClass:"my-0 col-mobile"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6","md":"3"}},[_c('validation-provider',{attrs:{"name":"ZipCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"id":"zipCode","state":errors.length > 0 ? false : null,"placeholder":"Code postal","autocomplete":"nope","aria-autocomplete":"nope"},model:{value:(_vm.storageLocationForm.zipCode),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "zipCode", $$v)},expression:"storageLocationForm.zipCode"}})]}}])})],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"not-autocomplete",attrs:{"id":"city","state":errors.length > 0 ? false : null,"placeholder":"Ville","autocomplete":"nope","aria-autocomplete":"nope"},model:{value:(_vm.storageLocationForm.city),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "city", $$v)},expression:"storageLocationForm.city"}})]}}])})],1)],1),_c('vs-divider',{staticClass:"mt-0 mb-1",attrs:{"color":"#cccccc"}}),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"address'"}},[_vm._v("Coordonnées GPS")])]),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6","md":"3"}},[_c('validation-provider',{attrs:{"name":"Latitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",attrs:{"id":"latitude","placeholder":"Latitude"},model:{value:(_vm.storageLocationForm.latitude),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "latitude", $$v)},expression:"storageLocationForm.latitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6","md":"3"}},[_c('validation-provider',{attrs:{"name":"Longitude"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-0",attrs:{"id":"longitude","placeholder":"Longitude"},model:{value:(_vm.storageLocationForm.longitude),callback:function ($$v) {_vm.$set(_vm.storageLocationForm, "longitude", $$v)},expression:"storageLocationForm.longitude"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"border-top":"1px solid #e2e2e2","padding-top":"0.5rem"}},[_c('div',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Annuler ")],1),(_vm.storageLocationForm.id)?_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.archiveStorageLocationLocal(
                    _vm.storageLocationForm.id,
                    _vm.storageLocationForm.name
                  )}}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon"}})],1):_vm._e()],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.create}},[(_vm.storageLocationForm.id)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.storageLocationForm.id ? "Modifier" : "Ajouter")+" ")],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"loading-bg-inner"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"outer"}),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"inner"})])]),_c('div',{staticClass:"mt-5"},[_c('br'),_c('br'),_c('br'),_vm._v(" Chargement des détails du point de stockage... ")])])}]

export { render, staticRenderFns }